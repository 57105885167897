import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  layoutPadding: {
    padding: theme.spacing(2.4, 1.6, 0, 1.6),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2.4, 2, 0, 2),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2.4, 12, 0, 12),
    },
  },
  bodyContainer: {
    // height: "calc(100% - 85px - 80px)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      // height: "calc(100% - 85px)",
    },
  },
}));

export default useStyles;
