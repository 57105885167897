import React from "react";
import useStyles from "./ProgressBar.style";

const ProgressBar = ({ value }) => {
  const classes = useStyles();
  return (
    <div className={classes.progressBar}>
      <div className={classes.progressBarFill} style={{ width: `${value}%` }}>
        <div className={classes.progressBarText}>{value}%</div>
      </div>
    </div>
  );
};

export default ProgressBar;
