import classes from "./style.module.scss";
import poweredBy from "../../assets/images/powered-by.svg";
import silverbackLogo from "../../assets/silverback/SILVERBACK-LOGO.png";
import { Typography } from "@material-ui/core";
import { APP_NAME, SILVERBACK_APP_NAME } from "../../utils/Constants";
import { homeLink } from "../../config/app-config";
import { useSelector } from "react-redux";

const PoweredBy = () => {
  const { hotel } = useSelector((state) => state.hotel);
  return (
    <div className={classes.poweredCon}>
      {hotel?.powered_by_logo_enable ? (
        <>
          <Typography className={classes.text} variant="h6" noWrap>
            Powered By
          </Typography>
          <a href={homeLink} target="_blank" rel="noreferrer">
            {APP_NAME === SILVERBACK_APP_NAME ? (
              <img src={silverbackLogo} alt="" className={classes.poweredImg} />
            ) : (
              <img src={poweredBy} alt="" className={classes.poweredImg} />
            )}
          </a>
        </>
      ) : ''}
    </div>
  );
};

export default PoweredBy;
