import * as types from './CurrentCuisineTypes';

const INITIAL_STATE = {
  cuisines: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_CURRENT_TAKEY_CUISINE:
      state.cuisines = action.payload;
      return state;

    case types.CLEAR_CURRENT_TAKEY_CUISINE:
      state.cuisines = null;
      return state;

    default: return state;
  }
}

export default reducer;
