import { makeStyles } from "@material-ui/core";
import {
  calculateFontSize,
  setFontColorOnBg,
} from "../../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  checkoutRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  root: {
    boxSizing: "border-box",
    width: "100%",
    marginBottom: theme.spacing(2.4),
    padding: theme.spacing(2.4),
    borderRadius: theme?.palette?.card?.cardBorderRadius || "12px",
    border: theme?.palette?.card?.cardBorder
      ? `1px solid ${theme?.palette?.card?.cardBorderColor}`
      : null,
    backgroundColor:
      theme?.palette?.card?.cardColor || theme.palette.common.white,
  },
  headerTitle: {
    fontWeight: "bold",
    // color: theme.palette.common.normalTitle,
    color: theme?.palette?.card?.cardFontColor,
    fontSize: calculateFontSize(16, theme.palette.common.fontVariation),
    lineHeight: "19px",
    // marginBottom: theme.spacing(2.4),
    [theme.breakpoints.up("sm")]: {
      fontSize: calculateFontSize(18, theme.palette.common.fontVariation),
    },
  },
  formDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  input: {
    marginRight: theme.spacing(2), // Adjust the spacing as needed
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "12px",
    padding: theme.spacing(0.5),
  },
  disabledButton: {
    backgroundColor: theme.palette.grey[300], // Customize the style for the disabled button
  },
  scannerDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "80%",
    paddingTop: "10px",
  },
  toRedeemText: {
    // color: "black",
    color: theme?.palette?.card?.cardFontColor,
    fontWeight: "400",
  },
  walletBtnDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  walletTitleDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "20px",
  },
  coinIcon: {
    color: "goldenrod !important",
    marginLeft: "6px",
  },
  redeemedText: {
    color: "green",
    fontWeight: "600",
  },
  applyBtn: {
    backgroundColor:
      theme?.palette?.button?.buttonColor || theme.palette.button.main,
    borderRadius: theme?.palette?.button?.borderRadius || "12px",
    border: theme?.palette?.button?.buttonBorder ? `1px solid black` : null,
    color: setFontColorOnBg(
      theme?.palette?.button?.buttonColor || theme.palette.button.main
    ),
  },
  scannerCloseBtn: {
    backgroundColor: "#e63946",
    color: "white",
    transition: "background-color 0.3s", // Add a smooth transition
    "&:hover": {
      backgroundColor: "#f77f88", // Light red color on hover
    },
  },
}));

export default useStyles;
