export const saveState = (state) => {
  try {
    const serialState = JSON.stringify(state);
    localStorage.setItem("appState", serialState);
  } catch (err) {
    console.log(err);
  }
};

export const loadState = () => {
  const paths = window.location.pathname.split("/");
  try {
    const serialState = localStorage.getItem("appState");
    if (serialState === null) {
      return undefined;
    }
    const jsonData = JSON.parse(serialState);
    const otherPaths = ["payment-callback"];
    if (
      jsonData.hotel.hotelKey === paths?.[1] ||
      otherPaths.indexOf(paths?.[1]) > -1
    ) {
      return jsonData;
    }
    return { user: jsonData?.user };
  } catch (err) {
    return undefined;
  }
};
