import { makeStyles } from "@material-ui/core/styles";
import { calculateFontSize } from "../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    zIndex: 10000,
    padding: theme.spacing(0, 2),
  },
  text: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: calculateFontSize(20, theme.palette.common.fontVariation),
    lineHeight: "28px",
    marginTop: theme.spacing(2),
    color: theme.palette.common.fontTitle,
  },
  secondaryText: {
    textAlign: "center",
    fontSize: calculateFontSize(16, theme.palette.common.fontVariation),
    lineHeight: "28px",
    marginTop: theme.spacing(1),
    color: theme.palette.common.fontTitle,
  },
  redirectMsg: {
    textAlign: "center",
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    lineHeight: "28px",
    marginTop: theme.spacing(1),
    color: theme.palette.common.fontTitle,
  },
  imageContainer: {
    width: "200px",
  },
  refIdText: {
    fontWeight: "600",
    color: theme.palette.common.fontTitle,
  },
}));

export default useStyles;
