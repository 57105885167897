import { axiosInstance } from "../../network/apis";
import { API } from "../../utils/Constants";

// const handlerEnabled = false;
// Sample response
// import MenuSampleResponse from "./MenuSampleResponse";

export const getCoupon = async (hotelKey) => {
  const { data } = await axiosInstance.get(
    `${API.ENDPOINTS.GET_COUPON}?user_id=${hotelKey}`,
    {
      handlerEnabled: false,
    }
  );
  // await axiosInstance.get(`${API.ENDPOINTS.GET_MENU}`, {
  //   handlerEnabled: false,
  // });
  // const data = MenuSampleResponse;
  if (!data.status) {
    throw new Error(data.message);
  }
  return { data };
};

export const validateCoupon = async (customerId, code, hotelId) => {
  try {
    const response = await axiosInstance.post(
      `${API.ENDPOINTS.COUPON_VALIDATE}`,
      {
        customer_email: customerId,
        code,
        store_id: hotelId,
      },
      {
        handlerEnabled: false,
      }
    );
    return response; // Return response if successful
  } catch (error) {
    // Even if the request fails with a 400 status code, return the response or error
    if (error.response) {
      // Return the response from the error object
      return error.response;
    }
    // Handle unexpected errors
    throw error;
  }
};

// export const validateCoupon = async (hotelId, code) => {
//   const { data } = await axiosInstance.post(
//     `${API.ENDPOINTS.VALIDATE_COUPON}`,
//     {
//       user_id: hotelId,
//       code,
//     },
//     {
//       handlerEnabled: false,
//     }
//   );

//   if (!data.status) {
//     throw new Error(data.message);
//   }
//   return { data };
// };
