import { makeStyles } from "@material-ui/core/styles";
import { calculateFontSize } from "../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
    borderRadius: "12px",
    padding: theme.spacing(1.6),
    width: "70vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    top: "40px",
    position: "absolute",
    [theme.breakpoints.up("md")]: {
      width: "35vw",
    },
  },
  success: {
    backgroundColor: "#459F49",
  },
  warning: {
    backgroundColor: "#ff9800",
  },
  error: {
    backgroundColor: "#DE3D31",
  },
  icon: {
    width: "8px",
    height: "8px",
    color: theme.palette.common.red,
  },
  text: {
    // marginTop: theme.spacing(0.8),
    fontWeight: 600,
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    lineHeight: "21px",
    color: theme.palette.common.white,
  },
  anchorOriginTopRight: {
    top: "90px",
  },
}));

export default useStyles;
