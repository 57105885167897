import * as types from './TimeType'

const INITIAL_STATE = {
    nextAvailableTime: null
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.NEXT_AVAILABLE_TIME:
            return {
                ...state,
                nextAvailableTime: action.payload
            };
        default:
            return state;
    }
}

export default reducer