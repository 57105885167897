import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.8)", // semi-transparent background
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9999, // make sure it is on top of other elements
  },
  loader: {
    border: "16px solid #f3f3f3", // Light grey
    borderTop: "16px solid #3498db", // Blue
    borderRadius: "50%",
    width: "80px",
    height: "80px",
    animation: "$spin 2s linear infinite",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

const BasicLoader = () => {
  const classes = useStyles();

  return (
    <div className={classes.overlay}>
      <div className={classes.loader}></div>
    </div>
  );
};

export default BasicLoader;
