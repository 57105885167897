import { call, put, takeLatest } from "redux-saga/effects";

import { getRestaurant } from "./RestaurantApis";
import * as ACTIONS from "./RestaurantActions";
import * as TYPES from "./RestaurantTypes";
// Utils
import { dispatchErrorMessageAsDialog } from "../../../utils/Shared";
// Constants
import { ERROR_TYPES } from "../../../utils/Constants";

// Replace with your sagas
export function* sagasRestaurantRequest({ payload }) {
  try {
    const { lat, lng } = payload;
    if (!lat || !lng) {
      throw new Error("Something went wrong with location");
    }
    const response = yield call(getRestaurant, { lat, lng });
    if (!response.data) {
      throw new Error("Something went wrong with the response");
    }
    yield put(ACTIONS.getRestaurantReceive(response.data));
  } catch (err) {
    yield put(ACTIONS.clearResturant());

    if (err.message) {
      dispatchErrorMessageAsDialog({
        message: err.message,
        type: ERROR_TYPES.RESTURANT,
      });
    } else {
      const { status, data } = err.response;
      if (status === 404) {
        dispatchErrorMessageAsDialog({
          message: data.error || "Resturants not found",
          type: ERROR_TYPES.RESTURANT,
        });
      } else {
        dispatchErrorMessageAsDialog({
          message: "Hotels are under maintenance, Please come back later",
          type: ERROR_TYPES.RESTURANT,
        });
      }
    }
  }
}

export function* TakeyRestaurantSaga() {
  yield takeLatest(TYPES.GET_TAKEY_RESTAURANT_REQUEST, sagasRestaurantRequest);
}
