import { makeStyles } from "@material-ui/core/styles";
import { calculateFontSize } from "../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: "84px",
    // padding: theme.spacing(0, 1.6),
    background: theme?.palette?.card?.cardColor || theme.palette.common.white,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
    borderRadius: theme?.palette?.card?.cardBorderRadius || "12px",
    border: theme?.palette?.card?.cardBorder
      ? `1px solid ${theme?.palette?.card?.cardBorderColor}`
      : null,
    cursor: "pointer",
    "&:hover": {
      borderColor: `${theme.palette.primary.main}`,
    },
    "&.selected": {
      borderColor: `${theme.palette.primary.main}`,
    },
  },
  splRoot: {
    display: "flex",
    alignItems: "center",
    height: "84px",
    // padding: theme.spacing(0, 1.6),
    background: theme?.palette?.card?.cardColor || theme.palette.common.white,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
    flexDirection: theme?.palette?.card?.position === 0 ? "row" : "row-reverse",
    borderRadius: theme?.palette?.card?.cardBorderRadius || "12px",
    border: theme?.palette?.card?.cardBorder
      ? `1px solid ${theme?.palette?.card?.cardBorderColor}`
      : null,
    cursor: "pointer",
    "&:hover": {
      borderColor: `${theme.palette.primary.main}`,
    },
    "&.selected": {
      borderColor: `${theme.palette.primary.main}`,
    },
  },
  orderTypeRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    flexGrow: 1,
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    fontWeight: 600,
    // color: theme.palette.common.normalTitle,
    color: theme?.palette?.card?.cardFontColor,
  },
  diabled: {
    opacity: 0.5,
    pointerEvents: "none",
  },
  placeholderImgActive: {
    width: "60px",
    marginRight: "10px",
    maxHeight: "60px",
    minHeight: "60px",
    borderTopLeftRadius: theme?.palette?.card?.cardBorderRadius || "10.9px",
  },
  placeholderImg: {
    width: "30%",
    marginRight: "10px",
    maxHeight: "100%",
    minHeight: "50px",
    borderTopLeftRadius: theme?.palette?.card?.cardBorderRadius || "10.9px",
    borderBottomLeftRadius: theme?.palette?.card?.cardBorderRadius || "10.9px",
  },
  splPlaceHolderImg: {
    width: "30%",
    marginRight: theme?.palette?.card?.position == 0 ? "10px" : 0,
    maxHeight: "100%",
    minHeight: "50px",
    borderTopLeftRadius:
      theme?.palette?.card?.position == 0
        ? theme?.palette?.card?.cardBorderRadius || "10.9px"
        : 0,
    borderBottomLeftRadius:
      theme?.palette?.card?.position == 0
        ? theme?.palette?.card?.cardBorderRadius || "10.9px"
        : 0,
    borderTopRightRadius:
      theme?.palette?.card?.position == 1
        ? theme?.palette?.card?.cardBorderRadius || "10.9px"
        : 0,
    borderBottomRightRadius:
      theme?.palette?.card?.position == 1
        ? theme?.palette?.card?.cardBorderRadius || "10.9px"
        : 0,
  },
  cardContentDiv: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    height: "100%",
    overflow: "hidden",
  },
  orderTypeCardContentDiv: {
    display: "flex",
    flexDirection: "column",
    width: "auto",
  },
  splCardContentDiv: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    height: "100%",
    overflow: "hidden",
    paddingLeft: theme?.palette?.card?.position == 0 ? 0 : "10px",
  },

  descText: {
    flexGrow: 1,
    fontSize: calculateFontSize(10, theme.palette.common.fontVariation),
    fontWeight: 300,
    // color: theme.palette.common.normalTitle,
    color: theme?.palette?.card?.cardFontColor,
  },
}));

export default useStyles;
