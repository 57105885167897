import * as types from "./AppTypes";

export const setAppVersion = (payload) => ({
  type: types.SET_APP_VERSION,
  payload,
});

export const setFlavour = (payload) => ({
  type: types.SET_FLAVOUR,
  payload,
});
