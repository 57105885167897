import * as types from './UserTypes';

const INITIAL_STATE = null;

// Replace with you own reducer
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_USER:
      if (action.payload) {
        return {
          ...state,
          ...action.payload
        };
      }
      return null;
    case types.LOGOUT_USER:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
