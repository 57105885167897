import { makeStyles } from "@material-ui/core/styles";
import { calculateFontSize } from "../../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  avatar: {
    borderRadius: "50%",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: theme?.palette?.button?.buttonBorder
      ? "1px solid black"
      : "1px solid white",
    backgroundColor:
      theme?.palette?.button?.buttonColor || theme.palette.button.main,
    fontSize: calculateFontSize(20, theme.palette.common.fontVariation),
    cursor: "pointer",
    textTransform: "capitalize",
    color: "#f5f5f5",
    padding: "0",
  },
  deleteAvatarRoot: {
    display: "flex",
    alignItems: "center",
    height: "54px",
    // padding: theme.spacing(0, 1.6),
    background: theme?.palette?.card?.cardColor || theme.palette.common.white,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
    borderRadius: "10px",
    border: theme?.palette?.card?.cardBorder
      ? `1px solid ${theme?.palette?.card?.cardBorderColor}`
      : null,
    cursor: "pointer",
    color: theme?.palette?.card?.cardFontColor,
    "&:hover": {
      borderColor: `${theme.palette.primary.main}`,
      cursor: "pointer",
    },
    "&.selected": {
      borderColor: `${theme.palette.primary.main}`,
    },
  },
  avatarRoot: {
    display: "flex",
    alignItems: "center",
    height: "84px",
    // padding: theme.spacing(0, 1.6),
    background: theme?.palette?.card?.cardColor || theme.palette.common.white,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
    borderRadius: theme?.palette?.card?.cardBorderRadius || "12px",
    border: theme?.palette?.card?.cardBorder
      ? `1px solid ${theme?.palette?.card?.cardBorderColor}`
      : null,
    cursor: "pointer",
    color: theme?.palette?.card?.cardFontColor,
    "&:hover": {
      borderColor: `${theme.palette.primary.main}`,
      cursor: "pointer",
    },
    "&.selected": {
      borderColor: `${theme.palette.primary.main}`,
    },
  },
  deleteBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    background: "rgba(255, 100, 100, 0.3)",
    color: theme?.palette?.card?.cardFontColor || "#ffffff",
    border: "1px solid rgba(214, 40, 40, 0.5)", // light border for structure
    boxShadow: "none", // no shadow to keep it subtle
    "&:hover": {
      background: "rgba(214, 40, 40, 0.7)", // slightly brighter on hover
    },
  },
  avatarIcon: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
  },
  drawer: {
    width: 295,
    flexShrink: 0,
    [theme.breakpoints.up("sm")]: {
      width: 350,
    },
    [theme.breakpoints.up("md")]: {
      width: 592,
    },
  },
  drawerPaper: {
    backgroundColor: theme.palette.common.background,
    width: 295,
    [theme.breakpoints.up("sm")]: {
      width: 350,
    },
    [theme.breakpoints.up("md")]: {
      width: 592,
    },
  },
  filterDrawerContainer: {
    padding: theme.spacing(2.4, 1.6, 0, 1.6),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2.4, 4, 0, 4),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2.4, 12, 0, 8),
    },
  },
  filterDrawerTitle: {
    fontWeight: 700,
    color: theme?.palette?.common?.themeFontColor,
    fontSize: calculateFontSize(16, theme.palette.common.fontVariation),
    marginBottom: theme.spacing(2.4),
  },
  card: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    background: theme?.palette?.card?.cardColor || theme.palette.common.white,
    color: theme?.palette?.card?.cardFontColor,
  },
  icon: {
    marginRight: theme.spacing(2),
    overflow: "visible",
    display: "flex",
  },
  iconDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "20%",
  },
  textDiv: {
    width: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: 0,
    paddingTop: "25px",
  },
  text: {
    flexGrow: 1,
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    fontWeight: 600,
    color: theme.palette.card.cardFontColor,
  },
}));

export default useStyles;
