import { useDispatch, useSelector } from "react-redux";
import {
  onFilterMenuCategory,
  onFilterMenuCombo,
} from "../store/Menu/MenuActions";
import { SORTS } from "../utils/Constants";
import useSearchHook from "./useSearchHook";
import moment from "moment";

const useSortHook = () => {
  const dispatch = useDispatch();
  const {
    category,
    comboModified,
    sortedCategoryMenu,
    sortedComboMenu,
    searchPhrase,
  } = useSelector((state) => state.menu);
  const { isMobileApp } = useSelector((state) => state.hotel);
  const { checkoutDetails } = useSelector((state) => state.cart);
  const { flavour } = useSelector((state) => state.app);

  const { handleCategorySearch, handleComboSearch } = useSearchHook();

  const getCategory = () => {
    //deep copy object
    return JSON.parse(JSON.stringify(sortedCategoryMenu));
  };
  const getCombo = () => {
    //deep copy object
    return JSON.parse(JSON.stringify(sortedComboMenu));
  };
  //-------------------------------------------------------category
  // default sort category
  const getDefaultSortCategory = (id) => {
    let categoryFil = getCategory();
    const catIndex = categoryFil.findIndex((c) => c.categoryId === id);
    const catDefIndex = category.findIndex((c) => c.categoryId === id);
    //if search phrase then take searched categories
    const data = searchPhrase ? handleCategorySearch(searchPhrase) : category;
    let defaultItems = [...data[catDefIndex]?.item];
    categoryFil[catIndex].item = [...defaultItems];

    return { categoryFil };
  };

  // A-Z sort category
  const getAZSortCategory = (id) => {
    let categoryFil = getCategory();
    const index = categoryFil.findIndex((c) => c.categoryId === id);
    categoryFil[index]?.item?.sort((a, b) => {
      let fa = a.label.toLowerCase(),
        fb = b.label.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    return { categoryFil };
  };

  // Z-A sort category
  const getZASortCategory = (id) => {
    let categoryFil = getCategory();
    const index = categoryFil.findIndex((c) => c.categoryId === id);
    categoryFil[index]?.item?.sort((a, b) => {
      let fa = a.label.toLowerCase(),
        fb = b.label.toLowerCase();

      if (fa < fb) {
        return 1;
      }
      if (fa > fb) {
        return -1;
      }
      return 0;
    });
    return { categoryFil };
  };

  // low -high price sort category
  const getLowHighPriceSortCategory = (id) => {
    let categoryFil = getCategory();
    const index = categoryFil.findIndex((c) => c.categoryId === id);
    categoryFil[index]?.item?.sort((a, b) => {
      return a.sale_price - b.sale_price;
    });
    return { categoryFil };
  };
  // high-low price sort category
  const getHighLowPriceSortCategory = (id) => {
    let categoryFil = getCategory();
    const index = categoryFil.findIndex((c) => c.categoryId === id);
    categoryFil[index]?.item?.sort((a, b) => {
      return b.sale_price - a.sale_price;
    });
    return { categoryFil };
  };
  //---------------------------------------------------------combo
  // default sort category
  const getDefaultSortCombo = (id) => {
    const comboFil = getCombo();

    const data = searchPhrase ? handleComboSearch(searchPhrase) : comboModified;

    const comDefItems = data[id];
    comboFil[id] = comDefItems;

    return { comboFil };
  };

  // A-Z sort category
  const getAZSortCombo = (id) => {
    let comboFil = getCombo();
    comboFil[id]?.sort((a, b) => {
      let fa = a.combo_name.toLowerCase(),
        fb = b.combo_name.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    return { comboFil };
  };

  // Z-A sort category
  const getZASortCombo = (id) => {
    let comboFil = getCombo();
    comboFil[id]?.sort((a, b) => {
      let fa = a.combo_name.toLowerCase(),
        fb = b.combo_name.toLowerCase();

      if (fa < fb) {
        return 1;
      }
      if (fa > fb) {
        return -1;
      }
      return 0;
    });
    return { comboFil };
  };

  // low -high price sort category
  const getLowHighPriceSortCombo = (id) => {
    let comboFil = getCombo();
    comboFil[id]?.sort((a, b) => {
      return a.combo_price - b.combo_price;
    });
    return { comboFil };
  };
  // high-low price sort category
  const getHighLowPriceSortCombo = (id) => {
    let comboFil = getCombo();
    comboFil[id]?.sort((a, b) => {
      return b.combo_price - a.combo_price;
    });
    return { comboFil };
  };

  //-----------------------------------------handle category sort
  const handleSortCategory = (val, id) => {
    let categoryRes = [];
    if (val === SORTS.DEFAULT) {
      categoryRes = getDefaultSortCategory(id);
    } else if (val === SORTS.AZ) {
      categoryRes = getAZSortCategory(id);
    } else if (val === SORTS.ZA) {
      categoryRes = getZASortCategory(id);
    } else if (val === SORTS.LOW_HIGH_PRICE) {
      categoryRes = getLowHighPriceSortCategory(id);
    } else if (val === SORTS.HIGH_LOW_PRICE) {
      categoryRes = getHighLowPriceSortCategory(id);
    }
    dispatch(onFilterMenuCategory(categoryRes?.categoryFil));
  };

  //----------------------------------------handle combo sort
  const handleSortCombo = (val, id) => {
    let categoryRes = [];
    if (val === SORTS.DEFAULT) {
      categoryRes = getDefaultSortCombo(id);
    } else if (val === SORTS.AZ) {
      categoryRes = getAZSortCombo(id);
    } else if (val === SORTS.ZA) {
      categoryRes = getZASortCombo(id);
    } else if (val === SORTS.LOW_HIGH_PRICE) {
      categoryRes = getLowHighPriceSortCombo(id);
    } else if (val === SORTS.HIGH_LOW_PRICE) {
      categoryRes = getHighLowPriceSortCombo(id);
    }
    dispatch(onFilterMenuCombo(categoryRes?.comboFil));
  };

  const checkProductAvailability = (product, currentTime) => {
    const momentCurrentTime = moment(currentTime);
    if (product == null) {
      return true;
    }
    let availabilityRules = product?.availability;

    if (
      typeof availabilityRules === "string" &&
      availabilityRules !== undefined &&
      availabilityRules !== null
    ) {
      availabilityRules = JSON.parse(availabilityRules);
    }
    if (
      availabilityRules &&
      availabilityRules?.length > 0 &&
      availabilityRules !== undefined
    ) {
      for (const rule of availabilityRules) {
        // app check
        let domain = "ONLINE_DELIVERY";

        if (
          flavour === "ONLINE_ORDER" &&
          checkoutDetails?.orderType === "DELIVERY"
        ) {
          if (isMobileApp) {
            domain = "ONLINE_APP_DELIVERY";
          } else {
            domain = "ONLINE_DELIVERY";
          }
        } else if (
          flavour === "ONLINE_ORDER" &&
          checkoutDetails?.orderType === "PICKUP"
        ) {
          if (isMobileApp) {
            domain = "ONLINE_APP_PICKUP";
          } else {
            domain = "ONLINE_PICKUP";
          }
        } else if (flavour === "TABLE") {
          domain = "TABLE_SIDE";
        }
        if (rule?.DM) {
          const isAvailable = rule?.DM?.includes(domain);
          if (!isAvailable) {
            continue; // Skip to the next rule if online delivery is not available
          }
        }

        // date check
        if (rule?.SD && rule?.ED) {
          const startDate = moment(rule.SD, "YYYY-MM-DD");
          const endDate = moment(rule?.ED, "YYYY-MM-DD");

          if (
            momentCurrentTime.isBefore(startDate) ||
            momentCurrentTime.isAfter(endDate)
          ) {
            continue;
          }
        }

        // week day check
        if (rule?.D) {
          const currentWeekDay = momentCurrentTime.format("d");
          if (!rule?.D?.includes(currentWeekDay)) {
            continue;
          }
        }

        // Time check
        if (rule?.H) {
          const currentTimeStr = moment(
            momentCurrentTime.format("HH:mm"),
            "HH:mm"
          );
          // Iterate over each time range in the rule.hours array
          for (const timeRange of rule?.H) {
            // Check if the current time falls within the current time range
            const startTime = moment(timeRange.S, "HH:mm");
            const endTime = moment(timeRange.E, "HH:mm");

            if (
              currentTimeStr.isAfter(startTime) &&
              currentTimeStr.isBefore(endTime)
            ) {
              return true; // Return true if current time is within any time range
            }
          }
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
    return false;
  };

  const checkComboCatAvailability = (product, currentTime) => {
    const momentCurrentTime = moment(currentTime);
    if (product == null) {
      return true;
    }
    let availabilityRules = product?.category_availability;

    if (
      typeof availabilityRules === "string" &&
      availabilityRules !== undefined &&
      availabilityRules !== null
    ) {
      availabilityRules = JSON.parse(availabilityRules);
    }

    if (
      availabilityRules &&
      availabilityRules?.length > 0 &&
      availabilityRules !== undefined
    ) {
      for (const rule of availabilityRules) {
        // app check
        let domain = "ONLINE_DELIVERY";

        if (
          flavour === "ONLINE_ORDER" &&
          checkoutDetails?.orderType === "DELIVERY"
        ) {
          if (isMobileApp) {
            domain = "ONLINE_APP_DELIVERY";
          } else {
            domain = "ONLINE_DELIVERY";
          }
        } else if (
          flavour === "ONLINE_ORDER" &&
          checkoutDetails?.orderType === "PICKUP"
        ) {
          if (isMobileApp) {
            domain = "ONLINE_APP_PICKUP";
          } else {
            domain = "ONLINE_PICKUP";
          }
        } else if (flavour === "TABLE") {
          domain = "TABLE_SIDE";
        }
        if (rule?.DM) {
          const isAvailable = rule?.DM?.includes(domain);
          if (!isAvailable) {
            continue; // Skip to the next rule if online delivery is not available
          }
        }

        // date check
        if (rule?.SD && rule?.ED) {
          const startDate = moment(rule.SD, "YYYY-MM-DD");
          const endDate = moment(rule?.ED, "YYYY-MM-DD");

          if (
            momentCurrentTime.isBefore(startDate) ||
            momentCurrentTime.isAfter(endDate)
          ) {
            continue;
          }
        }

        // week day check
        if (rule?.D) {
          const currentWeekDay = momentCurrentTime.format("d");
          // console.log("currentttttttttt", currentWeekDay);
          if (!rule?.D?.includes(currentWeekDay)) {
            continue;
          }
        }

        // Time check
        if (rule?.H) {
          const currentTimeStr = moment(
            momentCurrentTime.format("HH:mm"),
            "HH:mm"
          );
          // Iterate over each time range in the rule.hours array
          for (const timeRange of rule?.H) {
            // Check if the current time falls within the current time range
            const startTime = moment(timeRange.S, "HH:mm");
            const endTime = moment(timeRange.E, "HH:mm");
            if (
              currentTimeStr.isAfter(startTime) &&
              currentTimeStr.isBefore(endTime)
            ) {
              return true; // Return true if current time is within any time range
            }
          }
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
    return false;
  };

  const formatDateToCheck = (dateString) => {
    const date = new Date(dateString);
    return date?.toLocaleString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    });
  };

  const checkGroupedAvailability = (
    comboCatId,
    comboCategories,
    type,
    time,
    currentTime
  ) => {
    // Group objects by combo_cat_id
    const groupedCombos = comboCategories.filter(
      (combo) => combo.combo_cat_id === comboCatId
    );

    // Check if any combo in the group fails availability check
    const isAllCombosAvailable = groupedCombos.every((combo) => {
      const isComboAvailable = checkComboCatAvailability(
        combo,
        type === "ASAP" ? currentTime : formatDateToCheck(time)
      );

      const isProductAvailable = comboCategories
        .filter((combo) => combo.combo_cat_id === comboCatId) // Filter combos by combo_cat_id
        .some((combo) =>
          checkProductAvailability(
            combo,
            type === "ASAP" ? currentTime : formatDateToCheck(time)
          )
        );

      // If either availability check fails, return false immediately
      return isComboAvailable && isProductAvailable;
    });

    // Return true if all combos in the group are available, false otherwise
    return isAllCombosAvailable;
  };

  const checkCategoryAndProductAvailability = (
    category,
    currentTime,
    checkoutDetails
  ) => {
    // Check category-level availability (using the availability field at the category level)
    const isCategoryAvailable = checkProductAvailability(
      category,
      checkoutDetails?.deliveryType === "ASAP"
        ? currentTime
        : formatDateToCheck(checkoutDetails?.deliveryTime)
    );

    // If the category is inactive or unavailable, return false
    if (!isCategoryAvailable || category?.inactive) {
      return false;
    }

    // Check each item within the category
    const isAnyItemAvailable = category?.item.some((product) =>
      checkProductAvailability(
        product,
        checkoutDetails?.deliveryType === "ASAP"
          ? currentTime
          : formatDateToCheck(checkoutDetails?.deliveryTime)
      )
    );

    // Return true if any product in the category is available, otherwise return false
    return isAnyItemAvailable;
  };

  //return hook
  return {
    handleSortCategory,
    checkProductAvailability,
    handleSortCombo,
    checkComboCatAvailability,
    formatDateToCheck,
    checkGroupedAvailability,
    checkCategoryAndProductAvailability,
  };
};

export default useSortHook;
