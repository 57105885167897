import * as types from "./LoaderTypes";

const INITIAL_STATE = {
  isLoading: false,
  apiCalls: []
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SHOW_LOADER:
      state.apiCalls.push(action.data);
      return {
        ...state,
        isLoading: state.apiCalls.length > 0
      };
    case types.HIDE_LOADER:
      state.apiCalls.shift();
      return {
        ...state,
        isLoading: state.apiCalls.length > 0
      };
    default:
      return state;
  }
};

export default reducer;

// const INITIAL_STATE = {
//   isLoading: false,
//   apiCalls: []
// };

// const reducer = (state = INITIAL_STATE, action) => {

//   if (action.type.indexOf("_REQUEST") > -1) {
//     const apiCall = action.type.slice(0, action.type.indexOf("_REQUEST"))
//     state.apiCalls.push(apiCall);
//     return {
//       ...state,
//       isLoading: state.apiCalls.length > 0
//     }
//   }
//   if (action.type.indexOf("_RECEIVE") > -1) {
//     const apiCall = action.type.slice(0, action.type.indexOf("_RECEIVE"))
//     state.apiCalls = state.apiCalls.filter(a => a !== apiCall);
//     return {
//       ...state,
//       isLoading: state.apiCalls.length > 0
//     }
//   }
//   return state;
// };

// export default reducer;

///////////////////////////////////////////////////////////////////////////////////////////////////////

// import * as types from "./LoaderTypes";

// const INITIAL_STATE = false;

// const reducer = (state = INITIAL_STATE, action) => {
//   switch (action.type) {
//     case types.SHOW_LOADER:
//       return action.data;
//     case types.HIDE_LOADER:
//       return action.data;
//     default:
//       return state;
//   }
// };

// export default reducer;
