import { makeStyles } from "@material-ui/core/styles";
import { calculateFontSize } from "../../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  root: {
    padding: theme.spacing(2),
  },
  titleSection: {},
  title: {
    fontSize: calculateFontSize(20, theme.palette.common.fontVariation),
    fontWeight: 700,
    color: theme.palette.common.themeFontColor,
  },
  description: {
    marginTop: theme.spacing(1),
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    color: theme.palette.common.themeFontColor,
  },
  subTitleSection: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    display: "flex",
    alignItems: "center",
    fontSize: calculateFontSize(13, theme.palette.common.fontVariation),
    fontWeight: 700,
    color: theme.palette.common.themeFontColor,
  },
  subTitleIcon: {
    marginRight: theme.spacing(0.5),
  },
  subSectionContent: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  paymentDiv: {
    display: "flex",
    flexDirection: "column",
  },
  tableHeading: {
    color: theme.palette.common.themeFontColor,
  },
  zoneContainer: {},
  zoneDiv: {},
  detailsSection: {
    width: "100%",
    padding: 0,
    color: theme.palette.common.themeFontColor,
  },
  workingDayListItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  sectionText2: {
    textAlign: "right",
    fontWeight: "bold",
    fontSize: calculateFontSize(12, theme.palette.common.fontVariation),
    color: theme.palette.common.themeFontColor,
  },
  notAvailable: {
    color: theme.palette.common.red,
  },
  bodySection: {
    marginTop: theme.spacing(1),
  },
  closeButton: {
    boxSizing: "border-box",
    zIndex: 100,
    position: "absolute",
    padding: theme.spacing(0.4),
    top: theme.spacing(1),
    right: theme.spacing(3.2),
    width: 32,
    height: 32,
    borderRadius: "50%",
    color: theme.palette.common.themeFontColor,
    // backgroundColor: theme.palette.common.white
  },
  cuisinesItem: {
    display: "flex",
    flexDirection: "row",
  },
  cuisinesItemImage: {
    width: "60px",
    height: "60px",
    marginRight: theme.spacing(1),
  },
  cuisinesItemText: {
    marginBottom: theme.spacing(0),
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    color: theme.palette.common.themeFontColor,
    display: "flex",
    alignItems: "center",
  },
  versionCon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "1rem",
  },
  textDimmed: {
    fontSize: calculateFontSize(12, theme.palette.common.fontVariation),
    color: theme?.palette?.common?.bgVariationFont,
  },
  footerDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  footerLinkText: {
    marginRight: "10px",
    textDecoration: "none",
    color: theme.palette.common.themeFontColor,
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    "&:hover": {
      textDecoration: "none",
      cursor: "pointer",
    },
  },
}));

export default useStyles;
