import React from "react";
// import { useLocation } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
// Material
// import useResponsive from "../../../CustomHooks/useResponsive";
import useStyles from "./OrdersLayout.style.js";
const OrdersLayout = ({ children }) => {
  // const dispatch = useDispatch();
  const classes = useStyles();
  // const location = useLocation();
  // const { hotelKey, hotel } = useSelector((state) => state.hotel);
  // const locationState = useSelector((state) => state.location);
  // const { isMobileView } = useResponsive();

  return (
    <React.Fragment>
      <div className="scroll">
        <div className={clsx("container", classes.bodyContainer)}>
          {children}
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrdersLayout;
