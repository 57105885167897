import React from "react";
import clsx from "clsx";
import useStyles from "./ProfileLayout.style.js";

const ProfileLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className="scroll">
        <div className={clsx("container", classes.bodyContainer)}>
          {children}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProfileLayout;
