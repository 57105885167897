import React, { useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Material
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
// Icons
import CheckBoxOutlineBlankOutlinedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
// Styles
import useStyles from "./CheckBox.style.js";

import * as noImage from "./../../../assets/images/noImage.png";
import { CardMedia } from "@material-ui/core";

const CheckBox = ({
  text,
  secondaryText = null,
  checked,
  classes,
  disabled,
  image,
  isFromCheckout = false,
  onChange = () => {},
}) => {
  const _classes = useStyles();
  const checkBoxRef = useRef(null);
  const clickCheckBox = () => {
    checkBoxRef.current.click();
  };

  return (
    <div className={clsx(_classes.root, classes.rootContainer)}>
      <Checkbox
        disabled={disabled && !checked}
        inputRef={checkBoxRef}
        checked={checked}
        onChange={onChange}
        name={text}
        style={{ display: "none" }}
      />
      <div
        className={clsx(_classes.checkBoxContainer, classes.checkBoxContainer)}
      >
        {checked ? (
          <CheckBoxOutlinedIcon
            className={clsx(
              isFromCheckout ? _classes.checkBox : _classes.splCheckBox
            )}
            onClick={clickCheckBox}
          />
        ) : (
          <CheckBoxOutlineBlankOutlinedIcon
            // className={`${clsx(_classes.checkBox)}${disabled && !checked ? " disabled-checkbox" : ""
            //   }`}
            className={`${clsx(
              isFromCheckout ? _classes.checkBox : _classes.splCheckBox
            )}`}
            onClick={clickCheckBox}
          />
        )}
      </div>
      {image && (
        <CardMedia
          className={clsx(_classes.placeholderImg)}
          component="img"
          src={image || noImage.default}
          onError={(e) => {
            e.target.src = noImage.default;
            e.target.className = `${e.target.className} no-image`;
          }}
        />
      )}
      <div className={_classes.textContainer} onClick={onChange}>
        <Typography
          // color="primary"
          className={`${clsx(
            isFromCheckout ? _classes.text : _classes.splText,
            classes.textColorClass,
            classes.spCheckBoxText
          )}${disabled && !checked ? " disabled" : ""}`}
          variant="caption"
        >
          {text}
        </Typography>
        {secondaryText && (
          <Typography
            // color="primary"
            className={clsx(_classes.secondaryText, classes.textColorClass)}
            variant="caption"
          >
            {secondaryText}
          </Typography>
        )}
      </div>
    </div>
  );
};

CheckBox.propTypes = {
  classes: PropTypes.shape({
    rootContainer: PropTypes.any,
    checkBoxContainer: PropTypes.any,
    checkedClass: PropTypes.any,
    textColorClass: PropTypes.any,
    spCheckBoxText: PropTypes.any,
  }),
};
CheckBox.defaultProps = {
  classes: {
    rootContainer: null,
    checkBoxContainer: null,
    checkedClass: null,
    textColorClass: null,
    spCheckBoxText: null,
  },
};

export default CheckBox;
