import { makeStyles } from "@material-ui/core/styles";
import { calculateFontSize } from "../../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  text: {
    cursor: "pointer",
    fontSize: calculateFontSize(18, theme.palette.common.fontVariation),
    lineHeight: "21px",
    fontWeight: 500,
    // color: theme.palette.common.normalTitle,
    color: theme?.palette?.card?.cardFontColor,
  },
  splText: {
    cursor: "pointer",
    fontSize: calculateFontSize(18, theme.palette.common.fontVariation),
    lineHeight: "21px",
    fontWeight: 500,
    // color: theme?.palette?.card?.cardFontColor,
    color: theme?.palette?.common?.themeFontColor,
  },
  secondaryText: {
    cursor: "pointer",
    fontSize: calculateFontSize(12, theme.palette.common.fontVariation),
    lineHeight: "18px",
    fontWeight: 400,
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
  },
  checkBoxContainer: {
    cursor: "pointer",
    display: "flex",
  },
  checkBox: {
    width: 25,
    height: 25,
    // color: theme.palette.common.normalTitle,
    color: theme?.palette?.card?.cardFontColor,
  },
  splCheckBox: {
    width: 25,
    height: 25,
    color: theme?.palette?.common?.themeFontColor,
  },
  placeholderImg: {
    width: "60px",
    marginRight: "10px",
  },
}));

export default useStyles;
