import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
    position: "absolute",
    width: "100%",
    top: "114px",
    zIndex: 10,
    // backgroundColor : theme.palette.common.red
  },
  messageContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  alertDiv: {
    display: "flex",
    flexDirection: "column",
  },
  actionButtonContainer: {
    // marginTop: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  actionButton: {
    backgroundColor: "#ff9800",
    border: "2px solid #ffffff",
    boxShadow: "none",
    textTransform: "none",
    "&:hover, &:focus": {
      backgroundColor: "#f57c00",
    },
  },
  errorActionButton: {
    backgroundColor: "#f44336",
    border: "1px solid #ffffff",
    boxShadow: "none",
    textTransform: "none",
    "&:hover, &:focus": {
      backgroundColor: "#d32f2f",
    },
  },
}));

export default useStyles;
