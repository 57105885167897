import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  // Define your styles here
  ul: {
    background: theme.palette.card.cardColor,
    color: theme?.palette?.card?.cardFontColor,
    "& ul": {
      color: theme.palette.card.cardFontColor,
      background: theme.palette.card.cardColor,
    },
  },
  selectMenu: {
    background: theme.palette.card.cardColor,
    "& li": {
      color: theme.palette.card.cardFontColor,
    },
  },
}));

function Index({
  slots,
  className,
  value,
  onChange,
  date,
  shouldFilter = false,
  orderTimeType = "shedulued",
  onClick,
  isFromCheckout = false,
  typeChanges = false,
  setNewChange,
  newChange = false,
  setTime,
}) {
  const classes = useStyles();
  const { checkoutDetails } = useSelector((state) => state.cart);
  const [timeslots, setTimeSlots] = useState(slots);
  const formattedTime = moment(checkoutDetails?.deliveryTime)
    .format("hh:mm a")
    .toLowerCase();
  // console.log("formatted timee", formattedTime);
  // console.log("slotsssssssss", slots);
  // Find and set the slot based on the formatted time
  // console.log("formatted timeeeee", formattedTime);
  let matchedSlot = slots?.find((slot) => slot?.value === formattedTime);
  // console.log("matched slotttt", matchedSlot);
  const [timeChanges, setTimeChanges] = useState(false);
  // console.log("matched slotttttt", matchedSlot);
  // console.log("isfrom checkoutttt", isFromCheckout);
  // console.log("checkoutDetails?.deliveryType", checkoutDetails);
  if (
    isFromCheckout &&
    checkoutDetails?.deliveryType === "ASAP" &&
    !matchedSlot
  ) {
    matchedSlot = slots?.find((slot) => slot?.typeOfOrder === "ASAP");
    // console.log("matched solot 2222222222", matchedSlot);
  } else if (
    !isFromCheckout &&
    checkoutDetails?.deliveryType === "SCHEDULED" &&
    !matchedSlot
  ) {
    matchedSlot = slots?.find((slot) => slot?.typeOfOrder === "SCHEDULED");
  }
  // console.log("matched 333333", matchedSlot);
  const [selectedDate, setSelectedDate] = useState(matchedSlot || {});
  // console.log("selected dateeee", selectedDate);
  // console.log("timeeeeeeeeee", timeslots);

  useEffect(() => {
    if (matchedSlot) {
      setSelectedDate(matchedSlot);
      if (setTime) {
        setTime(matchedSlot?.value);
      }
    }
  }, [matchedSlot, checkoutDetails?.deliveryTime]);

  // console.log("selectyed dateeeeee", selectedDate);

  const filteredTimeSlots = (arry) => {
    return arry?.filter((item) => item.typeOfOrder !== "ASAP");
  };

  useEffect(() => {
    if (shouldFilter && slots) {
      setTimeSlots(filteredTimeSlots(slots));
    } else {
      setTimeSlots(slots);
    }
  }, [slots, shouldFilter]);

  useEffect(() => {
    if (
      checkoutDetails?.orderType &&
      slots.length > 0 &&
      isFromCheckout &&
      typeChanges &&
      !timeChanges
    ) {
      // Set the first slot as the default selected when orderType changes
      const firstSlot = slots[0];
      setSelectedDate(firstSlot);

      onChange(firstSlot, "time"); // Trigger onChange for the new selected slot

      setNewChange(true);
    }
  }, [
    checkoutDetails?.orderType,
    slots,
    isFromCheckout,
    typeChanges,
    timeChanges,
  ]);

  // useEffect(() => {
  //   if (selectedDate?.value && !slots.includes(selectedDate)) {
  //     /* ########################
  //       handle rerender of this component on deliverydate updation from delivery time slot generating hooks
  //       this results in creation of new slots which fails to match the object in our selectedDate state.
  //       This is a hack only
  //       #########################
  //     */
  //     const match = slots?.find((s) => s.value === selectedDate.value);
  //     if (match) {
  //       return setSelectedDate(match);
  //     }
  //   }

  //   const SelectedTimeFromSlots = slots?.find(
  //     (s) => s.value === selectedDate.value
  //   );
  //   if (!SelectedTimeFromSlots) {
  //     const data = slots[0];
  //     if (data) {
  //       setSelectedDate(data);
  //       onChange(data, "time");
  //     }

  //     return;
  //   }

  //   if (shouldFilter && orderTimeType !== "asap") {
  //     if (selectedDate?.value) {
  //       onChange(selectedDate, "time");
  //     } else {
  //       const data = slots[0];
  //       if (data) {
  //         setSelectedDate(data);
  //         onChange(data, "time");
  //       }
  //     }
  //   }
  // }, [selectedDate, slots, orderTimeType]);

  const sortTimeSlots = (slots) => {
    return slots.sort((a, b) => {
      // Use moment to parse the 'value' property and compare the times
      const timeA = moment(a.value, "hh:mm a");
      const timeB = moment(b.value, "hh:mm a");
      return timeA - timeB;
    });
  };

  // Assuming `timeslots` is your original array of objects
  const sortedTimeSlots = sortTimeSlots(timeslots);

  return (
    <FormControl variant="outlined" className={className}>
      {/* <InputLabel id="delivery-time">Time</InputLabel> */}
      <Select
        labelId="delivery-time"
        id="delivery-time-select"
        value={selectedDate?.value || ""}
        onChange={(e) => {
          const selected = timeslots.find(
            (slot) => slot.value === e.target.value
          );
          setSelectedDate(selected);
          onChange(selected, "time");
        }}
        onClick={(e) => {
          onClick();
          setTimeChanges(true);
        }}
        label="Time"
        MenuProps={{
          classes: { paper: classes.selectMenu },
        }}
        className={`font-weight-bold ${classes.ul}`}
      >
        {sortedTimeSlots?.map((elem, key) => (
          <MenuItem key={`option-${key}`} value={elem.value}>
            {elem.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

Index.defaultProps = {
  buffer: null,
};

Index.propTypes = {
  buffer: PropTypes.number,
  className: PropTypes.string.isRequired,
};

export default Index;
