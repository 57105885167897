import * as types from "./RestaurantTypes";

//Replace action name and update action types
// export const setRestaurantKey = (payload) => ({
//     type: types.SET_RESTAURANT_KEY,
//     payload
// });

export const getRestaurant = (payload) => ({
    type: types.GET_TAKEY_RESTAURANT_REQUEST,
    payload
});

export const getRestaurantReceive = payload => ({
    type: types.GET_TAKEY_RESTAURANT_RECEIVE,
    payload
});


export const clearResturant = () => ({
    type: types.CLEAR_TAKEY_RESTURANT
});
