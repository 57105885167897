import React, { useEffect, useState } from "react";
import { create } from "jss";
import { useDispatch, useSelector } from "react-redux";
// Material
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { StylesProvider, jssPreset } from "@material-ui/styles";
// Datepicker
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
// Store
import { getTheme } from "./store/Theme/ThemeAction";
// Container
import App from "./containers/App";

import { CookiesProvider } from "react-cookie";
import {
  calculateFontSize,
  hexToRgba,
  setFontColorOnBg,
} from "./CustomHooks/useThemeHook";

const defaultThemeConfig = {
  spacing: 10,
  shape: {
    borderRadius: 10,
  },
  breakpoints: {
    values: {
      xs: 0,
      // sm: 710,
      sm: 830,
      md: 1024,
    },
  },
  overrides: {},
};

function ThemeApp() {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);
  const hotel = useSelector((state) => state.hotel);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [textColor, setTextColor] = useState("black");
  const [btnTextColor, setBtnTextColor] = useState("#000000"); // Default text color is black

  useEffect(() => {
    const fetchFont = async () => {
      try {
        const fontUrl = `https://fonts.googleapis.com/css2?family=${theme?.theme?.font_family}&display=swap`;

        // Create a new link element
        const link = document.createElement("link");
        link.href = fontUrl;
        link.rel = "stylesheet";
        // console.log("linkkkkkkkkk", link);
        // Append the link element to the head of the document
        document.head.appendChild(link);
      } catch (error) {
        console.error("Error fetching font:", error);
      }
    };

    if (theme?.theme?.font_family) {
      fetchFont();
    }
  }, [theme?.theme?.font_family]);

  useEffect(() => {
    if (hotel) {
      // console.log("hotellll",hotel?.hotel)
      if (hotel?.hotel?.dark_theme_active === 1) {
        setIsDarkMode(true);
      } else {
        setIsDarkMode(false);
      }
    }
  }, [hotel]);

  // console.log("theme",theme)

  useEffect(() => {
    // Function to check if the color is light or dark
    const isLightColor = (hex) => {
      // Convert hex to RGB
      const rgb = parseInt(hex?.substring(1), 16);
      const r = (rgb >> 16) & 0xff;
      const g = (rgb >> 8) & 0xff;
      const b = (rgb >> 0) & 0xff;
      // Calculate luminance
      const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
      // Return true if luminance is bright, false if dark
      return luminance > 128;
    };

    // Determine text color based on the brightness of the button color
    if (isLightColor(theme?.theme?.button?.button_color)) {
      setBtnTextColor("#000000"); // Set black text for light colors
    } else {
      setBtnTextColor("#ffffff"); // Set white text for dark colors
    }
  }, [theme?.theme?.button?.button_color]);

  useEffect(() => {
    // Convert theme color to RGB
    const rgbColor = hexToRgb(theme?.color?.primary);

    // Calculate color brightness
    const brightness = calculateBrightness(rgbColor);

    // Set text color based on brightness
    if (brightness > 128) {
      setTextColor("black"); // Use black text for light backgrounds
    } else {
      setTextColor("white"); // Use white text for dark backgrounds
    }
  }, [theme?.color?.primary]);

  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  };

  const convertRgbaToHex = (rgba) => {
    if (rgba.startsWith("#")) {
      return rgba;
    }

    // Remove the alpha part (first two characters)
    const hex = `#${rgba?.slice(2)}`;
    return hex;
  };

  // Function to calculate color brightness
  const calculateBrightness = ({ r, g, b }) => {
    return (r * 299 + g * 587 + b * 114) / 1000;
  };

  const isLightColor = (hex) => {
    const rgb = parseInt(hex?.substring(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luminance > 128;
  };

  // Utility function to lighten or darken a color
  const adjustColorShade = (hex, percent) => {
    const num = parseInt(hex?.slice(1), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      G = ((num >> 8) & 0x00ff) + amt,
      B = (num & 0x0000ff) + amt;
    return `#${(
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)
      .toUpperCase()}`;
  };

  // Function to determine and adjust color shade
  const determineAndAdjustShade = (hex) => {
    return isLightColor(hex)
      ? adjustColorShade(hex, -20)
      : adjustColorShade(hex, 20);
  };

  const getShopBanner = () => {
    return hotel?.hotel?.shop_image || null;
  };

  const jss = create({ plugins: [...jssPreset().plugins] });
  const configureTheme = ({ fonts, color, theme }) => {
    // const configureTheme = ({ fonts, color }) => {
    return createMuiTheme({
      ...defaultThemeConfig,
      typography: {
        htmlFontSize: 16,
        fontFamily: theme?.font_family || fonts.poppins.fontFamily,
        poppins: fonts.poppins,
        rockwell: fonts.rockwell,
        ...fonts.fontWeights,
      },
      palette: {
        primary: {
          main:
            localStorage.getItem("themecolor") != null
              ? localStorage.getItem("themecolor")
              : color?.primary
              ? color?.primary
              : "#14213d",
          // main: "#a2d2ff",

          // main: "#9C0A35",
        },
        secondary: {
          main: color.secondary,
          // main: "#000000"
        },
        // background: color.background,

        common: {
          background: isDarkMode
            ? "#000000"
            : theme?.background_color || "#F0F5F5",
          white: isDarkMode ? "#131719" : color.white,
          lighterBg: isDarkMode ? "#1E2427" : color.white,
          fontTitle: isDarkMode ? "#ffffff" : "#000",
          themeFontColor: setFontColorOnBg(
            theme?.background_color || "#F0F5F5"
          ),
          buttonFontColor: setFontColorOnBg(
            theme?.button?.button_color || "#000"
          ),
          headerFontColor: setFontColorOnBg(theme?.header_color || "#000000"),
          subHeaderFontColor: setFontColorOnBg(
            theme?.header_color || "#ffffff"
          ),
          bgVariation: determineAndAdjustShade(
            theme?.background_color || "#F0F5F5"
          ),
          bgVariationFont: setFontColorOnBg(
            determineAndAdjustShade(theme?.background_color || "#F0F5F5")
          ),
          normalTitle: isDarkMode ? "#f1f1f1" : "#000000",
          black: isDarkMode ? "#000" : "#fff",
          subTitle: isDarkMode ? "gray" : "#000000",
          scrollBar: isDarkMode ? "#ababab" : "#f5f5f5",
          scrollBarIdle: isDarkMode ? "#666" : "#888",
          whiteTitle: "#FFFFFF",
          backgroundImage: theme?.background_image,
          headerText: textColor,
          red: color.red,
          green: color.green,
          grey: "#414A50",
          lightGrey: "#c6c6c6",
          fontVariation: theme?.font_variation,
          fontFamily: theme?.font_family || fonts.poppins.fontFamily,
          headerColor: theme?.header_color,
          shopBanner: getShopBanner(),
        },
        button: {
          borderRadius: theme?.button?.border_radius,
          buttonBorder: theme?.button?.button_border,
          buttonColor: theme?.button?.button_color,
          buttonFont: btnTextColor,
          buttonHover: determineAndAdjustShade(
            theme?.button?.button_color || "#000000"
          ),
          buttonHoverFont: setFontColorOnBg(
            determineAndAdjustShade(theme?.button?.button_color || "#000000")
          ),
        },
        card: {
          cardBorder: theme?.card?.card_border,
          cardBorderRadius: theme?.card?.card_border_radius,
          cardBorderColor: theme?.card?.card_border_color || "#000000",
          cardOpacity: theme?.card?.card_transpirancy || 1,
          position: theme?.card?.card_image_position || 0,
          cardHeight: theme?.card?.cardHeight || "135px",
          cardColor: hexToRgba(
            convertRgbaToHex(theme?.card?.card_background_color || "#ffffff"),
            theme?.card?.card_transpirancy || 1
          ),
          cardFontColor: setFontColorOnBg(
            convertRgbaToHex(theme?.card?.card_background_color || "#ffffff")
          ),
          // cardFontColor: setFontColorOnBg("#ffffff", "helloo"),
          cardShadeColor: determineAndAdjustShade(
            convertRgbaToHex(theme?.card?.card_background_color || "#ffffff")
          ),
          cardShadeFontColor: setFontColorOnBg(
            determineAndAdjustShade(
              convertRgbaToHex(theme?.card?.card_background_color || "#ffffff")
            )
          ),
        },
        modal: {
          modalBorder: theme?.modal?.modal_border,
          modalBorderRadius: theme?.modal?.modal_border_radius,
        },
        isDeliveryModalVisible: hotel?.hotel?.order_prompt_dialog,
      },
    });
  };

  useEffect(() => {
    dispatch(getTheme());
  }, [dispatch]);

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={configureTheme(theme)}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default ThemeApp;
