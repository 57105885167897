import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CheckoutGif from "../../assets/gifs/checkoutGif.json";
import Lottie from "react-lottie";

const useStyles = makeStyles((theme) => ({
  loaderOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
}));

const CheckoutLoader = () => {
  const classes = useStyles();

  const loader = {
    loop: true,
    autoplay: true,
    animationData: CheckoutGif,
  };

  return (
    <div className={classes.loaderOverlay}>
      <Lottie options={loader} height={300} width={300} />
    </div>
  );
};

export default CheckoutLoader;
