import { Drawer, Grid, IconButton, Typography } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { DeleteForeverOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearCart, saveCheckoutForm } from "../../../store/Cart/CartActions";
import { logoutUser } from "../../../store/User/UserAction";
import useStyles from "./AvatarMenu.style";
import AvatarItem from "./AvatarItem.js";
import {
  PersonAdd,
  Fastfood,
  CropFree,
  Redeem,
  ExitToApp,
  Input,
} from "@material-ui/icons";
import useWalletPoints from "../../../CustomHooks/useWalletPoints.js";
import { resetUserWalletDetails } from "../../../store/Restaurant/RestaurantActions.js";
import SpecialAlert from "../../SpecialAlert/SpecialAlert.js";
import {
  deleteAccount,
  setAccountDeleteStatus,
} from "../../../store/Profile/ProfileActions.js";

export const AvatarMenu = ({ setOpen }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isDeleteAcountProccesing, AccountDeleteStatus } = useSelector(
    (state) => state.profile
  );
  const user = useSelector((state) => state.user);
  const { hotelKey } = useSelector((state) => state.hotel);
  const { resetWalletProducts } = useWalletPoints();

  const [drawer, setDrawer] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteConfirmMessage, setDeleteConfirmMessage] = useState(false);

  const handleClick = (event) => {
    // setDrawer(event.currentTarget);
    setDrawer(true);
  };
  const handleClose = () => {
    setDrawer(false);
  };

  useEffect(() => {
    if (AccountDeleteStatus && !isDeleteAcountProccesing) {
      setDeleteConfirm(false);
      setDeleteConfirmMessage(true);
    }
  }, [AccountDeleteStatus, isDeleteAcountProccesing]);

  const handleAccountDelete = () => {
    setDeleteConfirm(true);
  };

  const handleConfirmClose = () => {
    setDeleteConfirm(false);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteAccount(user?.email));
  };

  const handleCloseConfirmationMessage = () => {
    setDeleteConfirmMessage(false);
    dispatch(setAccountDeleteStatus(false));
    dispatch(logoutUser());
    resetWalletProducts(2);
    resetUserWalletDetails();
    dispatch(
      saveCheckoutForm({
        customer_name: "",
        customer_phone: "",
        customer_email: "",
        customer_address: "",
        customer_city: "",
        customer_zipcode: "",
      })
    );

    history.push(`/${hotelKey}`);
  };

  return (
    <div>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.avatar}
      >
        {user ? (
          user?.name?.[0]
        ) : (
          <AccountCircleIcon className={classes.avatarIcon} />
        )}
      </IconButton>

      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="right"
        open={drawer}
        onClose={() => setDrawer(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.filterDrawerContainer}>
          <Typography className={classes.filterDrawerTitle} variant="h3">
            Menu
          </Typography>

          <Grid container spacing={2}>
            {!user && (
              <Grid item xs={12} sm={12}>
                {/* <ChoiceItem
                  // disabled={!opt.active}
                  title={"Login"}
                  type={"Login"}
                  value={"Login"}
                  // selected={"Login"}
                  handleClick={() => {
                    handleClose();
                    setOpen(true);
                  }}
                /> */}
                <AvatarItem
                  title={"Login"}
                  // disabled={!opt.active}
                  icon={<Input />}
                  type={"Login"}
                  value={"Login"}
                  handleClick={() => {
                    handleClose();
                    setOpen(true);
                  }}
                />
              </Grid>
            )}

            {user && (
              <Grid item xs={12} sm={12}>
                {/* <ChoiceItem
                  // disabled={!opt.active}
                  title={"Edit Profile"}
                  type={"Edit Profile"}
                  value={"Edit Profile"}
                  // selected={"Edit Profile"}
                  handleClick={() => {
                    handleClose();
                    history.push(`/${hotelKey}/profile`);
                  }}
                /> */}
                <AvatarItem
                  title={"Profile"}
                  // disabled={!opt.active}
                  icon={<PersonAdd />}
                  type={"Profile"}
                  value={"Profile"}
                  handleClick={() => {
                    handleClose();
                    history.push(`/${hotelKey}/profile`);
                  }}
                />
              </Grid>
            )}
            {user && (
              <Grid item xs={12} sm={12}>
                {/* <ChoiceItem
                  // disabled={!opt.active}
                  title={"Orders"}
                  type={"Orders"}
                  value={"Orders"}
                  // selected={"Orders"}
                  handleClick={() => {
                    handleClose();
                    history.push(`/${hotelKey}/orders`);
                  }}
                /> */}
                <AvatarItem
                  title={"Orders"}
                  // disabled={!opt.active}
                  icon={<Fastfood />}
                  type={"Orders"}
                  value={"Orders"}
                  handleClick={() => {
                    handleClose();
                    history.push(`/${hotelKey}/orders`);
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12}>
              {/* <ChoiceItem
                disabled={true}
                title={"My QR"}
                type={"My QR"}
                value={"My QR"}
                // selected={"My QR"}
                handleClick={() => {
                  handleClose();
                  history.push(`/${hotelKey}/profile`);
                }}
              /> */}
              <AvatarItem
                title={"My QR"}
                // disabled={!opt.active}
                icon={<CropFree />}
                type={"My QR"}
                value={"My QR"}
                handleClick={() => {
                  handleClose();
                  history.push(`/${hotelKey}/profile`);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              {/* <ChoiceItem
                disabled={true}
                title={"Coupons"}
                type={"Coupons"}
                value={"Coupons"}
                // selected={"Coupons"}
                handleClick={() => {}}
              /> */}
              <AvatarItem
                title={"Coupons"}
                // disabled={!opt.active}
                icon={<Redeem />}
                type={"Coupons"}
                value={"Coupons"}
                handleClick={() => {}}
              />
            </Grid>

            {user && (
              <Grid item xs={12} sm={12}>
                {/* <ChoiceItem
                  // disabled={!opt.active}
                  title={"Logout"}
                  type={"Logout"}
                  value={"Logout"}
                  // selected={"Logout"}
                  handleClick={() => {
                    handleClose();
                    dispatch(logoutUser());
                    dispatch(
                      saveCheckoutForm({
                        customer_name: "",
                        customer_phone: "",
                        customer_email: "",
                        customer_address: "",
                        customer_city: "",
                        customer_zipcode: "",
                      })
                    );
                    history.push(`/${hotelKey}`);
                  }}
                /> */}
                <AvatarItem
                  title={"Logout"}
                  // disabled={!opt.active}
                  icon={<ExitToApp />}
                  type={"Logout"}
                  value={"Logout"}
                  handleClick={() => {
                    handleClose();
                    dispatch(logoutUser());
                    resetWalletProducts(2);
                    resetUserWalletDetails();
                    dispatch(
                      saveCheckoutForm({
                        customer_name: "",
                        customer_phone: "",
                        customer_email: "",
                        customer_address: "",
                        customer_city: "",
                        customer_zipcode: "",
                      })
                    );

                    history.push(`/${hotelKey}`);
                  }}
                />
              </Grid>
            )}
            {user && (
              <Grid item xs={12} sm={12}>
                <AvatarItem
                  title={"Delete Account"}
                  // disabled={!opt.active}
                  isDelete={true}
                  icon={<DeleteForeverOutlined />}
                  type={"Delete"}
                  value={"Delete"}
                  handleClick={handleAccountDelete}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </Drawer>

      {/* <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {user ? (
          <div>
            <MenuItem
              onClick={() => {
                handleClose();
                history.push(`/${hotelKey}/profile`);
              }}
            >
              Profile
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                history.push(`/${hotelKey}/orders`);
              }}
            >
              Orders
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                dispatch(logoutUser());
                dispatch(
                  saveCheckoutForm({
                    customer_name: "",
                    customer_phone: "",
                    customer_email: "",
                    customer_address: "",
                    customer_city: "",
                    customer_zipcode: "",
                  })
                );
              }}
            >
              Logout
            </MenuItem>
          </div>
        ) : (
          <MenuItem
            onClick={() => {
              handleClose();
              setOpen(true);
            }}
          >
            Login
          </MenuItem>
        )}
      </Menu> */}
      <SpecialAlert
        title="Are You sure want to delete Account?"
        open={deleteConfirm}
        isLoading={isDeleteAcountProccesing}
        isCancel={true}
        handleCloseModal={handleConfirmClose}
        handleClose={handleConfirmDelete}
      />
      <SpecialAlert
        title="Your account deletion request has been received. Please note that your account will be permanently deleted after 30 days if you do not log in again within that period."
        open={deleteConfirmMessage}
        handleClose={handleCloseConfirmationMessage}
      />
    </div>
  );
};
