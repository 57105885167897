import axios from "axios";
import { requestHandler, successHandler, errorHandler } from "../interceptors";
import { API } from "../../utils/Constants";

const getHostname = () => window.location.hostname; // You can replace this with your logic to get the hostname

// Function to update headers with the hostname
const updateHeadersWithHostname = (config) => {
  config.headers = {
    ...config.headers,
    host_url: getHostname(),
    // Add other headers as needed
  };
  return config;
};

//add your BASE_URL to Constants file
export const axiosInstance = axios.create({
  baseURL: `${API.BASE_URL}${API.VERSION ? `/${API.VERSION}` : ``}`,
  headers: {
    "Content-Type": "application/json",
  },
});

// Handle request process
axiosInstance.interceptors.request.use((request) => {
  // Update headers with the hostname
  request = updateHeadersWithHostname(request);
  return requestHandler(request);
});
// Handle response process
axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

////////////////////////////////////////////////////////////////NODE axios instance
export const axiosInstanceNode = axios.create({
  baseURL: `${API.BASE_URL_NODE}`,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstanceNode.interceptors.request.use((request) =>
  requestHandler(request)
);
axiosInstanceNode.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

////////////////////////////////////////////////////////////////SOCKET axios instance
export const axiosInstanceSocket = axios.create({
  baseURL: `${API.BASE_URL_SOCKET}`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosInstanceSocketV2 = axios.create({
  baseURL: `${API.BASE_UTL_SOCKET_V2}`,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstanceNode.interceptors.request.use((request) =>
  requestHandler(request)
);

axiosInstanceNode.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
