import store from "../store";
import { setErrorMessage, resetErrorMessage } from "../store/Error/ErrorAction";
import { showSnackbarAction } from "../store/Snackbar/SnackbarAction";

// To show error message that returned from backend
export function dispatchSnackbarError(errorMsg) {
  if (errorMsg) {
    store.dispatch(showSnackbarAction(errorMsg, "error"));
  }
}
// To show success message after any success request if needed and rendered from locale files
export function dispatchSnackbarSuccess(message) {
  store.dispatch(showSnackbarAction(message, "success"));
}

// To show error message that returned from backend
export function dispatchErrorMessageAsDialog(errorObj) {
  store.dispatch(
    setErrorMessage({
      ...errorObj,
      showAs: "dialog",
    })
  );
}
export function dispatchErrorMessageAsWarning(errorObj) {
  store.dispatch(
    setErrorMessage({
      ...errorObj,
      showAs: "Warning",
    })
  );
}

export function dispatchResetErrorMessage() {
  store.dispatch(resetErrorMessage());
}
