import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import { setFontColorOnBg } from "../../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  // Define your styles here
  color: theme.palette.common.fontTitle,
  btn: {
    backgroundColor: theme?.palette?.button?.buttonColor || "black",
    color: setFontColorOnBg(theme?.palette?.button?.buttonColor || "#000000"),
    borderRadius: theme?.palette?.button?.borderRadius || "12px",
    border: theme?.palette?.button?.buttonBorder ? "1px solid black" : null,
    "&:hover": {
      color: "#fff",
      pointer: "cursor",
      borderRadius: "10px",
    },
  },
}));

const Btn = ({
  count,
  text,
  handleClick,
  startIcon,
  endIcon,
  disabled,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      // color="primary"
      className={classes.btn}
      onClick={handleClick}
      disabled={disabled}
      startIcon={startIcon || null}
      endIcon={endIcon || null}
      {...props}
    >
      {count !== 0 && count !== undefined ? `${text} (${count})` : text}
    </Button>
  );
};

export default Btn;
