import { useEffect } from "react";
import { useRef } from "react";
import { isEqual } from "lodash";

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    const previousValue = JSON.parse(ref.current || null);
    if (!isEqual(previousValue, value)) {
      ref.current = JSON.stringify(value);
    }
  });
  return ref.current ? JSON.parse(ref.current) : null;
};

export default usePrevious;
