// Replace with your request types
export const ORDER_REQUEST = "ORDER_REQUEST";
export const ORDER_FAILED = "ORDER_FAILED";
export const ORDER_RECEIVE = "ORDER_RECEIVE";
export const VERIFY_PAYMENT_REQUEST = "VERIFY_PAYMENT_REQUEST";
export const VERIFY_PAYMENT_RECEIVE = "VERIFY_PAYMENT_RECEIVE";
export const PAYMENT_STATUS_CODE = "PAYMENT_STATUS_CODE";
export const ORDER_STATUS_REQUEST = "ORDER_STATUS_REQUEST";
export const ORDER_STATUS_RECEIVE = "ORDER_STATUS_RECEIVE";
export const CLEAR_ORDER = "CLEAR_ORDER";
export const CLEAR_COMPLETE_ORDER = "CLEAR_COMPLETE_ORDER";
export const GET_ORDER_HISTORY = "GET_ORDER_HISTORY";
export const ORDER_HISTORY = "ORDER_HISTORY";
export const UPDATE_ORDER_REQUEST_LOADING = "UPDATE_ORDER_REQUEST_LOADING";
export const PAY_STATUS = "PAY_STATUS";
export const FAILED_MSG = "FAILED_MSG";
export const INCREMENT_ORDER_RETRY_COUNT = "INCREMENT_ORDER_RETRY_COUNT";
export const RESET_ORDER_RETRY_COUNT = "RESET_ORDER_RETRY_COUNT";
export const SET_ORDER_PAGINATION = "SET_ORDER_PAGINATION";
export const GET_ORDER_HISTORY_BY_PAGE_NO = "GET_ORDER_HISTORY_BY_PAGE_NO";
export const SET_ERROR_MODAL_CHECKOUT_OPEN = "SET_ERROR_MODAL_CHECKOUT_OPEN";
export const SET_ERROR_MODAL_CHECKOUT_CLOSE = "SET_ERROR_MODAL_CHECKOUT_CLOSE";
export const SET_UBER_FAILED_MODAL = "SET_UBER_FAILED_MODAL";
export const RESET_CHECKOUT_ERR_MODAL = "RESET_CHECKOUT_ERR_MODAL";
export const CANCEL_ORDER_STATUS_CHECK = "CANCEL_ORDER_STATUS_CHECK";
