import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAvailableCoupons,
  clearCoupon,
  getCoupon,
  setAvailableCoupons,
  setCouponValidationResult,
  setDiscountRemoveModal,
} from "../../../store/Cart/CartActions";
import { ALL_DELIVERY_TYPES, COUPONS } from "../../../utils/Constants";
import Btn from "../../Controls/Button/Button";
import Modal from "../../Material/Modal/Modal";
import CouponList from "../CouponList/CouponList";
import CouponSuccess from "../CouponSuccess/CouponSuccess";
import useStyles from "./Coupons.style";
import { resetWallet } from "../../../store/Profile/ProfileActions";
import useWalletPoints from "../../../CustomHooks/useWalletPoints";
import SpecialAlert from "../../SpecialAlert/SpecialAlert";
import useSortHook from "../../../CustomHooks/useSortHook";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const Coupons = () => {
  const modalInit = {
    type: "",
    show: false,
  };
  const history = useHistory();

  const dispatch = useDispatch();
  const classes = useStyles();
  const [modal, toggleModal] = useState(modalInit);
  const { checkProductAvailability, formatDateToCheck } = useSortHook();
  const [applicableCoupons, SetApplicableCoupons] = useState([]);
  const {
    selectedCoupon,
    coupons,
    priceSummary,
    isDiscountRemoveModal,
    checkoutDetails,
    data,
  } = useSelector((state) => state.cart);
  const { hotel, hotelKey } = useSelector((state) => state.hotel);

  const {
    resetWalletProducts,
    RemoveItemCoupon,
    checkIsProductLoyalityApplied,
  } = useWalletPoints();
  const [currentTime, setCurrentTime] = useState(moment()); // Initialize current time using moment

  // Update current time every second using useEffect
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const clearCurrentCoupon = () => {
    dispatch(resetWallet());
    resetWalletProducts(2);
    dispatch(clearCoupon());
  };

  const checkItemDiscountApplicability = (coupon) => {
    const applicableItems = coupon?.items || [];
    // const validItemsInCart = data?.filter((item) =>
    //   applicableItems.includes(item.itemId)
    // );

    const validItemsInCart = data?.filter((item) =>
      applicableItems.includes(item?.itemId)
    );
    const isDiscountApplicable =
      Number(coupon?.result?.DISCOUNT) < 100
        ? Number(priceSummary?.subTotal) >
            Number(coupon?.rules?.MIN_ORDER_VALUE) &&
          Number(priceSummary?.subTotal) <
            Number(coupon?.rules?.MAX_ORDER_VALUE) &&
          (coupon?.rules?.APPLICABLE_FOR === checkoutDetails?.orderType ||
            coupon?.rules?.APPLICABLE_FOR === ALL_DELIVERY_TYPES) &&
          coupon?.rules?.type === COUPONS?.ITEM_DISCOUNT &&
          validItemsInCart.length > 0
        : Number(priceSummary?.subTotal) >
            Number(coupon?.rules?.MIN_ORDER_VALUE) &&
          Number(priceSummary?.subTotal) <
            Number(coupon?.rules?.MAX_ORDER_VALUE) &&
          (coupon?.rules?.APPLICABLE_FOR === checkoutDetails?.orderType ||
            coupon?.rules?.APPLICABLE_FOR === ALL_DELIVERY_TYPES) &&
          coupon?.rules?.type === COUPONS?.ITEM_DISCOUNT;

    return isDiscountApplicable;
  };

  const findApplicableCoupons = () => {
    dispatch(clearAvailableCoupons());
    const newCoupons = coupons?.filter(
      (c) =>
        Number(priceSummary?.subTotal) > Number(c?.rules?.MIN_ORDER_VALUE) &&
        Number(priceSummary?.subTotal) < Number(c?.rules?.MAX_ORDER_VALUE) &&
        (c?.rules?.APPLICABLE_FOR === checkoutDetails?.orderType ||
          c?.rules?.APPLICABLE_FOR === ALL_DELIVERY_TYPES) &&
        (c?.rules?.type === COUPONS?.ITEM_DISCOUNT
          ? checkItemDiscountApplicability(c)
          : true) &&
        checkProductAvailability(
          c,
          checkoutDetails?.deliveryType === "ASAP"
            ? currentTime.toDate()
            : formatDateToCheck(checkoutDetails?.deliveryTime)
        )
    );
    dispatch(setAvailableCoupons(newCoupons));
    SetApplicableCoupons(newCoupons);
  };

  const selectCoupenHandler = (applied) => {
    toggleModal({
      ...modal,
      show: false,
    });
    if (applied) {
      toggleModal({
        type: "success",
        show: true,
      });
    }
  };

  useEffect(() => {
    if (!selectedCoupon) {
      dispatch(setCouponValidationResult(false));
    }
  }, [selectedCoupon]);

  useEffect(() => {
    findApplicableCoupons();
  }, [coupons, priceSummary, checkoutDetails?.orderType, selectedCoupon]);

  const handleCouponClick = () => {
    if (checkIsProductLoyalityApplied()) {
      dispatch(setDiscountRemoveModal(true));
    } else {
      if (!selectedCoupon?.id) {
        toggleModal({ type: "coupon", show: true });

        dispatch(getCoupon(hotel?.hotelId));
        findApplicableCoupons();
      } else {
        if (selectedCoupon?.result?.type === "ITEM_DISCOUNT") {
          RemoveItemCoupon(selectedCoupon);
          clearCurrentCoupon();
          dispatch(clearCoupon());
        }
        dispatch(setCouponValidationResult(false));
        // Clear current coupon and update the list of applicable coupons
        clearCurrentCoupon();
        // Re-render the applicable coupons list after removing the coupon
      }
    }
  };

  const handleRemoveWalletFunc = () => {
    resetWalletProducts(2);
    dispatch(resetWallet());
    dispatch(setDiscountRemoveModal(false));
    if (!selectedCoupon?.id) {
      toggleModal({ type: "coupon", show: true });
    } else {
      clearCurrentCoupon();
    }
  };

  const handleModalClick = () => {
    dispatch(setDiscountRemoveModal(false));
  };

  const handleSkipButton = () => {
    toggleModal({
      ...modal,
      show: false,
    });
    history.push(`/${hotelKey}/cart/checkout`);
  };

  return (
    <div className={classes.root}>
      <Typography
        // color="primary"
        className={classes.headerTitle}
        variant="h2"
        noWrap
      >
        {"Coupons"}
      </Typography>
      <div className={`${classes.listContainerRow} align-items-center`}>
        <Typography
          // color="primary"
          className={`${classes.headerTitle} ${
            !selectedCoupon?.id && classes.headerTitleEmpty
          } m-0`}
          variant="h2"
          noWrap
        >
          {selectedCoupon?.id ? selectedCoupon.name : "Select coupon"}
        </Typography>
        <Btn
          text={!selectedCoupon?.id ? "Select" : "Remove"}
          className={`${classes.btn}`}
          handleClick={handleCouponClick}
        />
      </div>
      <Modal
        customView={true}
        open={modal.show}
        handleClose={() =>
          toggleModal({
            type: "",
            show: false,
          })
        }
        modelContainer={
          modal.type === "success" && classes.orderTypeModalContainer
        }
      >
        {modal.type === "coupon" && (
          <CouponList
            handleClose={(d) => selectCoupenHandler(d)}
            coupons={applicableCoupons || []}
            handleSkip={handleSkipButton}
          />
        )}
        {modal.type === "success" && (
          <CouponSuccess
            discount={priceSummary?.discount || 0}
            handleClose={() => {
              toggleModal({
                type: "",
                show: false,
              });
              dispatch(setCouponValidationResult(false));
            }}
          />
        )}
      </Modal>
      {
        <SpecialAlert
          open={isDiscountRemoveModal}
          isCancel={true}
          handleCloseModal={handleModalClick}
          handleClose={handleRemoveWalletFunc}
          title={
            "Applying the coupon will remove your redeemed loyalty balance. Do you want to continue?"
          }
        />
      }
    </div>
  );
};

export default Coupons;
