export const formatGoogleAddress = (loc) => {
  let googlAddString = {
    address: "",
    city: "",
    zipcode: "",
  };
  //   let googlAddString = {
  //     route: "",
  //     sublocality: "",
  //     locality: "",
  //     administrative_area_level_1: "",
  //     postal_code: "",
  //   };
  if (loc && loc?.address_components) {
    loc.address_components.forEach((a) => {
      //   addresses = [];
      //   console.log(' a.types ', a.types)
      //   if (
      //     a.types.indexOf("street_number") > -1 ||
      //     a.types.indexOf("route") > -1 ||
      //     a.types.indexOf("sublocality") > -1 ||
      //     a.types.indexOf("locality") > -1
      //   ) {
      //     addresses.push(a.long_name);
      //   } else
      if (a.types.indexOf("administrative_area_level_1") > -1 || a.types.indexOf("locality") > -1) {
        googlAddString.city = a.long_name;
      } else if (a.types.indexOf("postal_code") > -1) {
        googlAddString.zipcode = a.long_name;
      }

      //   if (a.types.indexOf("route") > -1) {
      //     googlAddString.route = a.long_name;
      //   } else if (a.types.indexOf("sublocality") > -1) {
      //     googlAddString.sublocality = a.long_name;
      //   } else if (a.types.indexOf("locality") > -1) {
      //     googlAddString.locality = a.long_name;
      //   } else if (a.types.indexOf("administrative_area_level_1") > -1) {
      //     googlAddString.administrative_area_level_1 = a.long_name;
      //   } else if (a.types.indexOf("postal_code") > -1) {
      //     googlAddString.postal_code = a.long_name;
      //   }
    });
    // console.log("addresses ", addresses);
    // googlAddString = { ...googlAddString, address: addresses.join(",") };
    googlAddString = { ...googlAddString, address: loc.formatted_address };
  }
  return googlAddString;
};
