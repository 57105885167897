import { Box, Typography } from "@material-ui/core";
import { useState } from "react";
import { aboutUsLink, contactUsLink, homeLink, pricingLink, privacyPolicyLink, refundsAndCancellationLink, shippingAndDeliveryPolicyLink, tCLink } from "../../config/app-config";
import { SILVERBACK_APP_NAME } from "../../utils/Constants";
import useStyles from "./SiteFooter.style";

const SiteFooter = () => {
    const classes = useStyles();
    // eslint-disable-next-line no-unused-vars
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    return (
        <Box className={classes.footerSection}>
            <div className="copyright-area">
                <div className="container">
                    <div className="text-center">
                        <div className={classes.footerMenu}>
                            <ul className={classes.list}>
                                <li>
                                    <a className={classes.navLabel} href={aboutUsLink} target="_blank" rel="noreferrer">
                                        <Typography variant="caption">About Us</Typography>
                                    </a>
                                </li>
                                <li>
                                    <a className={classes.navLabel} href={tCLink} target="_blank" rel="noreferrer">
                                        <Typography variant="caption">Terms & Conditions</Typography>
                                    </a>
                                </li>
                                <li>
                                    <a className={classes.navLabel} href={privacyPolicyLink} target="_blank" rel="noreferrer">
                                        <Typography variant="caption">Privacy</Typography>
                                    </a>
                                </li>
                                <li>
                                    <a className={classes.navLabel} href={refundsAndCancellationLink} target="_blank" rel="noreferrer">
                                        <Typography variant="caption">Refunds/Cancellations</Typography>
                                    </a>
                                </li>
                                <li>
                                    <a className={classes.navLabel} href={shippingAndDeliveryPolicyLink} target="_blank" rel="noreferrer">
                                        <Typography variant="caption">Shipping & Delivery policy</Typography>
                                    </a>
                                </li>
                                <li>
                                    <a className={classes.navLabel} href={pricingLink} target="_blank" rel="noreferrer">
                                        <Typography variant="caption">Pricing</Typography>
                                    </a>
                                </li>
                                <li>
                                    <a className={classes.navLabel} href={contactUsLink} target="_blank" rel="noreferrer">
                                        <Typography variant="caption">Contact Us</Typography>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <div className="copyright-text">
                        <Typography className={classes.copyrightText} variant="caption">
                            Copyright &copy; {currentYear}, All Right Reserved <a className={classes.navLabel} href={homeLink} target="_blank" rel="noreferrer"><b>{SILVERBACK_APP_NAME}</b></a>
                        </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </Box>
    );
};

export default SiteFooter;