import moment from "moment";
import { axiosInstance, axiosInstanceNode } from "../../network/apis";
import { API, APP_VERSION } from "../../utils/Constants";

// Create Payload Utils
import * as Payload from "../../utils/Payload";

export const order = async (
  hotel,
  cart,
  location,
  user,
  paymentMethod,
  voucherData,
  profile
) => {
  const payload = Payload.cart(
    hotel,
    cart,
    location,
    user,
    paymentMethod,
    voucherData,
    profile
  );

  // console.log("payloadddddddd", payload);

  function getCurrentTime() {
    return moment().format("YYYY-MM-DD HH:mm:ss");
  }

  const currentTime = getCurrentTime();
  // const headers = {
  //   'Authorization': 'Bearer your_token',
  //   'X-App-Version': process.env.REACT_APP_VERSION,
  //   'X-Current-Time': currentTime
  // };

  // console.log("payloaddddddddd", payload);

  const { data } = await axiosInstance.post(
    `${API.ENDPOINTS.ONLINE_ORDER}`,
    payload,
    // { X_App_Version: `${APP_VERSION}`, X_Api_Call_Time: `${currentTime}` },
    {
      headers: {
        "X-App-Version": APP_VERSION,
        "X-Current-Time": currentTime,
      },
    },
    { handlerEnabled: false }
    // {
    //   headers: {
    //     'X-App-Version': process.env.REACT_APP_VERSION,
    //     'X-Api-Call-Time': currentTime,
    //   },
    // }
  );
  return { data: { ...data, status: data.status === "true" ? true : false } };
};

export const verifyPayment = async (orderId) => {
  try {
    const { data, status } = await axiosInstance.post(
      `${API.ENDPOINTS.VERIFY_PAYMENT}`,
      {
        order_id: orderId,
      },
      {
        handlerEnabled: true,
      }
    );
    // console.log("dataaaaaaaaaaaaaa", data)
    // if (data.status === false) {
    //   throw new Error(data || "Error");
    // }

    return { data, status }; // Return an object with both data and status
  } catch (error) {
    // console.log("errorrr", error)
    return {
      errorStatus: error?.response?.status,
      errorMsg: error?.response?.data?.message,
    };
    throw error;
  }
};

export const getOrderStatus = async (orderId) => {
  const { data } = await axiosInstance.post(
    `${API.ENDPOINTS.ORDER_STATUS}`,
    {
      order_id: orderId,
    },
    {
      handlerEnabled: true,
    }
  );
  if (data.status === false) {
    throw new Error(data.message);
  }
  return { data };
};

export const getOrderHistory = async (cusId) => {
  const { data } = await axiosInstance.get(
    `${API.ENDPOINTS.ORDER_HISTORY}?cusId=${cusId}`,
    {
      handlerEnabled: false,
    }
  );
  if (data.status === false) {
    throw new Error(data.message);
  }
  return { data };
};

export const getOrderHistoryByPage = async (cusId, pageNo) => {
  const { data } = await axiosInstance.get(
    `${API.ENDPOINTS.ORDER_HISTORY}?cusId=${cusId}&page=${pageNo}`,
    {
      handlerEnabled: false,
    }
  );
  if (data.status === false) {
    throw new Error(data.message);
  }
  return { data };
};
