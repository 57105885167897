import { axiosInstance } from "../../../network/apis";
import { API } from "../../../utils/Constants";

export const submitJoinUs = async (form_data) => {
  const { data } = await axiosInstance.post(
    `${API.ENDPOINTS.TAKEY_JOIN_US}`,
    { form_data },
    { handlerEnabled: false }
  );
  if (data.status === false) {
    throw new Error(data.message);
  }
  return { data: data.data };
};
