import useStyles from "./TableListing.style";
// Icons
import CloseIcon from "@material-ui/icons/Close";
import IconBtn from "../Controls/IconButton/IconButton";
import { TextField, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Btn from "../Controls/Button/Button";
import { selectTable, setGuestCount } from "../../store/Cart/CartActions";
import { useEffect } from "react";

const TableListing = ({ handleClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { tables } = useSelector((state) => state.hotel);
    const { guestCount } = useSelector((state) => state.cart);

    const tableClickHandler = (table) => {
        dispatch(selectTable(table));
        handleClose();
    };

    useEffect(() => {
        dispatch(setGuestCount(1));
    }, []);

    return (
        <div className={classes.root}>
            <IconBtn
                aria-label="close"
                component="span"
                icon={<CloseIcon />}
                onClick={() => handleClose()}
                className={classes.closeButton}
            />
            <div className={classes.scrollContainer}>
                <div className={classes.detailsContianer}>
                    <div className={classes.infoContainer}>
                        <Typography className={classes.title} variant="h3">
                            Choose Table
                        </Typography>
                        <div className="d-flex justify-content-center mb-3">
                            <TextField
                                className={classes.couponInput}
                                color="primary"
                                label="No. of guest"
                                variant="outlined"
                                type="number"
                                value={guestCount}
                                onChange={(e) => {
                                    dispatch(setGuestCount(e.target.value))
                                }}
                            />
                        </div>
                        {tables && tables.length !== 0 ? (
                            <div className={`row ${classes.tableCont}`}>
                                {tables?.map((table, i) => (
                                    <div key={i} className='col-lg-3 mb-2 col-xs-12'>
                                        <Btn className={`${classes.btn} w-100`} text={table.name || 'Unknown'} onClick={() => { tableClickHandler(table) }} />
                                    </div>
                                ))}
                            </div>
                            ) : (
                            <Typography
                                className={`text-center pt-2 ${classes.description}`}
                                variant="h3"
                            >
                                No coupons available
                            </Typography>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableListing;