import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    footerSection: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        padding: '50px 0'
    },
    footerMenu: {
        '& ul': {
            margin: '0',
            padding: '0'
        },
        '& li': {
            display: 'inline-block',
            marginLeft: '20px',
            [theme.breakpoints.down('xs')]: {
                display: 'block',
                marginLeft: '0'
            },
        },
    },
    navLabel: {
        ...theme.typography.poppins,
        color: theme.palette.common.white,
        '& span': {
            fontWeight: '600'
        },
        '&:hover': {
            color: '#ffffffa8',
            TextDecoration: 'none !important'
        }
    }
}));

export default useStyles;
