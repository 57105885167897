import { makeStyles } from "@material-ui/core/styles";
import { calculateFontSize } from "../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // height: "100%",
    width: "100%",
    paddingTop: "22px",
  },
  title: {
    // marginBottom: theme.spacing(4),
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3.5),
    padding: "50px",
    paddingTop: "70px",
  },
  splTitle: {
    color: "black",
  },
  googleButton: {
    backgroundColor: "#4285F4", // Google Blue
    height: "75px", // Adjust the height as needed
    fontSize: calculateFontSize(18, theme.palette.common.fontVariation),
    width: "300px",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "#357ae8",
    },
  },
  appleButton: {
    backgroundColor: "black",
    color: "white",
    fontSize: calculateFontSize(18, theme.palette.common.fontVariation),
    padding: 0,
    height: "75px", // Adjust the height as needed
    width: "300px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "#333",
    },
  },
  closeButton: {
    position: "absolute",
    top: "22px",
    right: theme.spacing(1),
  },
  googleIcon: {
    width: "25px",
    height: "25px",
    marginRight: "16px",
  },
  appleIcon: {
    width: "25px",
    height: "25px",
    marginRight: "16px",
  },
  logoImage: {
    width: "28px",
    height: "28px",
    marginRight: "16px",
    marginBottom: "3px",
  },
  appleLogoImg: {
    width: "30px",
    height: "30px",
    marginRight: "16px",
    marginBottom: "7px",
  },
}));

const useStylesMobile = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    background: "#fff",
    borderTopLeftRadius: theme?.palette?.modal?.modalBorderRadius || "30px",
    borderTopRightRadius: theme?.palette?.modal?.modalBorderRadius || "30px",
    height: "60%",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // height: "100%",
    width: "100%",
    paddingTop: "10px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    padding: "50px",
  },
  googleButton: {
    backgroundColor: "#4285F4", // Google Blue
    height: "75px", // Adjust the height as needed
    fontSize: calculateFontSize(18, theme.palette.common.fontVariation),
    width: "100%",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "#357ae8",
    },
  },
  appleButton: {
    backgroundColor: "black",
    color: "white",
    fontSize: calculateFontSize(18, theme.palette.common.fontVariation),
    padding: 0,
    height: "75px", // Adjust the height as needed
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "#333",
    },
  },
}));

export { useStyles, useStylesMobile };
