import React, { useEffect, useRef, useState } from "react";
import { Route } from "react-router-dom";

// Common InnerLayout
import InnerLayout from "../../Layouts/InnerLayout";
// Components
import Navbar from "./../../../components/Navbar/Navbar";
// Styles
import useStyles from "./CommonRoute.style.js";
import { useSelector } from "react-redux";
import BasicLoader from "../../../components/Loader/BasicLoader.js";

const CommonRoute = ({ component: Component, mainHeader, ...rest }) => {
  const classes = useStyles();
  const scrollRef1 = useRef(null);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const { isHotelFetchCompleted } = useSelector((state) => state.hotel);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     console.log("Scrolled!");
  //     const currentScrollPos = scrollRef1.current.scrollTop;
  //     console.log("curresnt scroll possssssss", currentScrollPos);
  //     const scrollingUp = currentScrollPos > prevScrollPos;
  //     console.log("scrolling uppppppppppp", scrollingUp);
  //     // setIsScrollingUp(scrollingUp);
  //     setPrevScrollPos(currentScrollPos);
  //   };

  //   const scrollElement = scrollRef1.current;
  //   console.log("scrolll elemtnttt", scrollElement);
  //   if (scrollElement) {
  //     console.log("working 111111111111");
  //     scrollElement.addEventListener("scroll", handleScroll);
  //   }

  //   return () => {
  //     if (scrollElement) {
  //       console.log("working 2222222222");

  //       scrollElement.removeEventListener("scroll", handleScroll);
  //     }
  //   };
  // }, [prevScrollPos]);

  const handleScroll = (scrollingUp) => {
    setIsScrollingUp(scrollingUp);
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentScrollPos = window.scrollY;
  //     console.log("Scroll event detected");
  //     console.log("Current scroll position:", currentScrollPos);
  //     console.log("Previous scroll position:", prevScrollPos);

  //     const scrollingUp = currentScrollPos < prevScrollPos;
  //     setIsScrollingUp(scrollingUp);
  //     setPrevScrollPos(currentScrollPos);
  //     console.log("Scrolling up:", scrollingUp);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [prevScrollPos]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsScrollingUp(true);
  //   }, 10000);
  // }, []);

  return (
    <div ref={scrollRef1} className={classes.appContianer}>
      {isHotelFetchCompleted && (
        <Navbar mainHeader={mainHeader} isScrollingUp={isScrollingUp} />
      )}
      <Route
        {...rest}
        render={(props) => (
          <InnerLayout>
            <Component {...props} onScroll={handleScroll} />
          </InnerLayout>
        )}
      />
    </div>
  );
};

export default CommonRoute;
