import * as types from "./ModalTypes"

const INITIAL_STATE = {
    selectedProduct: null,
    selectedGroup: null,
    min_count: null,
    product_arry: []
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SELECTED_PRODUCT:
            return {
                ...state,
                selectedProduct: action.payload,
            }
        case types.SELECTED_GROUP:
            return {
                ...state,
                selectedGroup: action.payload,
            }
        case types.MIN_COUNT:
            return {
                ...state,
                min_count: action.payload,
            }
        case types.REMOVE_SELECTED:
            return {
                ...state,
                product_arry: state.product_arry.filter((prod) => prod?.itemId !== action.payload)
            }
        case types.PRODUCT_ARRY:
            const newProduct = action.payload;
            if (state.product_arry.some(product => product.itemId === newProduct.itemId)) {
                return state; // Product already exists, return the current state
            } else {
                return {
                    ...state,
                    product_arry: [...state.product_arry, newProduct]
                };
            }
        default:
            return state
    }
}

export default reducer