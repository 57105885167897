import React, { useEffect, useMemo, useState } from "react";
// import "./Loader.scss";
import Lottie from "react-lottie";
import foodOrderDark from "../../assets/gifs/foodOrderDark.json";
import successDark from "../../assets/gifs/successDark.json";

// Material
import Typography from "@material-ui/core/Typography";
// Components
import ImageView from "./../ImageView/ImageView";
// Custom Hooks
import useScrollBlock from "./../../CustomHooks/useScrollBlock";
// Gifs
import * as foodLoaderGif from "../../assets/gifs/food-loader.gif";
import * as tableOrderGif from "../../assets/gifs/table-order.gif";
import * as successGif from "../../assets/gifs/success.gif";
// import * as mailConfirmGif from "../../assets/gifs/mail-confirm.gif";
import * as mailConfirm from "../../assets/images/email.png";
import * as noFood from "../../assets/images/no-food.png";
import paymentFailedGif from "../../assets/gifs/paymentFailedGif.json";

// Styles
import useStyles from "./Loader.style.js";
import { useSelector } from "react-redux";
import ProgressBar from "../ProgressBar/ProgressBar.js";

const Loader = ({
  success = false,
  text = null,
  secondaryText = null,
  gifType = "success",
  action = null,
  statusText = "",
  refId,
  isPaymentFailed = false,
  payFailedBackendMsg,
}) => {
  const classes = useStyles();
  const [blockScroll, allowScroll] = useScrollBlock();
  const hotel = useSelector((state) => state.hotel);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isProgressBar, setIsProgressBar] = useState(false);
  // console.log("hotel", hotel)
  useEffect(() => {
    if (hotel) {
      if (hotel?.hotel?.dark_theme_active === 1) {
        setIsDarkMode(true);
      } else {
        setIsDarkMode(false);
      }
    }
  }, [hotel]);

  useEffect(() => {
    const intervalDuration = 30000; // 30 seconds
    let currentProgress = 0;

    // Simulate progress update (e.g., fetch data, load resources)
    const progressInterval = setInterval(() => {
      currentProgress += 1;
      setProgress(currentProgress);

      if (currentProgress >= 100) {
        clearInterval(progressInterval);
      }
    }, intervalDuration / 100);

    return () => {
      clearInterval(progressInterval);
    };
  }, []);

  const getGif = useMemo(() => {
    if (success) {
      if (gifType === "success") {
        return successGif.default;
      } else if (gifType === "error") {
        return noFood.default;
      } else if (gifType === "table") {
        return tableOrderGif.default;
      }
      return mailConfirm.default;
    }

    // return foodLoaderGif.default;
    setIsProgressBar(true);
  }, [gifType, success, isDarkMode, progress]);

  // console.log("successsssss", success);

  const getLoaderText = useMemo(() => {
    let currentText = text;
    if (!success && !text) {
      currentText = "Please wait...";
    }
    return currentText;
  }, [success, text]);
  useEffect(() => {
    blockScroll();
    return () => {
      allowScroll();
    };
  }, [blockScroll, allowScroll]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: foodOrderDark,
  };

  const successOption = {
    loop: true,
    autoplay: true,
    animationData: successDark,
  };

  const paymentFailed = {
    loop: true,
    autoplay: true,
    animationData: paymentFailedGif,
  };
  return (
    <div className={classes.root}>
      {isDarkMode && !success && !isPaymentFailed && isProgressBar ? (
        <div className={classes.imageContainer}>
          {/* <Lottie options={defaultOptions} /> */}
          {/* <div className={classes.imageContainer}>
            <ProgressBar value={progress} />
          </div> */}
          <ProgressBar value={progress} />
        </div>
      ) : isDarkMode && success && !isPaymentFailed ? (
        <div className={classes.imageContainer}>
          <Lottie options={successOption} />
        </div>
      ) : isPaymentFailed ? (
        <div className={classes.imageContainer}>
          <Lottie options={paymentFailed} />
        </div>
      ) : isProgressBar ? (
        <div className={classes.imageContainer}>
          <ProgressBar value={progress} />
        </div>
      ) : (
        <ImageView image={getGif} className={classes.imageContainer} />
      )}
      {statusText && (
        <Typography className={classes.text} variant="caption">
          {statusText}
        </Typography>
      )}
      {payFailedBackendMsg && (
        <Typography className={classes.text} variant="caption">
          {`Status : ${payFailedBackendMsg}`}
        </Typography>
      )}
      {getLoaderText && (
        <Typography className={classes.text} variant="caption">
          {getLoaderText}
        </Typography>
      )}
      {secondaryText && (
        <Typography className={classes.secondaryText} variant="caption">
          {secondaryText}
        </Typography>
      )}
      {refId && (
        <Typography
          className={classes.refIdText}
        >{`Ref Id : ${refId}`}</Typography>
      )}
      {action ? action : null}
    </div>
  );
};

export default Loader;
