import * as types from "./VoucherTypes";

export const checkVoucher = (voucherCode, resId) => ({
  type: types.CHECK_VOUCHER_REQUEST,
  payload: { voucherCode, resId },
});

export const checkVoucherSuccess = (response) => ({
  type: types.CHECK_VOUCHER_SUCCESS,
  payload: response,
});

export const checkVoucherFailure = (error) => ({
  type: types.CHECK_VOUCHER_FAILURE,
  payload: error,
});

export const setVoucherLoading = () => ({
  type: types.SET_VOUCHER_LOADING,
});

export const clearVoucherLoading = () => ({
  type: types.CLEAR_VOUCHER_LOADING,
});
