import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  progressBar: {
    position: "relative",
    width: "100%",
    height: "20px",
    backgroundColor: "#e0e0e0",
    borderRadius: "4px",
    overflow: "hidden",
  },
  progressBarFill: {
    height: "100%",
    backgroundColor: "#4caf50", // Change this color as needed
    transition: "width 0.3s ease-in-out",
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff", // Text color
  },
  progressBarText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1, // Ensure the text is above the fill
  },
}));
export default useStyles;
