import React from "react";
import {
  makeStyles,
  Modal,
  Paper,
  Typography,
  Button,
  Slide,
} from "@material-ui/core";
import { useEffect } from "react";
import { useRef } from "react";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const CategoryModal = ({ open, handleClose, triggerElement }) => {
  const classes = useStyles();
  const modalRef = useRef(null);

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <Slide direction="down" in={open} container={triggerElement}>
        <Paper ref={modalRef} className={classes.paper}>
          <Typography variant="h6">Modal Content</Typography>
          <Button onClick={handleClose}>Close</Button>
        </Paper>
      </Slide>
    </Modal>
  );
};

export default CategoryModal;
