import React, { useState } from "react";
// Material
// import Popover from "@material-ui/core/Popover";
import Popper from "@material-ui/core/Popper";
// import Typography from "@material-ui/core/Typography";
// Controls
import Btn from "../../Controls/Button/Button";
// Styles
// import useStyles from "./PopoverButton.style.js";

export default function PopoverButton({
  text,
  buttonClass,
  buttonStartIcon,
  children,
  disable = false,
  buttonOnClick = () => {},
}) {
  // const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    if (disable) {
      return true;
    }
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div onMouseLeave={handlePopoverClose}>
      <Btn
        text={text}
        startIcon={buttonStartIcon}
        onMouseEnter={handlePopoverOpen}
        className={buttonClass}
        onClick={buttonOnClick}
      />
      <Popper
        id={"popper"}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        popperOptions={{ OverflowEvent: false }}
        style={{ zIndex: 2000 }}
      >
        {children}
      </Popper>
    </div>
  );
}
