import { makeStyles } from "@material-ui/core/styles";
import {
  calculateFontSize,
  setFontColorOnBg,
} from "../../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  inputUnderline: {
    borderBottom: "none",
    "&::before": {
      borderBottom: "none",
      "&::focus": {
        borderBottom: "none",
      },
    },
  },
  input: {
    width: "100%",
    padding: "0 6%",
    borderRadius: "10px",
    height: "48px",
    backgroundColor: theme.palette.common.white,
    color: "black",
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    lineHeight: "21px",
  },
  inputField: {
    color: "black",
  },
  loginBtn: {
    height: 50,
    fontSize: calculateFontSize(16, theme.palette.common.fontVariation),
    border: "1px solid",
    borderRadius: theme.shape.borderRadius,
    textTransform: "none",
    textDecoration: " none",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
    },
  },
  otpInput: {
    width: "40px !important",
    height: "40px !important",
    border: "1px solid #686b78",
    borderRadius: 6,
    margin: 5,
    "@media(max-width: 780px)": {
      width: "30px !important",
      height: "30px !important",
    },
    "&:focus": {
      borderColor: theme.palette.primary.main, // Focus state color
      outline: "none", // Removing default focus outline
    },
  },
  otpInputBoxDiv: {
    width: "100% !important",
    height: "40px !important",
    border: "1px solid #686b78",
    borderRadius: 6,
    margin: 5,
    "@media(max-width: 780px)": {
      width: "100% !important",
      height: "30px !important",
    },
    "&:focus": {
      borderColor: theme.palette.primary.main, // Focus state color
      outline: "none", // Removing default focus outline
    },
  },
  textCon: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "16px",
  },
  text: {
    cursor: "pointer",
    fontWeight: "bold",
    paddingLeft: "8px",
    // display: "flex",
    // alignItems: "center",
  },
  veryfiyBtnDiv: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  // otpInput: {

  // },
  otpInputBox: {
    width: "30px !important",
    height: "40px",
    margin: "0 5px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "2px solid #ccc",
    textAlign: "center",
  },

  verifyBtn: {
    backgroundColor: theme?.palette?.button?.buttonColor || "transparent",

    color: setFontColorOnBg(
      theme?.palette?.button?.buttonColor || theme.palette.button.main
    ),
    borderRadius: theme?.palette?.button?.borderRadius || "12px",
  },
  verifiedText: {
    color: "blue",
    width: "100%",
    textAlign: "center",
  },
  prefixStyle: {
    color:
      theme?.palette?.card?.cardFontColor || theme.palette.common.fontTitle,
  },
}));

export default useStyles;
