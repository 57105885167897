import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  orderTypeModalContainer: {
    width: "350px",
    height: "auto",
    [theme.breakpoints.up("sm")]: {
      maxHeight: "536px",
      maxWidth: "830px",
      width: "830px",
    },
  },
  orderTypeModal: {
    padding: theme.spacing(2.5),
  },
  modalTitleSection: {
    marginBottom: theme.spacing(1.6),
  },
  modalTitle: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    color: theme.palette.common.themeFontColor,
  },
}));

export default useStyles;
