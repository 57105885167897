import * as types from "./ProfileTypes";

export const getCustomerOrderRequest = (payload) => ({
  type: types.GET_CUSTOMER_ORDER_REQUEST,
  payload,
});

export const getCustomerOrderReceive = (payload) => ({
  type: types.CUSTOMER_ORDER_RECEIVE,
  payload,
});

export const getCustomerOrderInProgress = (payload) => ({
  type: types.IS_CUSTOMER_ORDER_INPROGRESS,
  payload,
});

export const getCustomerOrderByPageNo = (payload) => ({
  type: types.GET_CUSTOMER_HISTORY_BY_PAGE_NO,
  payload,
});

export const getCustomerWalletOrders = (payload) => ({
  type: types.GET_CUSTOMER_WALLET_REQUEST,
  payload,
});

export const setCustomerWalletOrders = (payload) => ({
  type: types.SET_CUSTOMER_WALLET_ORDERS,
  payload,
});

export const setRedumptionRatio = (payload) => ({
  type: types.SET_REDUMPTION_RATIO,
  payload,
});

export const setUsedWalletPoint = (payload) => ({
  type: types.SET_USED_WALLET_POINT,
  payload,
});

export const setPriceWalletPoint = (payload) => ({
  type: types.SET_PRICE_WALLET_POINT,
  payload,
});

export const clearUsedWalletPoints = () => ({
  type: types.CLEAR_USED_WALLET_POINTS,
});

export const updateUsedWalletPoints = (payload) => ({
  type: types.UPDATE_USED_WALLET_POINTS,
  payload,
});

export const addUsedWalletPoints = (payload) => ({
  type: types.ADD_USED_WALLET_POINTS,
  payload,
});

export const resetWallet = () => ({
  type: types.RESET_WALLET,
});

export const setSelectedWallets = (payload) => ({
  type: types.SET_SELECTED_WALLETS,
  payload: payload,
});

export const resetSelectedWallets = (payload) => ({
  type: types.RESET_SELECTED_WALLETS,
});

export const triggerEmailVerification = (payload) => ({
  type: types.TRIGGER_EMAIL_VERIFICATION,
  payload: payload,
});

export const getTriggeredEmailResponse = (payload) => ({
  type: types.GET_TRIGGERED_EMAIL_RESPONSE,
  payload: payload,
});

export const verifyEmailOtp = (payload) => ({
  type: types.VERIFY_EMAIL_OTP,
  payload: payload,
});

export const verifiedEmailResponse = (payload) => ({
  type: types.VERIFIED_EMAIL_RESPONSE,
  payload: payload,
});

export const isEmailVerified = (payload) => ({
  type: types.IS_EMAIL_VERIFIED,
  payload: payload,
});

export const getOrderDetailRequest = (payload) => ({
  type: types.GET_ORDER_DETAIL_REQUEST,
  payload: payload,
});

export const setOrderDetailSuccess = (payload) => ({
  type: types.SET_ORDER_DETAIL_SUCCESS,
  payload: payload,
});

export const isOrderDetailLoading = (payload) => ({
  type: types.IS_ORDER_DETAIL_LOADING,
  payload: payload,
});

export const setDirectLogin = (payload) => ({
  type: types.SET_DIRECT_LOGIN,
  payload: payload,
});

export const deleteAccount = (payload) => ({
  type: types.DELETE_ACCOUNT,
  payload: payload,
});
export const isDeleteAccountLoading = (payload) => ({
  type: types.IS_DELETE_ACCOUNT_LOADING,
  payload: payload,
});

export const setAccountDeleteStatus = (payload) => ({
  type: types.SET_ACCOUNT_DELETE_STATUS,
  payload: payload,
});
