import moment from "moment";
// import { useSelector } from "react-redux";
import { ORDER_TYPES } from "./Constants";
import {
  convertToMoment,
  getTimeIntervals,
  getTodayTimeIntervals,
} from "./TimeUtils";
import { useDispatch } from "react-redux";
import { setIsTodayHoliday } from "../store/Restaurant/RestaurantActions";

export const getViewPortHeight = () => {
  const { innerHeight: height } = window;
  return height;
};

export const setLocalStorageItem = (name, val) => {
  localStorage.setItem(name, val);
};

export const getLocalStorageItem = (name) => {
  return localStorage.getItem(name);
};

export const deleteLocalStorageItem = (name) => {
  localStorage.removeItem(name);
};

export const clearEntireLocalStorage = () => {
  localStorage.clear();
};

export const modifySlotsByType = (
  hotel,
  cart,
  formValues,
  res,
  deliveryTime
) => {
  if (!res.length || !deliveryTime) {
    return [];
  }

  const currentSelectedTime = moment(deliveryTime);
  const result = [];
  const dayInfo = hotel.workingInfo.find((elem) => elem.day === moment().day());
  const hours = dayInfo?.hotelDayInfo.find(
    (elem) => elem.type === formValues.orderType
  );
  for (let i = 0; i < res.length; i++) {
    if (moment().isSame(currentSelectedTime, "date") && i === 0) {
      let typeOfOrder = "SCHEDULED";
      let properStartTime;
      hours?.running_hours.forEach((h) => {
        const selectedDate = moment().startOf("D").format("YYYY-MM-DD");
        const todayShopStartValue = moment
          .utc(`${selectedDate} ${h.start}`)
          .local()
          .format("YYYY-MM-DD HH:mm:ss");
        const acquiredDate = moment(
          `${selectedDate} ${moment(todayShopStartValue).format("HH:mm")}`
        );
        properStartTime = acquiredDate;
        if (
          properStartTime &&
          moment().isAfter(properStartTime) &&
          hotel?.openingAvailable
          // if (
          //   properStartTime &&
          //   moment().isAfter(properStartTime)
        ) {
          if (
            formValues?.orderType === ORDER_TYPES.DELIVERY &&
            hotel?.deliveryAvailable
          ) {
            typeOfOrder = "ASAP";
          } else if (
            formValues?.orderType === ORDER_TYPES.PICKUP &&
            hotel?.pickupAvailable
          ) {
            typeOfOrder = "ASAP";
          }
        }
      });

      //show buffer value instead of ASAP
      const type = cart?.checkoutDetails.orderType;
      let buffer;
      if (type === ORDER_TYPES.DELIVERY && hotel?.deliveryAvailable) {
        buffer = hotel.delivery_buffer_time;
      } else if (type === ORDER_TYPES.PICKUP && hotel?.pickupAvailable) {
        buffer = hotel.pickup_buffer_time;
      }

      if (!buffer) {
        buffer = 10;
      }

      const showASAP = buffer + "-" + (buffer + 5) + " Mins";

      const item = {
        typeOfOrder,
        value: res[i],
        // label: typeOfOrder === "ASAP" ? "ASAP" : res[i],
        label: typeOfOrder === "ASAP" ? showASAP : res[i],
      };
      result.push(item);
    } else {
      const item = {
        typeOfOrder: "SCHEDULED",
        value: res[i],
        label: res[i],
      };
      result.push(item);
    }
  }
  return result;
};

export const generateDateSlots = (hotel, orderType) => {
  // console.log("workingggggggggg", hotel);
  const dates = [];
  let isToday = true;
  let pre_min_day = hotel?.pre_min_day === (null || 0) ? 1 : hotel?.pre_min_day;
  // if (hotel?.is_preorder === 1) {
  //   if (hotel?.pre_min_day != null) {
  // console.log("pre min day", pre_min_day)

  for (let i = 0; i < pre_min_day; i++) {
    const validDay = hotel?.workingInfo.filter(
      (d) => d.day === moment().add(i, "day").day()
    );
    // console.log("valid dayyyyyyy", validDay)
    const validDayInfo = validDay[0]?.hotelDayInfo?.filter(
      (d) => d.type === orderType
    );
    let isTodayOpen = true;
    let isHoliday = false;
    // console.log(" valid day infooo", validDayInfo)
    if (
      isToday &&
      validDayInfo &&
      moment().add(i, "day").day() === moment().day()
    ) {
      isToday = false;
      for (let i = 0; i < validDayInfo[0]?.running_hours?.length; i++) {
        const start = convertToMoment(validDayInfo[0]?.running_hours[i]?.start);
        const stop = convertToMoment(validDayInfo[0]?.running_hours[i]?.stop);
        // console.log("starttttttttttt", start)
        // console.log("stoppppppppppp", stop)
        // console.log("hoursssssss", validDayInfo[0]?.running_hours)
        if (!moment().isBetween(start, stop) && moment().isAfter(stop)) {
          isTodayOpen = false;
        } else {
          isTodayOpen = true;
          break;
        }
      }
    }

    if (hotel?.shop_hoilidays?.length > 0) {
      for (let h = 0; h < hotel?.shop_hoilidays?.length; h++) {
        if (
          moment().add(i, "day").format("YYYY-MM-DD") ===
          hotel.shop_hoilidays[h].date
        ) {
          isHoliday = true;
          // dispatch(setIsTodayHoliday(true));
          break;
        }
      }
    }

    if (
      !validDayInfo ||
      validDayInfo.length === 0 ||
      !isTodayOpen ||
      isHoliday
    ) {
      continue;
    }

    let label = moment().add(i, "day").format("dddd");
    if (moment().add(i, "day").format("LL") === moment().format("LL")) {
      label = "Today";
    } else if (
      moment().add(i, "day").format("LL") ===
      moment().add(1, "day").format("LL")
    ) {
      label = "Tomorrow";
    }

    const date = {
      date: moment().add(i, "day").format("LL"),
      label,
    };
    dates.push(date);
  }
  //   }

  // }
  // console.log("datesssssssssss", dates);
  return dates;
};

export const getTimeIntervalSlots = (hotel, cart, formValues, deliveryTime) => {
  if (!hotel || !deliveryTime) {
    return [];
  }

  const bufTime =
    formValues.orderType === "DELIVERY"
      ? hotel.delivery_buffer_time
      : hotel.pickup_buffer_time;
  // console.log("hotel infoooo firstttttt", hotel.workingInfo)
  const dayInfo = hotel.workingInfo.find(
    (elem) => elem.day === moment(deliveryTime).day()
  );
  // console.log("hotelll", hotel)
  // console.log("day infoooo", dayInfo)
  // console.log("hotel day infoooo", dayInfo?.hotelDayInfo)
  if (dayInfo && dayInfo.hotelDayInfo) {
    const hours = dayInfo.hotelDayInfo.find(
      (elem) => elem.type === formValues.orderType
    );
    const data = hours?.running_hours.map((elem) => {
      // const options = getTodayTimeIntervals(elem.start, elem.stop, 15, bufTime);
      const options = getTimeIntervals(elem.start, elem.stop, 15, bufTime);

      return options;
    });

    const sorted = data.sort((a, b) => {
      const timeA = moment(a, "HH:mm");
      const timeB = moment(b, "HH:mm");

      return timeA.isBefore(timeB) ? -1 : 1;
    });
    const res = sorted ? sorted.reduce((prev, curr) => [...curr, ...prev]) : [];
    // const res = data ? data : [];

    const result = modifySlotsByType(
      hotel,
      cart,
      formValues,
      res,
      deliveryTime
    );
    return result;
  } else {
    return [];
  }
};
