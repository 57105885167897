import { call, put, select, takeLatest } from "redux-saga/effects";

import { createUser, EmailLogin, EmailOtpVerify, loginUser } from "./UserApis";
import * as ACTIONS from "./UserAction";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from "../../utils/Shared";
import * as TYPES from "./UserTypes";
import { useSelector } from "react-redux";
import History from "../../routes/History";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { firebaseAuth } from "../../firebase";

// Replace with your sagas
export function* sagasCreateUser({ payload }) {
  try {
    const response = yield call(createUser, payload);
    yield put(ACTIONS.getUser({ ...response }));

    if (response) {
      dispatchSnackbarSuccess("User details updated");
    }
  } catch (err) {
    // dispatchSnackbarError(err.response.data);
    dispatchSnackbarError("Something went wrong, please try again later");
  }
}

export function* sagasLoginUser({ payload }) {
  try {
    const response = yield call(loginUser, payload);
    if (response) {
      yield put(ACTIONS.getUser(response));
      // console.log("response", response);
      dispatchSnackbarSuccess("Login Successfully");
      // payload.cbl();

      if (payload?.isDirectLogin) {
        History.push(`/${payload?.hotelKey}/profile`);
      }
    }
    // } else {
    //   payload.cbs();
    //   // dispatchSnackbarError("Account with this phone number does not exists!");
    // }
  } catch (err) {
    console.log("error ", err);
    // dispatchSnackbarError(err.response.data);
    dispatchSnackbarError("Something went wrong, please try again later");
  }
}

export function* sagasEmailLogin({ payload }) {
  // console.log("payload in sagaaaaa", payload);
  try {
    if (payload?.setLoading) payload.setLoading(true);
    const response = yield call(EmailLogin, payload);
    // console.log("responseeeeeeee", response);

    if (response?.status === 200) {
      // Call the callback to change the form type to "otp"
      if (payload?.onSuccess) {
        payload.onSuccess();
      }
      // Additional logic if needed, like getting user info, etc.
    }
  } catch (err) {
    console.log("error ", err);
    dispatchSnackbarError("Something went wrong, please try again later");
  } finally {
    // Stop loading
    if (payload?.setLoading) payload.setLoading(false);
  }
}

export function* sagasEmailOtpRequest({ payload }) {
  // console.log("payload in saga", payload);

  try {
    if (payload?.setLoading) payload.setLoading(true);
    const response = yield call(EmailOtpVerify, payload); // Call your API for OTP verification
    // console.log("response", response);

    if (response?.status === 200 || response?.status === 201) {
      const { customToken, customer } = response?.data;
      // console.log("custom tokennnnn", customToken);

      if (customToken) {
        try {
          // Get Firebase Auth instance
          const auth = getAuth();

          // Wait for the Firebase authentication to resolve
          const userCredential = yield call(
            signInWithCustomToken,
            auth,
            customToken
          );

          // Firebase sign-in success
          const user = userCredential.user;
          // console.log("Firebase sign-in success:", user);

          // Check if customer exists
          if (customer) {
            // console.log("customer found");
            // If customer exists, change formType to null (successful login)
            yield put(ACTIONS.getUser(customer)); // Dispatch user action if needed
            dispatchSnackbarSuccess("Login Successfully");

            if (payload?.changeFormType) {
              payload.changeFormType(null); // Set formType to null on success
            }
          } else {
            // console.log("customer not found", user);

            // Set customer UID in the login form values (callback from the component)
            if (payload?.setCustomerUid) {
              payload.setCustomerUid(user?.uid); // Send UID back to the component
            }

            // If no customer, change formType to signup (user needs to complete signup)
            if (payload?.changeFormType) {
              payload.changeFormType("signup");
            }
          }
        } catch (firebaseError) {
          console.error("Firebase sign-in error:", firebaseError);
          dispatchSnackbarError(
            "Failed to sign in with Firebase. Please try again."
          );
        }
      } else {
        // console.log("responseeeeee in sagaaaaaa", response);
        if (payload?.changeFormType) {
          payload.changeFormType(null); // Set formType to null on success
        }
        // if (payload?.setLoading) payload.setLoading(false);
        dispatchSnackbarError("No customer Token,OTP verification failed ");
      }
    } else {
      dispatchSnackbarError(response?.data?.error || "OTP verification failed");
    }
  } catch (err) {
    console.log("error", err);
    dispatchSnackbarError("Something went wrong, please try again later");
  } finally {
    if (payload?.setLoading) payload.setLoading(false);
  }
}

export function* CreateUserSaga() {
  yield takeLatest(TYPES.CREATE_USER_REQUEST, sagasCreateUser);
}

export function* LoginUserSaga() {
  yield takeLatest(TYPES.LOGIN_USER_REQUEST, sagasLoginUser);
}

export function* EmailLoginSaga() {
  yield takeLatest(TYPES.EMAIL_LOGIN_REQUEST, sagasEmailLogin);
}

export function* EmailOtpRequestSaga() {
  yield takeLatest(TYPES.EMAIL_OTP_REQUEST, sagasEmailOtpRequest);
}
