// Constants
import { useDispatch } from "react-redux";
import { MESSAGES } from "../../utils/Constants";
import * as ACTIONS from "./LocationActions";
import { call, put, takeLatest, select } from "redux-saga/effects";

export const calculateDistanceDuration = async (
  hotel,
  distanationLocation,
  isCheck,
  total
) => {
  const sortDeliveryZones = (deliveryzones) => {
    return deliveryzones?.sort((a, b) => {
      if (a.zone_area !== b?.zone_area) {
        return a.zone_area - b?.zone_area;
      } else {
        return b.minimum_amount - a?.minimum_amount;
      }
    });
  };

  // const findBestZone = (sortedZones, distance, total) => {
  //   let matchedZone = null; // Initialize matchedZone variable
  //   let freeZone = null; // Initialize freeZone variable
  //   let difference = 0;
  //   let message = ''; // Initialize message variable
  //   let minOrderValue = Infinity; // Track the minimum order value
  //   let distanceNotMet = true;
  //   for (let i = 0; i < sortedZones?.length; i++) {
  //     const zone = sortedZones[i];

  //     if (zone?.minimum_amount < minOrderValue) {
  //       minOrderValue = zone?.minimum_amount;
  //     }

  //     // Check if the order amount meets the minimum and the zone area is appropriate
  //     if (total >= zone?.minimum_amount && zone.zone_area >= distance) {
  //       matchedZone = matchedZone ? matchedZone : zone; // Assign the first matched zone to matchedZone

  //       // Check if this zone has a lower delivery fee than the current matched zone
  //       if (matchedZone && zone.delivery_fee < matchedZone.delivery_fee) {
  //         matchedZone = zone;
  //       }
  //     }
  //     if (
  //       zone.zone_area >= distance &&
  //       total >= zone?.minimum_amount &&
  //       zone?.delivery_fee === 0
  //     ) {
  //       freeZone = freeZone ? freeZone : zone; // Assign the first free zone to freeZone
  //     }
  //   }

  //   if (matchedZone && freeZone && matchedZone?.id !== freeZone?.id) {
  //     // Calculate the difference between the total and the minimum_amount
  //     difference = (freeZone.minimum_amount - total).toFixed(2);
  //     // Dispatch an action to store the difference in the Redux store
  //   }

  //   // Sort matched zones by delivery fee in ascending order
  //   sortedZones
  //     .filter(
  //       (zone) => total >= zone.minimum_amount && zone.zone_area >= distance
  //     )
  //     .forEach((zone) => {
  //       matchedZone =
  //         matchedZone && matchedZone.delivery_fee <= zone.delivery_fee
  //           ? matchedZone
  //           : zone;
  //     });

  //     if (!matchedZone) {
  //       if (distanceNotMet) {
  //         message = 'Invalid distance';
  //       } else {
  //         message = `Minimum order value is ${minOrderValue}`;
  //       }
  //     } else {
  //       message = 'Matched zone found';
  //     }

  //   return { matchedZone, freeZone, difference };
  // };
  const findBestZone = (sortedZones, distance, total) => {
    let matchedZone = null; // Initialize matchedZone variable
    let freeZone = null;
    let freedZoneForDiffer = null;
    let difference = 0;
    let message = ""; // Initialize message variable
    let minOrderValue = Infinity; // Track the minimum order value
    let distanceNotMet = true; // Flag to check if any zone met the distance requirement

    for (let i = 0; i < sortedZones?.length; i++) {
      const zone = sortedZones[i];

      // Track the minimum order value
      if (zone?.minimum_amount < minOrderValue) {
        minOrderValue = zone.minimum_amount;
      }

      // Check if the zone area is appropriate
      if (zone.zone_area >= distance) {
        distanceNotMet = false; // At least one zone met the distance requirement

        // Check if the order amount meets the minimum
        if (total >= zone?.minimum_amount) {
          matchedZone = matchedZone ? matchedZone : zone; // Assign the first matched zone to matchedZone

          // Check if this zone has a lower delivery fee than the current matched zone
          if (matchedZone && zone.delivery_fee < matchedZone.delivery_fee) {
            matchedZone = zone;
          }
        }

        if (
          zone?.delivery_fee === 0 &&
          total >= zone?.minimum_amount &&
          zone.zone_area >= distance
        ) {
          freeZone = freeZone ? freeZone : zone;
        }
        if (zone?.delivery_fee === 0 && zone.zone_area >= distance) {
          freedZoneForDiffer = freeZone ? freeZone : zone;
        }
      }
    }

    if (
      matchedZone &&
      freedZoneForDiffer &&
      matchedZone?.id !== freedZoneForDiffer?.id
    ) {
      // Calculate the difference between the total and the minimum_amount
      difference = (freedZoneForDiffer?.minimum_amount - total).toFixed(2);
      // Dispatch an action to store the difference in the Redux store
    }

    // Set the message based on whether a matched zone was found and the reason if not found
    if (!matchedZone) {
      if (distanceNotMet) {
        message = "Invalid distance";
      } else {
        message = `Delivery service is not available for below $${minOrderValue} order`;
      }
    } else {
      message = "Matched zone found";
    }

    return { matchedZone, freeZone, difference, message };
  };

  return new Promise((resolve, reject) => {
    if (!window.google) {
      return reject("Google api is not initialized");
    }
    if (!hotel) {
      return reject("Hotel not found");
    }
    if (!hotel.latitude || !hotel.longitude) {
      return reject("Hotel location not found");
    }
    if (!distanationLocation.latitude || !distanationLocation.longitude) {
      return reject("Destination location not found");
    }
    const mapsApi = window.google.maps;
    const origin = new mapsApi.LatLng(hotel.latitude, hotel.longitude);
    const destination = new mapsApi.LatLng(
      distanationLocation.latitude,
      distanationLocation.longitude
    );
    const service = new mapsApi.DistanceMatrixService();

    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: "DRIVING",
      },
      (response, status) => {
        if (status === "OK") {
          if (!response?.rows?.[0].elements?.[0]) {
            return reject("No response found from google map");
          }

          const { status, distance, duration } = response.rows[0].elements[0];

          if (status === "ZERO_RESULTS") {
            return reject("No response found from google map");
          }
          if (!distance || !duration) {
            return reject("No response found from google map");
          }
          // if (distance) {

          const formattedDistance = Number((distance.value / 1000).toFixed());
          const formattedDuration = duration.text;

          const sortedDeliveryZones = sortDeliveryZones(hotel?.deliveryzones);
          const zone = hotel?.deliveryzones?.find(
            (z) => z.zone_area >= formattedDistance
          );

          const { matchedZone, freeZone, difference, message } = findBestZone(
            sortedDeliveryZones,
            formattedDistance,
            total
          );

          if (!isCheck && !zone) {
            return reject(MESSAGES.INVALID_LOCATION);
          }
          // if ()
          resolve({
            distance: formattedDistance,
            duration: formattedDuration,
            zone: freeZone ? freeZone : matchedZone,
            difference: difference ? difference : null,
            message: message,
          });
          // } else {
          //   reject("Something went wrong while process your location");
          // }
          // resolve({
          //   distance: formattedDistance,
          //   duration: formattedDuration,
          // });
        } else {
          return reject("Something went wrong while process your location");
        }
      }
    );
  });
  // return new Promise((resolve, reject) => {
  //   if (!window.google) {
  //     reject("Google api is not initialized");
  //   } else if (!hotel) {
  //     reject("Hotel not found");
  //   } else if (!hotel.latitude || !hotel.longitude) {
  //     reject("Hotel location not found");
  //   } else if (
  //     !distanationLocation.latitude ||
  //     !distanationLocation.longitude
  //   ) {
  //     reject("Destination location not found");
  //   } else {
  //     const mapsApi = window.google.maps;
  //     const origin = new mapsApi.LatLng(hotel.latitude, hotel.longitude);
  //     const destination = new mapsApi.LatLng(
  //       distanationLocation.latitude,
  //       distanationLocation.longitude
  //     );
  //     const service = new mapsApi.DistanceMatrixService();
  //     service.getDistanceMatrix(
  //       {
  //         origins: [origin],
  //         destinations: [destination],
  //         travelMode: "DRIVING",
  //       },
  //       (response, status) => {
  //         if (status === "OK") {
  //           if (response?.rows?.[0].elements?.[0]) {
  //             reject("No response found from google map");
  //           } else {
  //             const { status, distance, duration } =
  //               response.rows[0].elements[0];

  //             if (status === "ZERO_RESULTS") {
  //               reject("No response found from google map");
  //             } else if (!distance || !duration) {
  //               reject("No response found from google map");
  //             } else {
  //               const formattedDistance = Number(
  //                 (distance.value / 1000).toFixed()
  //               );
  //               const formattedDuration = duration.text;

  //               const zone = hotel.deliveryzones.find(
  //                 (z) => z.zone_area >= formattedDistance
  //               );
  //               if (!zone) {
  //                 reject(MESSAGES.INVALID_LOCATION);
  //               }
  //               resolve({
  //                 distance: formattedDistance,
  //                 duration: formattedDuration,
  //               });
  //             }
  //           }
  //         } else {
  //           reject("Something went wrong while process your location");
  //         }
  //       }
  //     );
  //   }
  // });
};
