import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDelCharge } from "../store/Cart/CartActions";

function useCalculateDistance() {
  const { distance, currentDeliveryZone } = useSelector(
    (state) => state.location
  );
  const dispatch = useDispatch();

  const { hotel } = useSelector((state) => state.hotel);

  const inValidDistance = useMemo(() => {
    try {
      if (!hotel) {
        return true;
      }
      if (distance) {
        const zone = hotel.deliveryzones?.find((z) => z.zone_area >= distance);
        if (!zone) {
          return true;
        }
        return false;
      }
      return true;
    } catch (error) {
      return true;
    }
  }, [hotel, distance]);

  const deliveryCharge = useMemo(() => {
    if (!hotel) {
      return 0;
    }
    // const zone = hotel.deliveryzones?.find((z) => z.zone_area >= distance);
    const zone = currentDeliveryZone;
    if (!zone) {
      return 0;
    }
    dispatch(setDelCharge(zone.delivery_fee));
    return zone.delivery_fee;
  }, [hotel, distance, currentDeliveryZone]);

  return {
    inValidDistance,
    deliveryCharge,
  };
}

export default useCalculateDistance;
