import React from "react";
import { Formik,Field } from "formik";

// Controls
import InputField from "../../Controls/InputField/InputField";
//Icons
// import CheckIcon from "@material-ui/icons/Check";
// import CloseIcon from '@material-ui/icons/Close';
// Styles
// import useStyles from "./OrderSummaryForm.style";

const FormC = ({ initialValues, onChange = () => {}, onSubmit = () => {} }) => {
  // const classes = useStyles();
  const fieldChange = (e) => {
    onChange(e.target.id, e.target.value);
  };
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} onChange={handleChange}>
          {/* <InputField type={'text'} placeholder={'Apply coupon code'} helperText={'Coupon code applied'} endAdornment={<CheckIcon />} rootContainerClass={classes.inputContainer}/> */}
          <Field
            id="extraComments"
            name="extraComments"
            type="text"
            placeholder="Extra comments (optional)"
            value={values.extraComments}
            component={InputField}
            handleBlur={handleBlur}
            handleChange={fieldChange}
            isMultiline={true}
            isError={
              touched.extraComments && errors.extraComments ? true : false
            }
            helperText={
              touched.extraComments && errors.extraComments
                ? errors.extraComments
                : null
            }
          />
          {/* <InputField
            id="extraComments"
            name="extraComments"
            type={"text"}
            placeholder={"Extra comments (optional)"}
            handleBlur={handleBlur}
            handleChange={fieldChange}
            value={values.extraComments}
            isMultiline={true}
          /> */}
        </form>
      )}
    </Formik>
  );
};

export default FormC;
