import { makeStyles } from "@material-ui/core/styles";
import {
  calculateFontSize,
  setFontColorOnBg,
} from "../../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    position: "relative",
    // display: "flex",
    // flexDirection: "column",
    backgroundColor: theme.palette.common.white,
  },
  orderTypeModalContainer: {
    width: "450px",
    height: "auto",
  },
  detailsContianer: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.up("sm")]: {
      overflowY: "auto",
      overflowX: "hidden",
      width: "100%",
    },
  },
  scrollContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    overflowX: "hidden",
    background: theme?.palette?.common?.background,
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      overflowY: "hidden",
    },
  },
  closeButton: {
    // boxSizing: "border-box",
    // zIndex: 100,
    // position: "absolute",
    // padding: theme.spacing(0.4),
    // top: theme.spacing(3.2),
    // right: theme.spacing(3.2),
    // width: 32,
    // height: 32,
    // borderRadius: "50%",
    // color: theme.palette.primary.main,
    // backgroundColor: theme.palette.common.white,
    boxSizing: "border-box",
    zIndex: 100,
    position: "absolute",
    padding: theme.spacing(0.4),
    top: theme.spacing(3.2),
    right: 20,
    width: 93,
    height: 32,
    borderRadius: "10px",
    fontSize: "18px",
    color: setFontColorOnBg(theme?.palette?.button?.buttonColor || "#000000"),
    backgroundColor: theme?.palette?.button?.buttonColor || "black",
  },
  skipBtnDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  skipBtn: {
    // margin: "0 1rem",
    // color: setFontColorOnBg(theme?.palette?.button?.buttonColor || "#000000"),
    // // width: "200px",
    // borderRadius: theme?.palette?.button?.borderRadius || "12px",
    // backgroundColor: theme?.palette?.button?.buttonColor || "black",
    // transition: "background-color 0.3s",
    // border: theme?.palette?.button?.buttonBorder ? "2px solid black" : null,
    // "&:hover": {
    //   backgroundColor: "#000",
    //   color: "white",
    // },
    boxSizing: "border-box",
    zIndex: 100,
    position: "absolute",
    padding: theme.spacing(0.4),
    top: theme.spacing(3.2),
    right: 130,
    width: 93,
    height: 32,
    borderRadius: "10px",
    fontSize: "18px",
    color: setFontColorOnBg(theme?.palette?.button?.buttonColor || "#000000"),
    backgroundColor: theme?.palette?.button?.buttonColor || "black",
  },
  title: {
    // ...theme.typography.rockwell,
    color: theme.palette.common.themeFontColor,
    fontSize: calculateFontSize(20, theme.palette.common.fontVariation),
    fontWeight: 700,
    lineHeight: "23.49px",
    marginBottom: theme.spacing(0.8),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(1.6),
    },
  },
  otherCouponText: {
    fontSize: calculateFontSize(16, theme.palette.common.fontVariation),
    fontWeight: 600,
    lineHeight: "24px",
    // color: theme.palette.common.normalTitle,
    color: theme.palette.common.themeFontColor,
    marginBottom: theme.spacing(0.8),

    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(0.5),
    },
  },
  subTitle: {
    fontSize: calculateFontSize(16, theme.palette.common.fontVariation),
    fontWeight: 600,
    lineHeight: "24px",
    // color: theme.palette.common.normalTitle,
    color: theme.palette.card.cardFontColor,
    marginBottom: theme.spacing(0.8),

    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(0.5),
    },
  },
  noCouponText: {
    fontSize: calculateFontSize(12, theme.palette.common.fontVariation),
    fontWeight: 500,
    lineHeight: "24px",
    // color: theme.palette.common.subTitle,
    color: theme.palette.common.themeFontColor,
  },
  description: {
    fontSize: calculateFontSize(12, theme.palette.common.fontVariation),
    fontWeight: 500,
    lineHeight: "24px",
    // color: theme.palette.common.subTitle,
    color: theme.palette.card.cardFontColor,
    // marginBottom: theme.spacing(0.8),

    // [theme.breakpoints.up("sm")]: {
    //   marginBottom: theme.spacing(1.6),
    // },
  },
  listCont: {
    padding: "16px",
    backgroundColor:
      theme?.palette?.card?.cardColor || theme.palette.common.lighterBg,
    marginBottom: "5px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  listContDis: {
    padding: "16px",
    backgroundColor:
      theme?.palette?.card?.cardColor || theme.palette.common.lighterBg,
    marginBottom: "5px",
    // cursor: "pointer",
  },
  couponLeftDiv: {
    display: "flex",
    flexDirection: "column",
  },
  redeemText: {
    opacity: "0.5",
    color: theme?.palette?.card?.cardFontColor,
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1, 1.6, 1.6, 1.6),
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: theme.palette.common.background,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3.3, 2.4, 2.7, 2.4),
    },
  },
  entercoupon: {
    display: "flex",
    justifyContent: "center",
    margin: "1rem 0",
    marginTop: "5rem",
  },
  label: {
    color: theme.palette.common.themeFontColor,
    // color: theme?.palette?.card?.cardFontColor,
  },
  couponInput: {
    margin: "0 1rem",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.common.themeFontColor, // Change this color to your desired color
      },
      "&:hover fieldset": {
        borderColor: theme.palette.common.themeFontColor, // Change this color to your desired color on hover
      },
      "& focus": {
        color: theme.palette.common.themeFontColor,
      },
    },
  },
  couponInput: {
    margin: "0 1rem",
    "& label": {
      color: theme.palette.common.themeFontColor,
    },
    "& label.Mui-focused": {
      color: theme.palette.common.themeFontColor,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.common.themeFontColor,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.common.themeFontColor,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.common.themeFontColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.common.themeFontColor,
      },
      "& input": {
        color: theme.palette.common.themeFontColor,
      },
    },
  },
  couponBtn: {
    margin: "0 1rem",
    borderRadius: theme?.palette?.button?.borderRadius || "12px",
    border: theme?.palette?.button?.buttonBorder ? "1px solid black" : null,
    backgroundColor: theme?.palette?.button?.buttonColor || "black",
    // color:
    //   theme?.palette?.button?.buttonFont || theme.palette.common.normalTitle,
    color: setFontColorOnBg(theme?.palette?.button?.buttonColor || "#000000"),
    "&.Mui-disabled": {
      color: setFontColorOnBg(theme?.palette?.button?.buttonColor || "#000000"),
      backgroundColor: theme?.palette?.button?.buttonColor || "black",
      opacity: 0.4,
    },
  },
}));

export default useStyles;
