import * as types from "./CheckoutTypes";

const initialState = {
  isChecked: true,
  error: false,
  contactAccordion: true,
  addressAccordion: true,
  paymentMethodAccordion: true,
  newAddressForm: true,
};

const CheckoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_CHECKBOX:
      return { ...state, isChecked: !state.isChecked };
    case types.UPDATE_ERROR:
      return { ...state, error: action.payload };
    case types.UPDATE_CONTACT_ACCORDIAN:
      return { ...state, contactAccordion: action.payload };
    case types.UPDATE_ADDRESS_ACCORDIAN:
      return { ...state, addressAccordion: action.payload };
    case types.UPDATE_PAYMENT_ACCORDIAN:
      return { ...state, paymentMethodAccordion: action.payload };
    case types.UPDATE_NEW_ADDRESS_FORM:
      return { ...state, newAddressForm: action.payload };
    default:
      return state;
  }
};

export default CheckoutReducer;
