import { axiosInstance } from "../../network/apis";
import { API } from "./../../utils/Constants";

// const handlerEnabled = false;
// Sample response
// import MenuSampleResponse from "./MenuSampleResponse";

export const getMenu = async (hotelKey, withTableId) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const headers = {
    "With-Table-Id": String(withTableId), // Convert to string to include 'true' or 'false'
  };

  const { data } = await axiosInstance.post(
    `${API.ENDPOINTS.GET_MENU}`,
    { id: `${hotelKey}`, timeZone: `${timeZone}` },
    {
      headers,
      handlerEnabled: false,
    }
  );
  // await axiosInstance.get(`${API.ENDPOINTS.GET_MENU}`, {
  //   handlerEnabled: false,
  // });

  // const data = MenuSampleResponse;
  if (data.status !== "success") {
    throw new Error(data.message);
  }
  return { data };
};

export const getProdSuggest = async (payload) => {
  const { data } = await axiosInstance.get(`${API.ENDPOINTS.PROD_SUGGEST}`, {
    params: {
      online_key: `${payload?.onlineKey}`,
      id: `${payload?.prodId}`,
    },
    handlerEnabled: false,
  });
  // await axiosInstance.get(`${API.ENDPOINTS.GET_MENU}`, {
  //   handlerEnabled: false,
  // });

  // const data = MenuSampleResponse;
  if (data?.status !== true) {
    throw new Error(data.message);
  }
  return { data };
};

export const getCartSuggest = async (payload) => {
  const { data } = await axiosInstance.get(`${API.ENDPOINTS.CART_SUGGESTION}`, {
    params: {
      online_key: `${payload?.onlineKey}`,
    },
    handlerEnabled: false,
  });
  // await axiosInstance.get(`${API.ENDPOINTS.GET_MENU}`, {
  //   handlerEnabled: false,
  // });

  // const data = MenuSampleResponse;
  if (data?.status !== true) {
    throw new Error(data.message);
  }
  return { data };
};
