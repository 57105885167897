import { takeLatest, call, put } from "redux-saga/effects";
import {
  checkVoucherSuccess,
  checkVoucherFailure,
  setVoucherLoading,
  clearVoucherLoading,
} from "./VoucherAction";
import { apiCheckVoucher } from "./VoucherApi"; // Define your API function
import * as types from "./VoucherTypes";

function* checkVoucherSaga(action) {
  try {
    yield put(setVoucherLoading());
    const response = yield call(apiCheckVoucher, action.payload); // Make the API call
    // console.log("responseeeeeeee", response);
    yield put(checkVoucherSuccess(response?.data[0]));
  } catch (error) {
    yield put(checkVoucherFailure(error));
  } finally {
    yield put(clearVoucherLoading()); // Clear loading state
  }
}

export function* watchCheckVoucher() {
  yield takeLatest(types.CHECK_VOUCHER_REQUEST, checkVoucherSaga);
}
