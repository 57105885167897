// import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import {
  calculateFontSize,
  setFontColorOnBg,
} from "../../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    width: "100%",
    marginBottom: theme.spacing(2.4),
    padding: theme.spacing(2.4),
    borderRadius: theme?.palette?.card?.cardBorderRadius || "12px",
    border: theme?.palette?.card?.cardBorder
      ? `1px solid ${theme?.palette?.card?.cardBorderColor}`
      : null,
    backgroundColor:
      theme?.palette?.card?.cardColor || theme.palette.common.white,
  },
  headerTitle: {
    fontWeight: "bold",
    color: theme?.palette?.card?.cardFontColor,
    fontSize: calculateFontSize(16, theme.palette.common.fontVariation),
    lineHeight: "19px",
    marginBottom: theme.spacing(2.4),
    [theme.breakpoints.up("sm")]: {
      fontSize: calculateFontSize(18, theme.palette.common.fontVariation),
    },
  },
  headerTitleEmpty: {
    // color: "grey",
    color: theme?.palette?.card?.cardFontColor,
    fontWeight: 500,
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
  },
  listContainerRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: theme.spacing(1.6),
  },
  itemText: {
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    lineHeight: "21px",
  },
  greenText: {
    color: theme.palette.common.green,
  },
  itemPrice: {
    fontWeight: "bold",
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    lineHeight: "21px",
  },
  total: {
    paddingTop: theme.spacing(1.9),
  },
  totalPrice: {
    fontSize: calculateFontSize(18, theme.palette.common.fontVariation),
  },
  grow: {
    flexGrow: 1,
  },
  btn: {
    // width: '100%',
    fontSize: calculateFontSize(16, theme.palette.common.fontVariation),
    border: theme?.palette?.button?.buttonBorder ? "1px solid black" : null,
    borderRadius: theme.shape.borderRadius,
    textTransform: "none",
    textDecoration: " none",
    color: setFontColorOnBg(
      theme?.palette?.button?.buttonColor || theme.palette.primary.main
    ),
    borderRadius: theme?.palette?.button?.borderRadius || "10px",
    backgroundColor:
      theme?.palette?.button?.buttonColor || theme.palette.primary.main,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
    },
  },
  divider: {
    backgroundColor: theme.palette.common.grey,
  },
  mobileContainer: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      width: "100%",
    },
  },
  orderTypeModalContainer: {
    width: "450px",
    height: "auto",
  },
}));

export default useStyles;
