import { call, takeLatest } from "@redux-saga/core/effects";
import { submitJoinUs } from "./FormApis";

import * as TYPES from './FormTypes';

export function* sagasFormRequest({ payload }) {
    try {
        const { type, data } = payload;
        switch (type) {
            case TYPES.JOIN_US:
                const response = yield call(submitJoinUs, data);
                if (!response.data) {
                throw new Error("Something went wrong with the response");
                }
                break;
        
            default:
                break;
        }
    } catch (err) {
        console.log('from submission error:: ', err);
    }
}

export function* TakeyFormsSaga() {
    yield takeLatest(TYPES.TAKEY_FORM_SUBMISSION, sagasFormRequest);
}