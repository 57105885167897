import * as types from "./UserTypes";

//Replace action name and update action types
export const getUser = (payload) => ({
  type: types.GET_USER,
  payload,
});
export const createUser = (payload) => ({
  type: types.CREATE_USER_REQUEST,
  payload,
});
export const loginUser = (payload) => ({
  type: types.LOGIN_USER_REQUEST,
  payload: payload,
});
export const logoutUser = () => ({
  type: types.LOGOUT_USER,
});

export const emailLoginRequest = (payload) => ({
  type: types.EMAIL_LOGIN_REQUEST,
  payload: payload,
});

export const emailOtpRequest = (payload) => ({
  type: types.EMAIL_OTP_REQUEST,
  payload: payload,
});

export const isEmailLoginLoading = (payload) => ({
  type: types.EMAIL_LOGIN_LOADING,
  payload: payload,
});
