import FastFoodIcon from "@material-ui/icons/FastfoodRounded";

const FallBack = () => {
  return (
    <div className="loader-wrapper">
      <div className="loader-box">
        <div className="icon">
          <FastFoodIcon />
        </div>
      </div>
    </div>
  );
};

export default FallBack;
