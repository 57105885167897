import { useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as moment from "moment";
// Redux actions
import { showSnackbarAction } from "./../store/Snackbar/SnackbarAction";
// Custom hooks
import useCheckoutForm from "./useCheckoutForm";
// Constant
import { ORDER_TYPES } from "../utils/Constants";

function useWorkingInfo() {
  const { hotel } = useSelector((state) => state.hotel);
  const dispatch = useDispatch();
  const { formValues } = useCheckoutForm();

  /**
   * Function will return the complete workingInfo for a hotel
   */
  const hotelWorkingInfo = useMemo(() => {
    if (!hotel) {
      return [];
    }
    return hotel.workingInfo || [];
  }, [hotel]);

  /**
   * Function will return the specific day workingInfo using day number for a hotel
   */
  const getWorkingInfoByDay = useCallback(
    (day) => {
      const dayWorkingInfo = hotelWorkingInfo.find((w) => w.day === day);
      if (!dayWorkingInfo) {
        return {
          status: false,
          message:
            "Ohh oh, Resturant working infomartion is not available for selected date/time",
        };
      }
      if (dayWorkingInfo.is_closed) {
        return {
          status: false,
          message: "Ohh oh, Resturant is temporarily closed",
        };
      }
      const { orderType } = formValues;
      const info = dayWorkingInfo.hotelDayInfo.find(
        (i) => i.type === orderType
      );
      if (!info) {
        return {
          status: false,
          message: "Ohh oh, Resturant is temporarily closed",
        };
      }

      return {
        status: true,
        data: { day: day, ...info },
      };
    },
    [hotelWorkingInfo, formValues]
  );

  /**
   * Function will return the valid date for pickup or delivery
   * @param {*} param0
   * @param {*} date
   * @returns
   */
  const getDefaultDateTime = useCallback(
    ({ day, running_hours = [] }, date = moment().utc()) => {
      // console.log("running_hours ================>", running_hours);
      const today = date.format("YYYY-MM-DD").toString(),
        current = date;
      try {
        // console.log("today ================>", running_hours);
        if (running_hours.length === 0) {
          return current;
        }
        for (let i = 0; i < running_hours.length; i++) {
          const start = moment(`${today} ${running_hours[i].start}`).utc();
          const end = moment(`${today} ${running_hours[i].stop}`).utc();
          const isExist = current.isBetween(start, end);
          // console.log("isExist =================>", isExist);
          if (isExist) {
            // console.log("current =================>", current);
            return current;
          } else if (current.isBefore(start.toString())) {
            // console.log("start =================>", start);
            return start;
          }
        }
        const nextDay = moment(current).add(1, "days").startOf("day").utc();
        day = nextDay.day();
        const next = getWorkingInfoByDay(day);
        // console.log("next =================>", next);

        return getDefaultDateTime(next.data, nextDay);
      } catch (error) {
        return current;
      }
    },
    [getWorkingInfoByDay]
  );

  /**
   * Function will return the valid date for pickup or delivery including buffer time
   */
  const defaultStartTime = useMemo(() => {
    const today = moment().utc();
    const res = getWorkingInfoByDay(today.day());
    if (!res.status) {
      return null;
    }
    const info = res.data;

    const time = getDefaultDateTime(info);
    if (formValues.orderType === ORDER_TYPES.DELIVERY) {
      return time
        .add(hotel.delivery_buffer_time || 0, "minutes")
        .format("YYYY-MM-DDTHH:mm")
        .toString();
    } else {
      return time
        .add(hotel?.pickup_buffer_time || 0, "minutes")
        .format("YYYY-MM-DDTHH:mm")
        .toString();
    }
  }, [
    formValues,
    hotel?.pickup_buffer_time,
    hotel?.delivery_buffer_time,
    getWorkingInfoByDay,
    getDefaultDateTime,
  ]);

  /**
   * Function to validate date on click
   * @param {*} dateValue
   * @returns
   */
  function validateWorkingInfo(dateValue) {
    try {
      const date = moment(dateValue).utc();

      if (date.isBefore(moment().utc())) {
        throw new Error("Ohh oh! Please select today or future date/time");
      }
      const res = getWorkingInfoByDay(date.day());
      // console.log("date.toString() ", date.toString());
      if (!res.status) {
        throw new Error(res.message);
      }
      const info = res.data;
      // console.log("info ", info);

      if (info.running_hours.length === 0) {
        const nextTime = getDefaultDateTime(info, date);
        throw new Error(
          `Ohh oh, Resturant will be closed, Opening time : ${nextTime
            .format("YYYY-MM-DDTHH:mm")
            .toString()}`
        );
      }

      for (let i = 0; i < info.running_hours.length; i++) {
        const start = moment(
          `${date.format("YYYY-MM-DD").toString()} ${
            info.running_hours[i].start
          }`
        ).utc();
        const end = moment(
          `${date.format("YYYY-MM-DD").toString()} ${
            info.running_hours[i].stop
          }`
        ).utc();
        const isExist = date.isBetween(start, end);
        if (isExist) {
          return true;
        }
      }
      const nextTime = getDefaultDateTime(info, date);
      throw new Error(
        `Ohh oh, Resturant will be closed, Opening time : ${nextTime
          .format("YYYY-MM-DD HH:mm")
          .toString()}`
      );
    } catch (error) {
      console.log("Error", error.message);
      dispatch(showSnackbarAction(error.message, "warning"));
      return false;
    }
  }

  return {
    hotelWorkingInfo,
    defaultStartTime,
    validateWorkingInfo,
  };
}

export default useWorkingInfo;
