import { call, put, takeLatest } from "redux-saga/effects";

import { getHomeRestaurant } from "./HomeApis";
import * as ACTIONS from "./HomeActions";
import * as TYPES from "./HomeTypes";
// Utils
import { dispatchErrorMessageAsDialog } from "../../utils/Shared";
import { ERROR_TYPES } from "../../utils/Constants";
// Constants

// Replace with your sagas
export function* sagasHomeRestaurantRequest({ payload }) {
  try {
    const { lat, lng } = payload;
    if (!lat || !lng) {
      throw new Error("Something went wrong with location");
    }
    const response = yield call(getHomeRestaurant, { lat, lng });
    if (!response.data) {
      throw new Error("Something went wrong with the response");
    }
    yield put(ACTIONS.getHomeRestaurantReceive(response.data));
  } catch (err) {
    yield put(ACTIONS.clearHomeResturant());

    if (err.message) {
      dispatchErrorMessageAsDialog({
        message: err.message,
        type: ERROR_TYPES.RESTURANT,
      });
    } else {
      if (err.response) {
        const { status, data } = err.response;
        if (status === 404) {
          dispatchErrorMessageAsDialog({
            message: data.error || "Resturants not found",
            type: ERROR_TYPES.RESTURANT,
          });
        } else {
          dispatchErrorMessageAsDialog({
            message: "Hotels are under maintenance, Please come back later",
            type: ERROR_TYPES.RESTURANT,
          });
        }
      }
    }
  }
}

export function* HomeRestaurantSaga() {
  yield takeLatest(
    TYPES.GET_HOME_RESTAURANT_REQUEST,
    sagasHomeRestaurantRequest
  );
}
