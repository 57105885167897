import { axiosInstance } from "../../network/apis";
import { API } from "../../utils/Constants";

export const apiCheckVoucher = async (voucherCode) => {
  // console.log("vouchercode in apiiiiiiiiiiii", voucherCode);
  //   console.log("res id in apiiiiii", resId);
  try {
    const response = await axiosInstance.get(`${API.ENDPOINTS.VOUCHER_CHECK}`, {
      params: {
        voucher_code: voucherCode.voucherCode,
        // res_id: voucherCode?.resId,
        res_id: voucherCode?.resId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
