// Define calculateFontSize function outside of useThemeHook
export const calculateFontSize = (value, variation) => {
  const fontSize = `${value * (variation || 1)}px`; // Calculate font size with variation, defaulting to 1 if variation is not provided

  return fontSize;
};

export const setFontColorOnBg = (bg, hel) => {
  const hexToRgb = (hex) => {
    // Remove '#' if present
    hex = hex.replace("#", "");
    // Convert to RGB
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgb(${r}, ${g}, ${b})`;
  };

  // Convert hexadecimal color to RGB format if needed
  const color = bg?.startsWith("#") ? hexToRgb(bg) : bg;

  // Function to determine brightness of color
  const calculateBrightness = (color) => {
    // Check if color is null or not in expected format
    if (!color || !color.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)) {
      return null;
    }

    // Convert color to RGB format
    const rgb = color.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    const r = parseInt(rgb[1]);
    const g = parseInt(rgb[2]);
    const b = parseInt(rgb[3]);
    // Calculate brightness using HSP formula
    const brightness = Math.sqrt(
      0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)
    );
    return brightness;
  };

  // Determine brightness of background color
  const brightness = calculateBrightness(color);

  // If brightness calculation fails, return null
  if (brightness === null) {
    return null;
  }
  // If background is light, return black, otherwise return white
  return brightness > 127 ? "black" : "white";
};

export const darkenColor = (hex, amount = 0.2) => {
  // Convert hex to RGB
  const bigint = parseInt(hex?.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Calculate the new RGB values by subtracting the specified amount
  const darken = (color) =>
    Math.max(0, Math.min(255, Math.floor(color * (1 - amount))));

  // Return the new hex color
  return `#${((1 << 24) + (darken(r) << 16) + (darken(g) << 8) + darken(b))
    .toString(16)
    .slice(1)
    .toUpperCase()}`;
};

export const hexToRgb = (hex) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
};

export function hexToRgba(hex, alpha) {
  const bigint = parseInt(hex?.replace("#", ""), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function getContrastingGreenColor(bgColor) {
  // Helper function to convert RGBA string to RGB array
  function rgbaToRgb(rgba) {
    const matches = rgba.match(
      /rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d.]+)?\)/
    );
    return matches
      ? [
          parseInt(matches[1]),
          parseInt(matches[2]),
          parseInt(matches[3]),
          parseFloat(matches[4] || 1),
        ]
      : null;
  }

  // Helper function to check if color is green and if it is light or dark
  function isGreenShade(rgb) {
    const [r, g, b] = rgb;
    const greenThreshold = 0.3; // Adjust this threshold as needed
    const isGreen = g > r && g > b && g / (r + b) > greenThreshold;
    const isLightGreen = g > 127.5; // Green component is more than half of 255
    return { isGreen, isLightGreen };
  }

  // Default return color if not green
  const defaultColor = "rgba(25, 119, 91, 1)";

  // Convert input color to RGB
  let rgba;
  if (bgColor.startsWith("rgba") || bgColor.startsWith("rgb")) {
    rgba = rgbaToRgb(bgColor);
  } else {
    throw new Error("Unsupported color format. Use rgba or rgb format.");
  }

  if (!rgba) return defaultColor;

  const [r, g, b, a] = rgba;
  const { isGreen, isLightGreen } = isGreenShade([r, g, b]);

  if (!isGreen) return defaultColor;

  let contrastingColor;
  if (isLightGreen) {
    contrastingColor = [0, 50, 0, 1]; // Dark color for light green background
  } else {
    contrastingColor = [200, 255, 255, 1]; // Light color for dark green background
  }

  // Convert RGB back to rgba format
  const rgbaToString = (r, g, b, a) => `rgba(${r}, ${g}, ${b}, ${a})`;
  return rgbaToString(...contrastingColor);
}
