import { makeStyles } from "@material-ui/styles";
import { setFontColorOnBg } from "../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  customPopup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme?.palette?.modal?.modalBorderRadius || "12px",
    border: theme?.palette?.modal?.modalBorder ? "1px solid black" : null,
  },
  customText: {
    textAlign: "center",
    background: theme?.palette?.common?.background,
    color: theme?.palette?.common?.themeFontColor,
  },
  customAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme?.palette?.common?.background,
  },
  actionText: {
    fontWeight: "bold",
    color: theme?.palette?.common?.themeFontColor,
  },
  updateActionText: {
    fontWeight: "bold",
    backgroundColor: "#99d98c",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: "#b5e48c",
    },
  },
  placeholderImg: {
    maxHeight: "370px",
    width: "100%",
    margin: "5px",
  },
  imgDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme?.palette?.common?.background,
  },
  readyToPayBtn: {
    background: "#e63946",
    "&:hover": {
      backgroundColor: "#c1121f",
      cursor: "pointer",
    },
  },
  okActionText: {
    fontWeight: "bold",
    backgroundColor: theme?.palette?.button?.buttonColor || "black",
    color: setFontColorOnBg(theme?.palette?.button?.buttonColor || "#000000"),
    borderRadius: theme?.palette?.button?.borderRadius || "12px",
    border: theme?.palette?.button?.buttonBorder ? "1px solid black" : null,
    "&:hover": {
      backgroundColor: "#000",
      color: "#fff",
      cursor: "pointer",
    },
  },
  getItFreeBtn: {
    background: "#8cb369",
    "&:hover": {
      backgroundColor: "#6a994e",
      cursor: "pointer",
    },
  },
}));

export default useStyles;
