import * as types from "./VoucherTypes";

const initialState = {
  voucherData: null,
  error: null,
  isVoucherLoading: false,
};

const VoucherReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHECK_VOUCHER_SUCCESS:
      return {
        ...state,
        voucherData: action.payload,
        error: null,
      };
    case types.CHECK_VOUCHER_FAILURE:
      return {
        ...state,
        voucherData: null,
        error: action.payload,
      };
    case types.SET_VOUCHER_LOADING:
      return { ...state, isVoucherLoading: true };
    case types.CLEAR_VOUCHER_LOADING:
      return { ...state, isVoucherLoading: false };
    default:
      return state;
  }
};

export default VoucherReducer;
