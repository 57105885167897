import * as types from "./OrderTypes";

const INITIAL_STATE = {
  orderId: null,
  paymentId: null,
  orderInProcess: false,
  orderResponse: null,
  paymentStatus: null,
  orderStatus: null,
  orderHistory: [],
  orderRequestLoading: true,
  paymentSatusCode: null,
  isPayFailed: false,
  failedMsg: null,
  orderPagination: null,
  isErrorModalCheckout: false,
  uberFailedModal: null,
};

// Replace with you own reducer
const reducer = (state = INITIAL_STATE, { ...action }) => {
  switch (action.type) {
    case types.ORDER_REQUEST:
      return {
        ...state,
        orderInProcess: true,
      };
    case types.ORDER_FAILED:
      return {
        ...state,
        orderInProcess: false,
      };
    case types.SET_ERROR_MODAL_CHECKOUT_OPEN:
      return {
        ...state,
        isErrorModalCheckout: true,
      };
    case types.SET_ERROR_MODAL_CHECKOUT_CLOSE:
      return {
        ...state,
        isErrorModalCheckout: false,
      };
    case types.ORDER_RECEIVE:
      return {
        ...state,
        orderInProcess: false,
        orderId: action.payload.order_id,
        paymentId: action.payload.payment_id,
        orderResponse: action.payload,
      };

    case types.VERIFY_PAYMENT_RECEIVE:
      return {
        ...state,
        paymentStatus: action.payload,
      };
    case types.ORDER_STATUS_RECEIVE:
      return {
        ...state,
        orderStatus: action.payload,
      };
    case types.PAYMENT_STATUS_CODE:
      return {
        ...state,
        paymentSatusCode: action.payload,
      };
    case types.FAILED_MSG:
      return {
        ...state,
        failedMsg: action.payload,
      };
    case types.CLEAR_ORDER:
      return {
        ...state,
        orderInProcess: false,
        orderResponse: null,
        paymentSatusCode: null,
      };
    case types.SET_UBER_FAILED_MODAL:
      return {
        ...state,
        uberFailedModal: action.payload,
      };

    case types.CLEAR_COMPLETE_ORDER:
      return {
        ...state,
        orderId: null,
        paymentId: null,
        orderInProcess: false,
        orderResponse: null,
        orderStatus: null,
        paymentSatusCode: null,
      };
    case types.SET_ORDER_PAGINATION:
      return {
        ...state,
        orderPagination: action.payload,
      };
    case types.RESET_CHECKOUT_ERR_MODAL:
      return {
        ...state,
        uberFailedModal: null,
        isErrorModalCheckout: false,
      };
    case types.ORDER_HISTORY:
      return {
        ...state,
        orderHistory: action.payload,
      };
    case types.UPDATE_ORDER_REQUEST_LOADING:
      return {
        ...state,
        orderRequestLoading: action.payload,
      };

    case types.PAY_STATUS:
      return {
        ...state,
        isPayFailed: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
