import * as types from "./ProfileTypes";

const INITIAL_STATE = {
  isCutomerOrderInProgress: false,
  customerOrders: null,
  customerWalletOrders: null,
  customerOrdersPagination: null,
  usedWalletPoints: 0,
  redumptionRatio: null,
  priceWalletPoint: null,
  allWalletPointsUsed: null,
  selectedWallet: null,
  isTriggerringEmailIsInProcess: false,
  triggeredEmailResponse: null,
  verifiedEmailResponse: null,
  isEmailVerified: false,
  orderDetailData: null,
  isOrderDetailLoading: false,
  isDirectLogin: false,
  isDeleteAcountProccesing: false,
  AccountDeleteStatus: false,
};

// Replace with you own reducer
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_CUSTOMER_ORDER_REQUEST:
      return {
        ...state,
        customerOrders: null,
      };
    case types.CUSTOMER_ORDER_RECEIVE:
      return {
        ...state,
        customerOrders: action.payload?.data,
        customerOrdersPagination: action?.payload,
      };
    case types.SET_CUSTOMER_WALLET_ORDERS:
      return {
        ...state,
        customerWalletOrders: action.payload,
      };
    case types.SET_REDUMPTION_RATIO:
      return {
        ...state,
        redumptionRatio: action.payload,
      };
    case types.SET_USED_WALLET_POINT:
      return {
        ...state,
        usedWalletPoints: action.payload,
      };
    case types.GET_TRIGGERED_EMAIL_RESPONSE:
      return {
        ...state,
        triggeredEmailResponse: action.payload,
        isTriggerringEmailIsInProcess: false,
      };
    case types.VERIFY_EMAIL_OTP:
      return {
        ...state,
        isTriggerringEmailIsInProcess: true,
      };
    case types.VERIFIED_EMAIL_RESPONSE:
      return {
        ...state,
        verifiedEmailResponse: action.payload,
        isTriggerringEmailIsInProcess: false,
      };
    case types.SET_PRICE_WALLET_POINT:
      return {
        ...state,
        priceWalletPoint: action.payload,
      };
    case types.CLEAR_USED_WALLET_POINTS:
      return {
        ...state,
        usedWalletPoints: null,
      };
    case types.IS_DELETE_ACCOUNT_LOADING:
      return {
        ...state,
        isDeleteAcountProccesing: action.payload,
      };
    case types.TRIGGER_EMAIL_VERIFICATION:
      return {
        ...state,
        isTriggerringEmailIsInProcess: true,
      };
    case types.SET_ACCOUNT_DELETE_STATUS:
      return {
        ...state,
        AccountDeleteStatus: action.payload,
      };
    case types.IS_EMAIL_VERIFIED:
      return {
        ...state,
        isEmailVerified: action.payload,
      };
    case types.SET_SELECTED_WALLETS:
      return {
        ...state,
        selectedWallet: action.payload,
      };
    case types.RESET_SELECTED_WALLETS:
      return {
        ...state,
        selectedWallet: null,
      };
    case types.UPDATE_USED_WALLET_POINTS:
      return {
        ...state,
        usedWalletPoints: state.usedWalletPoints - action.payload,
      };
    case types.ADD_USED_WALLET_POINTS:
      return {
        ...state,
        usedWalletPoints: state.usedWalletPoints + action.payload,
      };
    case types.RESET_WALLET:
      return {
        ...state,
        priceWalletPoint: null,
        usedWalletPoints: null,
        redumptionRatio: null,
      };
    case types.SET_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        orderDetailData: action.payload,
        isOrderDetailLoading: false,
      };
    case types.SET_DIRECT_LOGIN:
      return {
        ...state,
        isDirectLogin: action.payload,
      };
    case types.IS_ORDER_DETAIL_LOADING:
      return {
        ...state,
        isOrderDetailLoading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
