import React from "react";
import { Drawer, Grid, Typography } from "@material-ui/core";
import useStyles from "./OrderTypeDrawer.style.js";
import ChoiceItem from "../ChoiceItem/ChoiceItem.js";
import useCheckoutForm from "../../CustomHooks/useCheckoutForm.js";
import useDynamicHotelData from "../../CustomHooks/useDynamicHotelData.js";
import { setLocalStorageItem } from "../../utils/utils.js";
import { useDispatch } from "react-redux";
import { resetWallet } from "../../store/Profile/ProfileActions.js";
import useWalletPoints from "../../CustomHooks/useWalletPoints.js";

const OrderTypeDrawer = ({ drawerOpen, setDrawerOpen }) => {
  const classes = useStyles();
  const { formValues, setFormValues } = useCheckoutForm();
  const { getOrderTypes } = useDynamicHotelData();
  const dispatch = useDispatch();
  const { resetWalletProducts } = useWalletPoints();

  const applyOrderFilter = (fieldName, val) => {
    setFormValues({ [fieldName]: val });
    dispatch(resetWallet());
    resetWalletProducts(2);
    setDrawerOpen(false);
  };

  return (
    <React.Fragment>
      <Drawer
        open={drawerOpen}
        className={classes.drawer}
        variant="temporary"
        anchor="right"
        onClose={() => setDrawerOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {getOrderTypes?.length != 0 ? (
          <div className={classes.filterDrawerContainer}>
            <Typography className={classes.filterDrawerTitle} variant="h3">
              I am ordering for
            </Typography>

            <Grid container spacing={2}>
              {getOrderTypes.map((opt, i) => (
                <Grid item key={i} xs={12} sm={12}>
                  <ChoiceItem
                    // disabled={!opt.active}
                    title={opt.title}
                    type={opt.type}
                    value={opt.type}
                    selected={formValues.orderType === opt.type}
                    handleClick={(val) => applyOrderFilter("orderType", val)}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        ) : (
          <div className={classes.filterDrawerContainer}>
            <Typography className={classes.filterDrawerTitle} variant="h3">
              Currently no types available for Order
            </Typography>
          </div>
        )}
      </Drawer>
    </React.Fragment>
  );
};

export default OrderTypeDrawer;
