import { makeStyles } from "@material-ui/core/styles";
import {
  calculateFontSize,
  setFontColorOnBg,
} from "../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    position: "relative",
    // display: "flex",
    // flexDirection: "column",
    backgroundColor: theme.palette.common.white,
  },
  detailsContianer: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.up("sm")]: {
      overflowY: "auto",
      overflowX: "hidden",
      width: "100%",
    },
  },
  scrollContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      overflowY: "hidden",
    },
  },
  closeButton: {
    boxSizing: "border-box",
    zIndex: 100,
    position: "absolute",
    padding: theme.spacing(0.4),
    top: theme.spacing(3.2),
    right: theme.spacing(3.2),
    width: 32,
    height: 32,
    borderRadius: "50%",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
  },
  title: {
    // ...theme.typography.rockwell,
    fontSize: calculateFontSize(20, theme.palette.common.fontVariation),
    fontWeight: 700,
    lineHeight: "23.49px",
    marginBottom: theme.spacing(0.8),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(1.6),
    },
  },
  subTitle: {
    fontSize: calculateFontSize(16, theme.palette.common.fontVariation),
    fontWeight: 600,
    lineHeight: "24px",
    marginBottom: theme.spacing(0.8),

    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(0.5),
    },
  },
  description: {
    fontSize: calculateFontSize(12, theme.palette.common.fontVariation),
    fontWeight: 500,
    lineHeight: "24px",
    // marginBottom: theme.spacing(0.8),

    // [theme.breakpoints.up("sm")]: {
    //   marginBottom: theme.spacing(1.6),
    // },
  },
  listCont: {
    padding: "16px",
    backgroundColor: "#F0F5F5",
    marginBottom: "5px",
    cursor: "pointer",
  },
  listContDis: {
    padding: "16px",
    backgroundColor: "#E2E2E250",
    marginBottom: "5px",
    // cursor: "pointer",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1, 1.6, 1.6, 1.6),
    width: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3.3, 2.4, 2.7, 2.4),
    },
  },
  entercoupon: {
    display: "flex",
    justifyContent: "center",
    margin: "1rem 0",
    marginTop: "2rem",
  },
  couponInput: {
    margin: "0 1rem",
  },
  couponBtn: {
    margin: "0 1rem",
    borderRadius: theme?.palette?.button?.borderRadius || "12px",
    border: theme?.palette?.button?.buttonBorder ? "1px solid black" : null,
    backgroundColor:
      theme?.palette?.button?.buttonColor || theme.palette.button.main,
    color: setFontColorOnBg(
      theme?.palette?.button?.buttonColor || theme.palette.button.main
    ),
  },
  btn: {
    height: "40px",
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    border: "1px solid",
    borderRadius: theme.shape.borderRadius,
    textTransform: "none",
    textDecoration: " none",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
    },
  },
  tableCont: {
    padding: "0 24px",
  },
}));

export default useStyles;
