import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

// Material
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import Fade from "@material-ui/core/Fade";
//Image
import * as noImage from "./../../assets/images/noImage.png";
// Styles
import useStyles from "./ImageView.style.js";
import { isNull } from "lodash";

const ImageView = ({
  title,
  overlayText,
  className,
  image,
  disabled,
  ...props
}) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(
        classes.root,
        className,
        disabled && classes.disabledOverLay
      )}
    >
      <CardMedia
        className={clsx(classes.img, image || "no-image")}
        component="img"
        src={image || noImage.default}
        title={title}
        {...props}
        onError={(e) => {
          e.target.src = noImage.default;
          e.target.className = `${e.target.className} no-image`;
        }}
      />
      {!isNull(overlayText) && overlayText !== "null" && (
        <Fade in={true}>
          <Typography className={classes.overlayText} variant="caption">
            {overlayText}
          </Typography>
        </Fade>
      )}
    </div>
  );
};

ImageView.propTypes = {
  title: PropTypes.string,
  overlayText: PropTypes.string,
  className: PropTypes.any,
  image: PropTypes.string,
  disabled: PropTypes.bool,
};

ImageView.defaultProps = {
  title: null,
  overlayText: null,
  className: 0,
  image: null,
  disabled: false,
};

export default ImageView;
