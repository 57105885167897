import * as types from './LocationTypes';

const INITIAL_STATE = {
  lat: null,
  lng: null,
  location: null
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.UPDATE_LOCATION:
      state.lat = (action.payload.geometry && action.payload.geometry.location.lat()) || null;
      state.lng = (action.payload.geometry && action.payload.geometry.location.lng()) || null;
      state.location = (action.payload && action.payload) || null
      return state;

    case types.SET_INITIAL_LOCATION:
      state.lat = (action.payload.lat && action.payload.lat) || null;
      state.lng = (action.payload.lng && action.payload.lng) || null;
      state.location = (action.payload && action.payload) || null
      return state;

    default: return state;
  }
}

export default reducer;