// Icons
import CloseIcon from "@material-ui/icons/Close";
// Material
import Typography from "@material-ui/core/Typography";

import IconBtn from "../../Controls/IconButton/IconButton";
import useStyles from "./CouponList.style";
import { useDispatch, useSelector } from "react-redux";
import {
  // getCoupon,
  selectCoupon,
  setCouponValidationResult,
  setIsFreeDeliveryCouponApplied,
  updateCart,
  validateCoupon,
} from "../../../store/Cart/CartActions";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TextField, Button } from "@material-ui/core";
import { resetWallet } from "../../../store/Profile/ProfileActions";
import useWalletPoints from "../../../CustomHooks/useWalletPoints";
import SpecialAlert from "../../SpecialAlert/SpecialAlert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SkipNext } from "@material-ui/icons";
import store from "../../../store";
import CouponSuccess from "../CouponSuccess/CouponSuccess";
import ModalComponent from "../../Material/Modal/Modal";
import { set } from "lodash";

const CouponList = ({ handleClose = () => {}, coupons, handleSkip }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [manualCode, setManualCode] = useState("");
  const { hotel, hotelKey } = useSelector((state) => state.hotel);
  const {
    selectedCoupon,
    coupons: allCoupons,
    data,
    isValidating,
    coupon_validation_result,
    validatedCoupons,
  } = useSelector((state) => state.cart);

  const { menuVersion, sortedCategoryMenu } = useSelector(
    (state) => state.menu
  );
  const { resetWalletProducts } = useWalletPoints();
  const [itemModal, setItemModal] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [isFreedevliveryCoupon, setIsFreeDeliveryCoupon] = useState(false);

  const user = useSelector((state) => state.user);
  const getUniqueCoupons = useMemo(() => {
    return allCoupons.filter(
      ({ id: id1 }) => !coupons.some(({ id: id2 }) => id2 === id1)
    );
  }, [allCoupons, coupons]);

  const isHaveFreeProduct = (c) => {
    // console.log("cccccccccccc", c);
    return c?.result?.DISCOUNT === 100;
  };
  // console.log("hotel", hotel);
  // const couponClickHandler = (c) => {
  //   dispatch(resetWallet());
  //   resetWalletProducts(2);
  //   // console.log("ccccc", c);
  //   // const payload = {
  //   //   customerId: user?.email,
  //   //   code: c?.code,
  //   // };
  //   // dispatch(validateCoupon(payload));
  //   if (c?.result?.type === "ITEM_DISCOUNT") {
  //     if (isHaveFreeProduct(c)) {
  //       setSelectedProducts(c);
  //       setItemModal(true);
  //     } else {
  //       dispatch(selectCoupon(c));
  //     }
  //   } else {
  //     dispatch(selectCoupon(c));
  //   }
  //   // handleClose(true);
  // };
  const couponClickHandler = async (c) => {
    if (isValidating) return;
    dispatch(resetWallet());
    resetWalletProducts(2);

    const payload = {
      customerId: user?.email || null,
      code: c?.code,
      store_id: hotel?.hotelId || null,
      result_status: false,
      isNewCoupon: false,
    };
    // Call validateCoupon and wait for the result
    // console.log("validateCoupon");
    await dispatch(validateCoupon(payload));
    // console.log("response in funccccccc", response);
    // await new Promise((resolve) => setTimeout(resolve, 100));
    // if (coupon_validation_result) {
    //   // Proceed with the valid coupon logic
    //   if (c?.result?.type === "ITEM_DISCOUNT") {
    //     if (isHaveFreeProduct(c)) {
    //       setSelectedProducts(c);
    //       setItemModal(true);
    //       return;
    //     } else {
    //       dispatch(selectCoupon(c));
    //     }
    //   } else {
    //     dispatch(selectCoupon(c));
    //   }
    //   handleClose(true);
    // } else {
    //   console.log("Coupon is not valid.");
    // }
    // Now, check the state
    // const coupon_validation_result =
    //   store.getState().cart.coupon_validation_result;
  };
  // const availCouponClickHandler = (code) => {
  //   const payload = {
  //     hotelId: hotel.hotelId,
  //     code: code,
  //   };
  //   dispatch(validateCoupon(payload));
  // };

  useEffect(() => {
    if (coupon_validation_result) {
      // Proceed with the valid coupon logic
      // console.log("selected couponnnnn", selectedCoupon);
      // console.log("validatedCoupons", validatedCoupons);
      if (validatedCoupons?.result?.type === "ITEM_DISCOUNT") {
        if (isHaveFreeProduct(validatedCoupons)) {
          setSelectedProducts(validatedCoupons);
          dispatch(setIsFreeDeliveryCouponApplied(false));
          setItemModal(true);
          return;
        } else {
          // console.log("working 222222222222");
          setSelectedProducts(validatedCoupons);
          // setItemModal(true);
          dispatch(selectCoupon(validatedCoupons));
          handleClose(true);
          return;
        }
      } else if (validatedCoupons?.result?.type === "FREE_DELIVERY") {
        setIsFreeDeliveryCoupon(true);
        setItemModal(true);
        dispatch(setIsFreeDeliveryCouponApplied(true));
        setSelectedProducts(validatedCoupons);
        // dispatch(selectCoupon(validatedCoupons));
        return;
      } else {
        dispatch(selectCoupon(validatedCoupons));
      }
      handleClose(true);
    } else {
      console.log("Coupon is not valid.");
    }
  }, [coupon_validation_result, validatedCoupons]);

  const applyCoupon = () => {
    // const payload = {
    //   hotelId: hotel.hotelId,
    //   code: manualCode,
    // };
    const payload = {
      customerId: user?.email || null,
      code: manualCode,
      store_id: hotel?.hotelId || null,
      result_status: false,
      isNewCoupon: true,
    };
    dispatch(validateCoupon(payload));
  };

  // useEffect(() => {
  //   if (selectedCoupon?.id) {
  //     handleClose(true);
  //   }
  // }, [selectedCoupon]);

  // const alertTitle = useMemo(() => {
  //   console.log("validatedCoupons", validatedCoupons);
  //   const items = validatedCoupons.products || [];
  //   if (items.length > 1) {
  //     return `You can get ${items
  //       .slice(0, -1)
  //       .map((product) => product.name)
  //       .join(", ")} and ${items.slice(-1)[0].name} free`;
  //   } else if (items.length === 1) {
  //     return `You get ${items[0].name} free`;
  //   } else {
  //     return `You get a free product`;
  //   }
  // }, [validatedCoupons]);

  const alertTitle = useMemo(() => {
    if (!isFreedevliveryCoupon) {
      if (validatedCoupons) {
        return `You get ${validatedCoupons?.name} for free`;
      } else {
        return `You get a free product`;
      }
    } else {
      return `You have successfully applied a delivery fee coupon and will save ${validatedCoupons?.result?.MAX_DISCOUNT_VALUE} dollars on your delivery!`;
    }
  }, [validatedCoupons, isFreedevliveryCoupon]);

  const calculateDiscountPercentage = (
    discountPercentage,
    maxDiscountValue,
    item
  ) => {
    if (item?.sale_price > maxDiscountValue) {
      return (maxDiscountValue / item?.sale_price) * 100;
    } else {
      return discountPercentage;
    }
  };

  // Function to handle the close of the item modal and add free product
  const handleCloseItem = useCallback(() => {
    // Prevent function from running if it's already adding the product
    if (isAddingProduct) return;

    // Set the flag to indicate the product is being added
    setIsAddingProduct(true);

    // Check if selectedProducts has items
    if (selectedProducts?.items?.length > 0) {
      sortedCategoryMenu?.forEach((category) => {
        category?.item?.forEach((it) => {
          const selectedItemArray = JSON.parse(selectedProducts?.items);
          const isItemIdIncluded = selectedItemArray?.includes(it?.itemId);
          const isTheItemInCart = selectedItemArray.some((item) =>
            data?.some((dataItem) => dataItem.itemId === item)
          );

          if (isItemIdIncluded) {
            dispatch(
              updateCart({
                ...it,
                count: 1,
                modifiers: [],
                size: [],
                itemDiscountEnabled: true,
                itemDiscount:
                  calculateDiscountPercentage(
                    selectedProducts?.result?.DISCOUNT,
                    selectedProducts?.result?.MAX_DISCOUNT_VALUE,
                    it
                  ) || 0,
                isEditMode: false,
                menuVersion,
                isAutoAdded: true,
                categoryId: category.categoryId,
              })
            );
          }
        });
      });
    }

    dispatch(selectCoupon(selectedProducts));

    // Close the modal and reset the state flag after the action is done
    setItemModal(false);
    handleClose(false);
    dispatch(setCouponValidationResult(false));
    // Allow the action to be triggered again after a short delay (optional)
    setTimeout(() => setIsAddingProduct(false), 500);
  }, [
    isAddingProduct,
    selectedProducts,
    sortedCategoryMenu,
    data,
    dispatch,
    handleClose,
  ]);

  return (
    <div className={classes.root}>
      {/* <div className={classes.skipBtnDiv}> */}
      {/* <Button className={classes.skipBtn} onClick={handleSkip}>
          Skip without Coupon
        </Button> */}
      <IconBtn
        aria-label="close"
        component="span"
        icon={<SkipNext />}
        onClick={handleSkip}
        text={"Skip"}
        className={classes.skipBtn}
      />
      <IconBtn
        aria-label="close"
        component="span"
        icon={<CloseIcon />}
        text={"Close"}
        onClick={() => handleClose(false)}
        className={classes.closeButton}
      />
      {/* </div> */}
      <div className={classes.scrollContainer}>
        <div className={classes.detailsContianer}>
          <div className={classes.infoContainer}>
            {/* <Typography className={classes.title} variant="h3">
              Available Coupons
            </Typography> */}
            <div className={classes.entercoupon}>
              <TextField
                className={classes.couponInput}
                // color="primary"
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
                label="Enter Coupon Code"
                variant="outlined"
                onChange={(e) => {
                  setManualCode(e.target.value);
                }}
              />
              <Button
                className={classes.couponBtn}
                variant="contained"
                color="primary"
                onClick={applyCoupon}
                disabled={!manualCode}
              >
                APPLY
              </Button>
            </div>
            <Typography className={classes.title} variant="h3">
              Available Coupons
            </Typography>
            {coupons && coupons.length !== 0 ? (
              <>
                {coupons?.map((c) => (
                  <div
                    className={classes.listCont}
                    onClick={() => couponClickHandler(c)}
                    key={c.id}
                  >
                    <div className={classes.couponLeftDiv}>
                      <Typography className={classes.subTitle} variant="h3">
                        {c.name || ""}
                      </Typography>
                      <Typography className={classes.description} variant="h3">
                        {c?.description || ""}
                      </Typography>
                    </div>
                    <Typography className={classes.redeemText} variant="body1">
                      Click to Redeem
                    </Typography>
                    <Button
                      className={classes.couponBtn}
                      variant="contained"
                      // color="primary"
                      // onClick={() => couponClickHandler(c)}
                      disabled={manualCode}
                    >
                      APPLY
                    </Button>
                  </div>
                ))}
              </>
            ) : (
              <Typography
                className={`text-center pt-2 ${classes.noCouponText}`}
                variant="h3"
              >
                No coupons available
              </Typography>
            )}
            {getUniqueCoupons.length !== 0 && (
              <>
                <Typography
                  className={`py-2 ${classes.otherCouponText}`}
                  variant="h3"
                >
                  Other Coupons
                </Typography>
                {getUniqueCoupons?.map((c) => (
                  <div
                    className={classes.listContDis}
                    // onClick={(c) => availCouponClickHandler(c?.id)}
                    key={c.id}
                  >
                    <Typography className={classes.subTitle} variant="h3">
                      {c.name || ""}
                    </Typography>
                    <Typography className={classes.description} variant="h3">
                      {c?.description || ""}
                    </Typography>
                  </div>
                ))}
              </>
            )}
          </div>
          {/* <div className={classes.skipBtnDiv}>
            <Button className={classes.skipBtn} onClick={handleSkip}>
              Skip without Coupon
            </Button>
          </div> */}
        </div>
      </div>
      {/* <SpecialAlert
        open={itemModal}
        title={alertTitle}
        handleClose={handleCloseItem}
      /> */}
      <ModalComponent
        customView={true}
        open={itemModal}
        handleClose={handleCloseItem}
        modelContainer={classes.orderTypeModalContainer}
      >
        {itemModal && (
          <CouponSuccess
            isCustomTitle={true}
            discount={alertTitle}
            handleClose={handleCloseItem}
          />
        )}
      </ModalComponent>
    </div>
  );
};

export default CouponList;
