import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onFilterMenuCategory,
  onFilterMenuCombo,
} from "../store/Menu/MenuActions";
import { convertToMoment } from "../utils/TimeUtils";

const useSearchHook = () => {
  const dispatch = useDispatch();
  const { searchPhrase, category, comboModified } = useSelector(
    (state) => state.menu
  );

  useEffect(() => {
    const catSearc = handleCategorySearch(searchPhrase);
    const comSearch = handleComboSearch(searchPhrase);
    dispatch(onFilterMenuCategory(catSearc));
    dispatch(onFilterMenuCombo(comSearch));
  }, [searchPhrase]);

  const checkItemAvailability = (item) => {
    if (!item?.catAvailability || item?.catAvailability?.length === 0) {
      return true;
    }

    const availability = item?.catAvailability?.filter(
      (a) => a.day === moment().day()
    );
    if (availability.length === 0) {
      return false;
    }

    for (let i = 0; i < availability.length; i++) {
      if (item?.show_only === 1) {
        const start = convertToMoment(availability[i]?.start);
        const stop = convertToMoment(availability[i]?.end);
        if (!moment().isBetween(start, stop)) {
          return false;
        }
      }
    }

    return true;
  };

  const handleCategorySearch = (searchKey) => {
    if (searchKey) {
      const _category = [...category];
      const res = _category.map((_c) => ({
        ..._c,
        item: _c?.item?.filter(
          (i) => i.label.toLowerCase().indexOf(searchKey.toLowerCase()) > -1
        ),
      }));
      const cat = res?.filter((c) => checkItemAvailability(c));
      return cat || res;
    } else {
      const _category = [...category];
      const res = _category?.filter((c) => checkItemAvailability(c));
      return res || _category;
    }
  };

  const handleComboSearch = (searchKey) => {
    if (searchPhrase) {
      let res = {};
      //combo_name filter
      if (comboModified) {
        Object.keys(comboModified).forEach(
          (c) =>
            (res = {
              ...res,
              [c]: comboModified[c]?.filter(
                (cm) =>
                  cm?.combo_name
                    .toLowerCase()
                    .indexOf(searchKey.toLowerCase()) > -1
              ),
            })
        );
      }
      return res;
    } else {
      return comboModified;
    }
  };
  return { handleCategorySearch, handleComboSearch };
};

export default useSearchHook;
