import { SILVERBACK_APP_NAME, SIPO_APP_NAME, TAKEY_APP_NAME,STAGING_SIPO_APP_NAME } from "../utils/Constants";
import { silverBackFirebaseConfig, sipoFirebaseConfig, stagingSipoFirebaseConfig, TakeyFirebaseConfig } from "./firebase-configs";


let numberPrefix;
const updateNumberPrefix = (countryName) => {
    if (countryName === 'IN') {
        numberPrefix = "+91"
    } else if (countryName === "NZ") {
        numberPrefix = "+64"
    } else if (countryName === "AU") {
        numberPrefix = "+61"
    } else {
        numberPrefix = "+64"
    }
    // return numberPrefix
}



export { numberPrefix, updateNumberPrefix }




// selecting firebase config value
let firebaseConfigValue = {};
switch (process.env.REACT_APP_NAME) {
    case SIPO_APP_NAME:
        firebaseConfigValue = sipoFirebaseConfig;
        break;
    case SILVERBACK_APP_NAME:
        firebaseConfigValue = silverBackFirebaseConfig;
        break;
    case TAKEY_APP_NAME:
        firebaseConfigValue = TakeyFirebaseConfig;
        break;
        case STAGING_SIPO_APP_NAME:
            firebaseConfigValue = stagingSipoFirebaseConfig;
    default:
        break;
}

let userCountry;
switch (numberPrefix) {
    case "+91":
        userCountry = "IN";
        break;
    case "+64":
        userCountry = "NZ";
        break;
    case "+61":
        userCountry = "AU";
        break;
    default:
        break;
}

// setting all config values
export const fireBaseConfig = firebaseConfigValue;
// export const phoneNumberCountry = process.env.REACT_APP_PHONE_NUMBER_COUNTRY;
export const phoneNumberCountry = userCountry;
// export const phoneNumberPrefix = process.env.REACT_APP_PHONE_NUMBER_PREFIX;
export const phoneNumberPrefix = updateNumberPrefix();
export const appMapsCountry = process.env.REACT_APP_MAPS_COUNTRY;
export const aboutUsLink = process.env.REACT_APP_ABOUT_US_LINK;
export const tCLink = process.env.REACT_APP_TERMS_AND_CONDITIONS_LINK;
export const privacyPolicyLink = process.env.REACT_APP_PRIVACY_POLICY_LINK;
export const refundsAndCancellationLink = process.env.REACT_APP_REFUNDS_CANCELLATION_LINK;
export const pricingLink = process.env.REACT_APP_PRICING_LINK;
export const contactUsLink = process.env.REACT_APP_CONTACT_US_LINK;
export const shippingAndDeliveryPolicyLink = process.env.REACT_APP_SHIPPING_AND_DELIVERY_POLICY_LINK;
export const homeLink = process.env.REACT_APP_HOME_LINK;


// feature toggles
export const ENABLE_TABLE_PRE_BOOKING_AND_ORDER = process.env.REACT_APP_ENABLE_TABLE_PRE_BOOKING_AND_ORDER === 'true';