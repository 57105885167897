import * as types from "./AppTypes";

const INITIAL_STATE = {
  version: null,
  flavour: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_APP_VERSION:
      return {
        ...state,
        version: action.payload,
      };
    case types.SET_FLAVOUR:
      return {
        ...state,
        flavour: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
