import React from "react";
import clsx from "clsx";

// Material
import { OutlinedInput, Typography } from "@material-ui/core";
// Styles
import useStyles from "./InputField.style.js";

const InputField = ({
  id,
  name,
  type,
  keyboard,
  disabled,
  label,
  value,
  placeholder,
  error,
  handleChange = () => {},
  handleBlur = () => {},
  helperText,
  isMultiline,
  isRequired,
  endAdornment,
  isError = false,
  rootContainerClass = null,
  startAdornment,
  style,
  isFromCheckout = false,
  isSignupFormm = false,
}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, rootContainerClass)}>
      <OutlinedInput
        id={id}
        name={name}
        type={type}
        disabled={disabled}
        style={style}
        value={value}
        placeholder={placeholder}
        endAdornment={endAdornment}
        startAdornment={startAdornment}
        aria-describedby="outlined-weight-helper-text"
        inputProps={{
          "aria-label": "weight",
          className: isFromCheckout
            ? classes.splInput
            : isSignupFormm
            ? classes.signupInput
            : classes.input,
          inputMode: keyboard,
        }}
        classes={{
          root: clsx(
            isError ? "" : classes.inputContainer,
            isFromCheckout ? classes?.splBg : ""
          ),

          notchedOutline: clsx(
            classes.notchedOutline,
            isError === true && classes.error,
            isError === true && classes.success
          ),
          adornedEnd: clsx(
            isError === true && classes.errorHelperText,
            isError === false && classes.successHelperText
          ),
          focused: classes.focused,
        }}
        labelWidth={0}
        multiline={isMultiline ? true : false}
        rows={isMultiline ? 3 : 1}
        fullWidth={true}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {/* 1212{helperText} */}
      {helperText ? (
        <Typography
          className={clsx(
            classes.helperText,
            isError === true && classes.errorHelperText,
            isError === false && classes.successHelperText
          )}
          variant="caption"
          noWrap
        >
          {helperText}
        </Typography>
      ) : null}
    </div>
  );
};

export default InputField;
