export const sipoFirebaseConfig = {
    apiKey: "AIzaSyDXN933r4nfzKpLb9VJS2sGKimVSiCuhs0",
    authDomain: "sipo-online-order-5ccef.firebaseapp.com",
    projectId: "sipo-online-order-5ccef",
    storageBucket: "sipo-online-order-5ccef.appspot.com",
    messagingSenderId: "355399886695",
    appId: "1:355399886695:web:29eb076f13d6a0c1acf1c2",
    measurementId: "G-2EJ7B7TTDJ"
};
  
export const silverBackFirebaseConfig = {
    apiKey: "AIzaSyDQJENCHMWe3KJKoyOXeYNLBx0P4OUZtts",
    authDomain: "silverback-adf25.firebaseapp.com",
    projectId: "silverback-adf25",
    storageBucket: "silverback-adf25.appspot.com",
    messagingSenderId: "166541498411",
    appId: "1:166541498411:web:8ae91a34d5cb722f52726e",
    measurementId: "G-3B5MXQ3MRY"
};

export const TakeyFirebaseConfig = {
    apiKey: "AIzaSyAd4RgQMhcS3leZRRrHGypfyXwb_43M6nQ",
    authDomain: "takey-56a06.firebaseapp.com",
    projectId: "takey-56a06",
    storageBucket: "takey-56a06.appspot.com",
    messagingSenderId: "411659906897",
    appId: "1:411659906897:web:9f02d3e1cc1e67385cad70",
    measurementId: "G-VNFHP19X5V"
};

export const stagingSipoFirebaseConfig = {
    apiKey: "AIzaSyDXN933r4nfzKpLb9VJS2sGKimVSiCuhs0",
    authDomain: "sipo-online-order-5ccef.firebaseapp.com",
    projectId: "sipo-online-order-5ccef",
    storageBucket: "sipo-online-order-5ccef.appspot.com",
    messagingSenderId: "355399886695",
    appId: "1:355399886695:web:29eb076f13d6a0c1acf1c2",
    measurementId: "G-2EJ7B7TTDJ"
};