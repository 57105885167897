import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducers";
import createSagaMiddleware from "redux-saga";
import { watchSagas } from "./sagas";
import { saveState, loadState } from "./localstorage";
const saga = createSagaMiddleware();
//redux dev tool
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
const enhancer = composeEnhancers(applyMiddleware(saga));

const persistedState = loadState();

const store = createStore(reducers, persistedState, enhancer);
store.subscribe(() =>
  saveState({
    hotel: store.getState().hotel,
    // menu: store.getState().menu,
    cart: store.getState().cart,
    order: {
      orderId: store.getState().order.orderId,
      paymentId: store.getState().order.paymentId,
      orderHistory: store.getState().order.orderHistory,
    },
    location: {
      latitude: store.getState().location.latitude,
      longitude: store.getState().location.longitude,
    },
    user: store.getState().user,
    app: store.getState().app,
  })
);

saga.run(watchSagas);

export default store;
