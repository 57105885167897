import { call, put, takeLatest } from "redux-saga/effects";

import { getCartSuggest, getMenu, getProdSuggest } from "./MenuApis";
import * as ACTIONS from "./MenuActions";
import * as TYPES from "./MenuTypes";
// Caart action
import { processCart } from "../Cart/CartActions";
import { dispatchErrorMessageAsDialog } from "../../utils/Shared";
// Constants
import { ERROR_TYPES } from "../../utils/Constants";

// Replace with your sagas
export function* sagasMenuRequest({ payload, tableId }) {
  // const now = new Date();
  // const seconds = now.getSeconds();
  // const milliseconds = now.getMilliseconds();

  try {
    const hotelKey = payload;
    if (!hotelKey) {
      throw new Error("Something went wrong with the hotel key");
    }
    // console.log(
    //   `Seconds: ${seconds}, Milliseconds: ${milliseconds}`,
    //   "before api call"
    // );
    const withTableId = tableId != null ? true : false;
    const response = yield call(getMenu, hotelKey, withTableId);
    // console.log("responseeeee", response);
    if (!response?.data?.data || !response?.data?.combos) {
      throw new Error("Something went wrong with the response");
    }
    // const endnow = new Date();
    // const endseconds = endnow.getSeconds();
    // const endmilliseconds = endnow.getMilliseconds();
    // console.log(
    //   `Seconds: ${endseconds}, Milliseconds: ${endmilliseconds}`,
    //   "after api call"
    // );
    yield put(ACTIONS.getMenuReceive(response.data.data));
    yield put(ACTIONS.getComboMenuReceive(response.data.combos));
    yield put(ACTIONS.setMenuVersion(response?.data?.menu_version));
    yield put(processCart({ hotelKey, catrgories: response.data }));
  } catch (err) {
    yield put(ACTIONS.clearMenu());
    if (err.message) {
      dispatchErrorMessageAsDialog({
        message: err.message,
        type: ERROR_TYPES.RESTURANT,
      });
    } else {
      const { status, data } = err.response;
      if (status === 404) {
        dispatchErrorMessageAsDialog({
          message: data.error || "Resturant menu not found",
          type: ERROR_TYPES.RESTURANT,
        });
      } else {
        dispatchErrorMessageAsDialog({
          message: "Something went wrong with resturant menu",
          type: ERROR_TYPES.RESTURANT,
        });
      }
    }
  }
}

export function* sagasProdSuggest(payload) {
  try {
    if (!payload?.onlineKey || !payload?.prodId) {
      throw new Error("Something went wrong with the hotel key");
    }

    const response = yield call(getProdSuggest, payload);
    yield put(ACTIONS.setProdSuggest(response.data?.data));
  } catch (error) {
    console.log("errorrrrrrrr", error);
  }
}

export function* sagasCartSuggest(payload) {
  try {
    if (!payload?.onlineKey) {
      throw new Error("Something went wrong with the hotel key");
    }

    const response = yield call(getCartSuggest, payload);
    yield put(ACTIONS.setCartSuggest(response.data?.data));
  } catch (error) {
    console.log("errorrrrrrrr", error);
  }
}

export function* MenuSaga() {
  yield takeLatest(TYPES.GET_MENU_REQUEST, sagasMenuRequest);
}

export function* ProdSuggestSaga() {
  yield takeLatest(TYPES.GET_PRODUCT_SUGGESTIONS, sagasProdSuggest);
}

export function* CartSuggestSaga() {
  yield takeLatest(TYPES.GET_CART_SUGGESTIONS, sagasCartSuggest);
}
