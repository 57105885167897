import { makeStyles } from "@material-ui/core/styles";
import {
  calculateFontSize,
  setFontColorOnBg,
} from "../../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    width: "100%",
    backgroundColor: theme?.palette?.card?.cardColor,
    padding: theme.spacing(2.4, 1.6),
    marginBottom: theme.spacing(2.4),
    borderRadius: theme?.palette?.card?.cardBorderRadius || "12px",
    border: theme?.palette?.card?.cardBorder
      ? `1px solid ${theme?.palette?.card?.cardBorderColor}`
      : null,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2.4),
      borderRadius: theme?.palette?.card?.cardBorderRadius || "12px",
      border: theme?.palette?.card?.cardBorder
        ? `1px solid ${theme?.palette?.card?.cardBorderColor}`
        : null,
      backgroundColor:
        theme?.palette?.card?.cardColor || theme.palette.common.white,
    },
  },
  headerTitle: {
    // ...theme.typography.rockwell,
    fontWeight: "bold",
    color: theme.palette.card.cardFontColor,
    fontSize: calculateFontSize(16, theme.palette.common.fontVariation),
    lineHeight: "19px",
    marginBottom: theme.spacing(2.4),
    [theme.breakpoints.up("sm")]: {
      fontSize: calculateFontSize(18, theme.palette.common.fontVariation),
    },
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: theme.spacing(1.6),
  },
  itemText: {
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    lineHeight: "21px",
    color: theme.palette.card.cardFontColor,
  },
  greenText: {
    color: theme.palette.common.green,
  },
  itemPrice: {
    fontWeight: "bold",
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    lineHeight: "21px",
    color: theme.palette.card.cardFontColor,
  },
  itemTotalPrice: {
    fontWeight: "bold",
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    lineHeight: "21px",
    color: theme.palette.card.cardFontColor,
  },
  total: {
    paddingTop: theme.spacing(1.9),
  },
  totalPrice: {
    fontSize: calculateFontSize(18, theme.palette.common.fontVariation),
  },
  grow: {
    flexGrow: 1,
  },
  btn: {
    width: "100%",
    fontSize: calculateFontSize(16, theme.palette.common.fontVariation),
    border: theme?.palette?.button?.buttonBorder ? "1px solid" : null,
    borderRadius:
      theme?.palette?.button?.borderRadius || theme.shape.borderRadius,
    textTransform: "none",
    // color: theme?.palette?.button?.buttonFont || theme.palette.common.fontTitle,
    color: setFontColorOnBg(theme?.palette?.button?.buttonColor || "#000000"),
    backgroundColor: theme?.palette?.button?.buttonColor || "black",
    textDecoration: " none",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: `${theme.palette.button.buttonHover} !important`,
      color: `${theme.palette.button.buttonHoverFont} !important`,
    },
  },
  divider: {
    backgroundColor: theme.palette.common.grey,
  },
  mobileContainer: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      width: "100%",
    },
  },
  orderTypeModalContainer: {
    width: "450px",
    height: "auto",
  },
}));

export default useStyles;
