// Material
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

function useResponsive() {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const isWebView = useMediaQuery(theme.breakpoints.up("sm"));
  return {
    isMobileView,
    isWebView,
  };
}

export default useResponsive;
