import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onFilterMenuCategory,
  onFilterMenuCombo,
} from "../store/Menu/MenuActions";
import { SORTS } from "../utils/Constants";
import useSearchHook from "./useSearchHook";

const useSortMainHook = () => {
  const dispatch = useDispatch();
  const {
    category,
    comboModified,
    sortedCategoryMenu,
    sortedComboMenu,
    searchPhrase,
  } = useSelector((state) => state.menu);

  const { handleComboSearch, handleCategorySearch } = useSearchHook();

  const [FType, setFType] = useState(SORTS.DEFAULT);

  useEffect(() => {
    setFType(SORTS.DEFAULT);
  }, [searchPhrase]);

  const getCategory = () => {
    //deep copy object
    return JSON.parse(JSON.stringify(sortedCategoryMenu));
  };
  const getCombo = () => {
    //deep copy object
    return JSON.parse(JSON.stringify(sortedComboMenu));
  };

  const getDefaultSort = () => {
    const categoryFil = searchPhrase
      ? handleCategorySearch(searchPhrase)
      : category;
    const comboFil = searchPhrase
      ? handleComboSearch(searchPhrase)
      : comboModified;

    return { categoryFil, comboFil };
  };

  const getAZSort = () => {
    //category
    let categoryFil = getCategory();
    categoryFil.sort((a, b) => {
      let fa = a.label.toLowerCase(),
        fb = b.label.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    //combo
    let comboFil = Object.keys(getCombo())
      .sort()
      .reduce(function (result, key) {
        result[key] = sortedComboMenu[key];
        return result;
      }, {});

    return { categoryFil, comboFil };
  };

  const getZASort = () => {
    //category
    const categoryFil = getCategory();
    categoryFil.sort((a, b) => {
      let fa = a.label.toLowerCase(),
        fb = b.label.toLowerCase();
      if (fa < fb) {
        return 1;
      }
      if (fa > fb) {
        return -1;
      }
      return 0;
    });
    //combo
    let comboFil = Object.keys(getCombo())
      .sort()
      .reverse()
      .reduce(function (result, key) {
        result[key] = sortedComboMenu[key];
        return result;
      }, {});

    return { categoryFil, comboFil };
  };

  const handleFilter = (val) => {
    setFType(val);
    let categoryRes = [];
    if (val === SORTS.DEFAULT) {
      categoryRes = getDefaultSort();
    } else if (val === SORTS.AZ) {
      categoryRes = getAZSort();
    } else if (val === SORTS.ZA) {
      categoryRes = getZASort();
    }
    //
    dispatch(onFilterMenuCategory(categoryRes?.categoryFil));
    dispatch(onFilterMenuCombo(categoryRes?.comboFil));
  };

  return { FType, setFType, handleFilter };
};

export default useSortMainHook;
