import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
// import { Route, useLocation } from "react-router-dom";
// import clsx from 'clsx';

// Components
import Footer from "./../../../components/Footer/Footer";

// Styles
import useStyles from "./MainLayout.style.js";
import SiteFooter from "../../../components/SiteFooter/SiteFooter";
import { APP_NAME, SILVERBACK_APP_NAME } from "../../Constants";
import { useDispatch, useSelector } from "react-redux";
import { setIsHomeScrolled } from "../../../store/Menu/MenuActions.js";

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const scrollRef = useRef(null);
  const dispatch = useDispatch();
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const { isHotelFetchCompleted } = useSelector((state) => state.hotel);

  useEffect(() => {
    const handleScroll = () => {
      // console.log("Scrolled!");
      const currentScrollPos = scrollRef.current.scrollTop;
      const scrollingUp = currentScrollPos > prevScrollPos;
      setIsScrollingUp(scrollingUp);
      setPrevScrollPos(currentScrollPos);
      dispatch(setIsHomeScrolled(scrollingUp));
    };

    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [prevScrollPos]);

  return (
    <React.Fragment>
      <div
        ref={scrollRef}
        className={clsx("scroll", classes.bodyContainerMainHeader)}
      >
        <div className={`${classes.contentContainer} container`}>
          {children}
        </div>
        {APP_NAME === SILVERBACK_APP_NAME && (
          // add footer for silverback only
          <SiteFooter />
        )}
      </div>
      {isHotelFetchCompleted && <Footer isScrollingUp={isScrollingUp} />}
    </React.Fragment>
  );
};

export default MainLayout;
