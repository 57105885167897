import { call, put, takeLatest } from "redux-saga/effects";

import {
  getRestaurant,
  getRestaurantTables,
  getTableDetails,
  getUserWalletPoints,
  tableOptionRequest,
  tableOptionRequestV2,
} from "./RestaurantApis";
import * as ACTIONS from "./RestaurantActions";
import * as TYPES from "./RestaurantTypes";
import * as THEME_ACTIONS from "../Theme/ThemeAction";
// Other Actions
import { getMenu, clearMenu } from "../Menu/MenuActions";
// import { getDistanceDuration } from "../Location/LocationActions";
// Utils
import {
  dispatchErrorMessageAsDialog,
  dispatchErrorMessageAsWarning,
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from "../../utils/Shared";
// Constants
import { ERROR_TYPES } from "../../utils/Constants";
import { getThemeReceive } from "../Theme/ThemeAction";
import { getCoupon } from "../Cart/CartActions";
import { setAppVersion } from "../App/AppActions";

// Replace with your sagas
export function* sagasRestaurantRequest({ payload, tableId }) {
  try {
    yield put(clearMenu());
    const hotelKey = payload;
    if (!hotelKey) {
      throw new Error("Something went wrong with the hotel key");
    }
    const response = yield call(getRestaurant, hotelKey);
    if (!response.data) {
      throw new Error("Something went wrong with the response");
    }
    // console.log("response", response);
    // console.log("response configgggggggggg", window.location.origin);
    yield put(ACTIONS.getRestaurantReceive(response.data));
    // console.log("response in sagaaaaaaaaaaaaaaaaaa", response)
    yield put(ACTIONS.setLatestAppVersion(response?.appInfo));

    yield put(ACTIONS.setLinkedRest(response?.linked_res));
    // const fullUrl = `${window.location.origin}${response.config.url}`;
    // const targetOrigin = "https://app.sipocloudpos.com";
    // const isSipocloudpos = fullUrl.startsWith(targetOrigin);
    // yield put(ACTIONS.setIsAppFlavour(isSipocloudpos));

    yield put(
      THEME_ACTIONS.getThemeNew(response?.data[0]?.shopThemes?.online_theme)
    );
    // yield put(setAppVersion(response.update));
    // console.log("theme color", response?.data[0]?.online_theme_color)
    if (response?.data[0]?.online_theme_color != null) {
      localStorage.setItem("themecolor", response?.data[0]?.online_theme_color);
    }
    yield put(getThemeReceive(response?.data));
    yield put(getMenu(hotelKey, tableId));
    // yield put(getDistanceDuration());
    if (response?.data[0]?.hotelId) {
      yield put(getCoupon(response?.data[0]?.hotelId));
    }
  } catch (err) {
    console.log("errrrrrrrrrrrr", err);
    yield put(ACTIONS.clearResturant());

    if (err.message) {
      dispatchErrorMessageAsDialog({
        message: err.message,
        type: ERROR_TYPES.RESTURANT,
      });
    } else {
      const { status, data } = err?.response;

      if (status === 404) {
        dispatchErrorMessageAsWarning({
          showAs: "Warning",
          message: data.error || "Resturant not found",
          title: data.error || "Resturant not found",
          type: ERROR_TYPES.RESTURANT,
        });
      } else {
        dispatchErrorMessageAsDialog({
          message: "Hotel is under maintenance, Please come back later",
          type: ERROR_TYPES.RESTURANT,
        });
      }
    }
  }
}

export function* sagasTableDetailsRequest({ payload }) {
  try {
    const tableId = payload;
    yield put(ACTIONS.setTableId(tableId));
    const response = yield call(getTableDetails, tableId);
    if (!response.data) {
      throw new Error("Something went wrong with the response");
    }

    yield put(ACTIONS.setTableDetails(response.data[0]));
  } catch (err) {
    // yield put(ACTIONS.clearResturant());
    if (err.message) {
      dispatchErrorMessageAsDialog({
        message: err.message,
        type: ERROR_TYPES.RESTURANT,
      });
    } else {
    }
  }
}

export function* sagasTableOptionRequest({ payload }) {
  try {
    const time = new Date();

    const details = {
      event: `${payload.tableUserID}_waiter_action`,
      data: {
        time: time.getTime(),
        table: payload.tableName,
        item: payload.option,
      },
    };

    const response = yield call(tableOptionRequest, details);
    const responsev2 = yield call(tableOptionRequestV2, details);

    // if (response) {
    //   dispatchSnackbarSuccess(
    //     `${payload.option} requested for ${payload.tableName}`
    //   );
    // } else {
    //   dispatchSnackbarError("Something went wrong with the response");
    // }
  } catch (err) {
    if (err.message) {
      console.log("errrrrrrrrrr", err.message);
      dispatchErrorMessageAsDialog({
        message: err.message,
        type: ERROR_TYPES.DEFAULT,
      });
    } else {
    }
  }
}

export function* sagasRestaurantTablesRequest({ payload }) {
  try {
    const response = yield call(getRestaurantTables, payload);
    if (!response.data) {
      throw new Error("Something went wrong with the response");
    }

    yield put(ACTIONS.getRestaurantTablesReceive(response.data));
  } catch (err) {
    // yield put(ACTIONS.clearResturant());
    console.log("error ", err);
  }
}

export function* sagasUserWalletPoints({ payload }) {
  try {
    const response = yield call(getUserWalletPoints, payload);
    yield put(ACTIONS.setUserWalletError(false));

    yield put(ACTIONS.getUserWalletPointsReceive(response));
  } catch (error) {
    // Check if the error status is 400
    if (error.response && error.response.status === 400) {
      // Dispatch an action to set the error state in Redux
      yield put(ACTIONS.setUserWalletError(true));
    } else {
      // For other errors, log the error
      console.log("Error:", error);
    }
  }
}

export function* RestaurantSaga() {
  yield takeLatest(TYPES.GET_RESTAURANT_REQUEST, sagasRestaurantRequest);
}
export function* TableDetailsSaga() {
  yield takeLatest(TYPES.GET_TABLE_DETAILS, sagasTableDetailsRequest);
}
export function* TableOptionRequestSaga() {
  yield takeLatest(TYPES.TABLE_OPTION_REQUEST, sagasTableOptionRequest);
}
export function* RestaurantTablesSaga() {
  yield takeLatest(TYPES.GET_RESTAURANT_TABLES, sagasRestaurantTablesRequest);
}

export function* UserWalletPointsSaga() {
  yield takeLatest(TYPES.GET_WALLET_POINTS_REQUEST, sagasUserWalletPoints);
}
