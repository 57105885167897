import * as types from "./ErrorTypes";

export const setErrorMessage = ({
  message,
  title = null,
  type = null,
  showAs = null
}) => {
  return {
    type: types.SET_ERROR,
    action: {
      message,
      title,
      type,
      showAs
    },
  };
};

export const resetErrorMessage = () => {
  return {
    type: types.RESET_ERROR,
  };
};
