import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import {
  ENABLE_TABLE_PRE_BOOKING_AND_ORDER,
  phoneNumberPrefix,
  numberPrefix,
} from "../config/app-config";
import {
  APP_CONFIG,
  COUPONS,
  DELIVERY_PICKUP_TYPES,
  ORDER_TYPES,
} from "./Constants";
import { getLocalStorageItem } from "./utils";
import useWalletPoints from "../CustomHooks/useWalletPoints";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import useCalculateDistance from "../CustomHooks/useCalculateDistance";

export const cart = (
  {
    hotel,
    userId,
    tableId,
    tableDetails,
    tablePeopleCount,
    appVersion,
    userData,
    isMobileApp,
  },
  {
    checkoutDetails,
    data,
    tipAmount,
    priceSummary,
    selectedCoupon,
    selectedTable,
    guestCount,
    delCharge,
  },
  location,
  user,
  voucherData,
  profile
) => {
  if (!hotel) {
    throw new Error("Hotel information not found");
  }
  let dTime = checkoutDetails.deliveryTime;
  // console.log("checkoutDetails", checkoutDetails);

  // we don't need to add buff time for asap as per sid
  // if (checkoutDetails.deliveryType.toLowerCase() === "asap") {
  //   const bufTime =
  //     checkoutDetails.orderType === "DELIVERY"
  //       ? hotel.delivery_buffer_time
  //       : hotel.pickup_buffer_time;
  //   dTime = moment(dTime).add(bufTime, "minutes").toISOString();
  // }
  const getBrowserName = () => {
    const userAgent = window.navigator.userAgent;

    if (userAgent.indexOf("Firefox") !== -1) {
      return "Mozilla-Firefox";
    } else if (userAgent.indexOf("Chrome") !== -1) {
      return "Google-Chrome";
    } else if (userAgent.indexOf("Safari") !== -1) {
      return "Apple-Safari";
    } else if (
      userAgent.indexOf("Edge") !== -1 ||
      userAgent.indexOf("Edg") !== -1
    ) {
      return "Microsoft-Edge";
    } else if (
      userAgent.indexOf("Opera") !== -1 ||
      userAgent.indexOf("OPR") !== -1
    ) {
      return "Opera";
    } else if (userAgent.indexOf("Trident") !== -1) {
      return "Microsoft-Internet-Explorer";
    } else {
      return "Unknown";
    }
  };

  const generateBrowserId = () => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const isMobile = /Mobile/.test(userAgent);
    const browserName = getBrowserName();

    return `${checkoutDetails?.customer_email?.replaceAll(
      /\s/g,
      ""
    )}-${browserName}-${platform}-${isMobile ? "Mobile" : "Desktop"}`;
  };

  const getSizePrice = (size, options, base) => {
    // console.log("optinossssss", options);
    const elem = options?.find((elem) => elem.id === Number(size));
    // console.log("elemmmmm", elem);
    return elem ? elem.size_amount : base;
  };

  const clalculateComboDiscount = (priceMeta, item) => {
    // console.log("price metaaaaaaa", priceMeta);
    // console.log("itemmmm", item);
    // if (!selectedCoupon?.result?.type || selectedCoupon?.result?.type === COUPONS.ITEM_DISCOUNT) {
    //     return { dicountFactor: 0, dicountFactorValue: 0, totalDicount: 0, discountPercent: 0 };
    // }

    const salePrice = item.size
      ? getSizePrice(item.size, item.sizes, item.sale_price)
      : item.sale_price;
    // console.log("salePrice", salePrice);
    const totalDicount =
      priceMeta.actualItemPrice -
      (priceMeta.comboPrice + priceMeta?.actualExtraPrice);
    // console.log("total discount", totalDicount);
    const dicountFactor =
      (salePrice * item.count * 100) / priceMeta.actualItemPrice;
    // console.log("discount factor", dicountFactor);
    const dicountFactorValue = (totalDicount * dicountFactor) / 100;
    // console.log("discount factor value", dicountFactorValue);
    const discountPercent = (dicountFactorValue / salePrice / item.count) * 100;
    // console.log("discount percentage", discountPercent);
    return { dicountFactor, dicountFactorValue, totalDicount, discountPercent };
  };

  const calculateItemDiscount = (item) => {
    let discountPercent = 0;

    // Check for valid coupon type
    if (
      !selectedCoupon?.result?.type ||
      selectedCoupon?.result?.type === COUPONS.CART_DISCOUNT ||
      selectedCoupon?.result?.type != COUPONS.ITEM_DISCOUNT
    ) {
      return 0;
    }

    // Parse applicable items
    const applicableItems = JSON.parse(selectedCoupon?.items) || [];
    if (!applicableItems.length) {
      return 0;
    }
    console.log("working 1111111111111111", selectedCoupon);

    // Check if discount is 100
    if (Number(selectedCoupon?.result?.DISCOUNT) === 100) {
      if (applicableItems.includes(item.itemId)) {
        if (item?.isAutoAdded) {
          const maxDiscountValue =
            Number(selectedCoupon?.result?.MAX_DISCOUNT_VALUE) || 0;
          const itemSalePrice = Number(item?.sale_price) || 0;

          if (maxDiscountValue < itemSalePrice) {
            // Calculate discount percent based on MAX_DISCOUNT_VALUE
            discountPercent = (maxDiscountValue / itemSalePrice) * 100;
          } else {
            // Apply 100% discount
            discountPercent = 100;
          }
        } else {
          return 0; // No discount for non-auto-added items
        }
      }
    } else {
      // Normal discount logic for non-100% discount
      console.log("working 333333333333");

      if (applicableItems.includes(item.itemId)) {
        discountPercent =
          Number(item?.itemDiscount) ||
          Number(selectedCoupon?.result?.DISCOUNT) ||
          0;
      }
    }

    // Return the calculated discount
    return discountPercent; // Was previously `return 0`, now returning the actual discountPercent
  };

  // const getDiscountReason = (type, d) => {
  //   //discount_reason for combo
  //   try {
  //     if (type === "combo") {
  //       if (selectedCoupon?.result?.type === COUPONS.CART_DISCOUNT) {
  //         return selectedCoupon?.name;
  //       } else {
  //         return d?.combo_name;
  //       }
  //     }
  //     //discount_reason for non combo
  //     else {
  //       if (selectedCoupon?.items) {
  //         const applicableItems = JSON.parse(selectedCoupon?.items) || [];
  //         const validItem = applicableItems.includes(d?.itemId);
  //         if (validItem) return selectedCoupon?.name;
  //         else return null;
  //       } else {
  //         return null;
  //       }
  //     }
  //   } catch (error) {
  //     if (type === "combo") return d?.combo_name;
  //     else return null;
  //   }
  // };

  // const isCouponeApplied = useMemo(()=>{
  //   if(selectedCoupon){
  //     if(selectedCoupon)
  //   }

  // },[selectedCoupon])

  const getDiscountReason = (type, d) => {
    try {
      // Discount reason for combo items
      if (type === "combo") {
        if (selectedCoupon?.result?.type === COUPONS.CART_DISCOUNT) {
          return selectedCoupon?.name;
        } else {
          return d?.combo_name;
        }
      }
      // Discount reason for non-combo items
      else {
        if (selectedCoupon?.items) {
          const applicableItems = JSON.parse(selectedCoupon?.items) || [];
          const validItem = applicableItems.includes(d?.itemId);

          // If discount is 100%, only apply reason if item is auto-added
          if (Number(selectedCoupon?.result?.DISCOUNT) === 100) {
            if (d?.isAutoAdded && validItem) {
              return selectedCoupon?.name; // Add discount reason only for auto-added items
            } else {
              return null; // No discount reason for non-auto-added items
            }
          }

          // For non-100% discounts, proceed with normal logic
          if (validItem) {
            return selectedCoupon?.name;
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
    } catch (error) {
      if (type === "combo") return d?.combo_name;
      else return null;
    }
  };

  const isCouponApplied = (data) => {
    if (!selectedCoupon) {
      return false;
    }

    if (selectedCoupon?.rules?.type === "CART_DISCOUNT") {
      return true;
    } else if (selectedCoupon?.result?.DISCOUNT === 100) {
      return data?.isAutoAdded ? true : false;
    } else {
      // Parse the string into an array if selectedCoupon.items is a string
      const couponItems =
        typeof selectedCoupon?.items === "string"
          ? JSON.parse(selectedCoupon.items)
          : selectedCoupon.items;

      // Check if data.itemId is included in the array
      return couponItems?.includes(data?.itemId) || false;
    }
  };

  const setProductData = () => {
    const prodArr = [];
    data.map((d) => {
      let item = {};
      if (d.combo_id) {
        const batchId = uuidv4();
        d.combos.individualProducts.map((c) => {
          // console.log("cccccccccccccc", c);
          item = {
            is_combo: true,
            combo_id: d.combo_id,
            combo_price: d.combo_price,
            dicountFactor: clalculateComboDiscount(d.combos.priceMeta, c)
              .dicountFactor,
            dicountFactorValue: clalculateComboDiscount(d.combos.priceMeta, c)
              .dicountFactorValue,
            totalDicount: clalculateComboDiscount(d.combos.priceMeta, c)
              .totalDicount,
            discount: clalculateComboDiscount(d.combos.priceMeta, c)
              .discountPercent,
            discount_reason: getDiscountReason("combo", d),
            product_id: c.itemId,
            count: c.count,
            price: c.size
              ? getSizePrice(c.size, c.sizes, c.sale_price)
              : c.sale_price,
            kitchen_note: c.extraComments || "",
            modifiers: c.modifiers?.map((m) => ({
              ...m,
              count: m.count * d.count,
            })),
            sizeenabled: c.size ? true : false,
            size: c.sizes?.filter((s) => s.id === Number(c.size)),
            coupon_id: selectedCoupon?.id,
            is_coupon_applied: !!selectedCoupon?.id,
            comboBatch: batchId,
            extra_price: c?.extra_price || 0,
          };
          prodArr.push(item);
        });
      } else {
        const theDiscount = calculateItemDiscount(d);
        item = {
          is_combo: false,
          product_id: d.itemId,
          count: d.count,
          price: d.size
            ? getSizePrice(d.size, d.sizes, d.sale_price)
            : d.sale_price,
          kitchen_note: d.extraComments || "",
          modifiers: d.modifiers?.map((m) => ({
            ...m,
            count: m.count * d.count,
          })),
          sizeenabled: d?.size && d?.size != "" ? true : false,
          size: d.sizes?.filter((s) => s.id === Number(d.size)),
          discount: d?.isWalletRedeemed ? d?.walletDiscount : theDiscount,
          isWalletRedeemed: d?.isWalletRedeemed,
          walletDiscount: d?.walletDiscount,
          walletAmount: d?.walletAmount,
          walletType: d?.walletType,
          walletUsedForProduct: d?.walletUsedForProduct,
          discount_reason: getDiscountReason("notCombo", d),
          coupon_id: selectedCoupon?.id,
          // is_coupon_applied: !!selectedCoupon?.id,
          is_coupon_applied: isCouponApplied(d),

          comboBatch: null,
          extra_price: 0,
        };

        if (d?.isWalletRedeemed && theDiscount === 100) {
          const item1 = {
            ...item,
            count: 1,
            discount: theDiscount,
          };
          prodArr.push(item1);
          if (d.count > 1) {
            const item2 = {
              ...item,
              count: d.count - 1,
              discount: 0,
              discount_reason: null,
            };
            prodArr.push(item2);
          }
        } else {
          prodArr.push(item);
        }
      }
    });

    return prodArr;
  };

  const isVoucherApplied = !!voucherData?.voucherData;

  const getCartDiscount = () => {
    if (
      !selectedCoupon?.result?.type ||
      selectedCoupon?.result?.type === COUPONS.ITEM_DISCOUNT
      // ||
      // selectedCoupon?.result?.type === COUPONS.FREE_DELIVERY
    ) {
      return 0;
    } else {
      return (
        (Number(priceSummary.discount) * 100) /
          Number(priceSummary.totalWithoutDiscount) || 0
        // Number(priceSummary.discount) || 0
      );
    }
    // else if (selectedCoupon?.result?.type === COUPONS.FREE_DELIVERY) {
    //   console.log("dellllllllll", delCharge);
    //   if (delCharge >= selectedCoupon?.result?.MAX_DISCOUNT_VALUE) {
    //     return selectedCoupon?.result?.MAX_DISCOUNT_VALUE;
    //   } else {
    //     return delCharge;
    //   }
    // }
  };

  const getDeliveryCouponDiscount = () => {
    if (selectedCoupon?.result?.type === COUPONS.FREE_DELIVERY) {
      const maxDiscountValue = selectedCoupon?.result?.MAX_DISCOUNT_VALUE;

      if (delCharge > 0 && maxDiscountValue > 0) {
        const discountPercentage = Math.min(
          (maxDiscountValue / delCharge) * 100,
          100
        );
        return discountPercentage; // Returns percentage discount
      } else {
        return 0; // Return 0% if no valid charge or discount value
      }
    }
    return 0; // Return 0 if the coupon type isn't free delivery
  };

  const getTotalWalletPoints = () => {
    let total = 0;

    data?.map((item) => {
      if (item?.walletUsedForProduct) {
        total += item?.walletUsedForProduct;
      }
    });
    return total;
  };

  const getWalletRedemption = () => {
    let obj = {};

    if (checkoutDetails?.orderType === "DELIVERY") {
      obj = userData?.redumption_ratio?.find(
        (rat) => rat?.order_type === "ONLINE_DELIVERY"
      );
    } else if (checkoutDetails?.orderType === "PICKUP") {
      obj = userData?.redumption_ratio?.find(
        (rat) => rat?.order_type === "ONLINE_PICKUP"
      );
    }
    return obj;
  };

  const getPriceWithAvailableWalletPoints = (points) => {
    let price = 0;
    const redump = getWalletRedemption();
    price = points / redump?.ratio_points;
    const roundedPrice = parseFloat(price?.toFixed(2));

    return roundedPrice;
  };

  const totalWalletAmount = () => {
    let totalAmount = 0;
    let totalWalletPoints = getTotalWalletPoints();

    totalAmount = getPriceWithAvailableWalletPoints(totalWalletPoints);
    return totalAmount;
  };

  const getOrderType = (isMobileApp, orderType) => {
    if (isMobileApp) {
      if (orderType === "DELIVERY") {
        return "APP_DELIVERY";
      } else if (orderType === "PICKUP") {
        return "APP_PICKUP";
      }
    } else {
      return orderType;
    }
  };

  const setOrderType = (dTime, hotel, orderType, graceMinutes = 10) => {
    if (!hotel || !dTime) {
      return "SCHEDULED";
    }

    const bufferTime =
      orderType === "DELIVERY"
        ? hotel.delivery_buffer_time + graceMinutes
        : hotel.pickup_buffer_time + graceMinutes;

    // Add buffer time to the current time
    const currentTimeWithBuffer = moment().add(bufferTime, "minutes");

    // Subtract grace period from the selected time
    const selectedTime = moment(dTime).subtract(graceMinutes, "minutes");

    // Compare the adjusted selected time with the current time + buffer
    if (selectedTime.isSameOrBefore(currentTimeWithBuffer)) {
      return "ASAP";
    } else {
      return "SCHEDULED";
    }
  };

  const getWalletOrderType = () => {
    let hasType1 = false;
    let hasType2 = false;

    for (let dat of data) {
      if (dat?.walletType === 1) {
        hasType1 = true;
      } else if (dat.walletType === 2) {
        return 2;
      }
    }

    if (hasType1) {
      return 1;
    } else {
      return 0;
    }
  };

  // console.log({ checkoutDetails, priceSummary, dTime });
  // console.log("checlout in payloaddddd", checkoutDetails)

  let tablePrebookDetails = {
    table_id: null,
    table_name: null,
    guest: null,
  };
  if (
    ENABLE_TABLE_PRE_BOOKING_AND_ORDER &&
    checkoutDetails.orderType === ORDER_TYPES.TABLE_BOOKING_AND_ORDERING &&
    selectedTable?.id
  ) {
    tablePrebookDetails = {
      table_id: selectedTable.id,
      table_name: selectedTable.name,
      guest: guestCount,
    };
  }

  if (tableId) {
    const deliverTime = moment()
      .add(hotel.pickup_buffer_time, "minutes")
      .toISOString();
    return {
      server_secret: APP_CONFIG.SERVER_SECRET,
      currency: APP_CONFIG.CURRENCY,
      // terminal_id: hotel.main_terminal,
      browserId: uuidv4(),
      isMobileApp: isMobileApp,
      userUniqueId: userId,
      notes: checkoutDetails.extraComments || "",
      restaurant_key: hotel.online_key,
      discount: getCartDiscount() || 0,
      // discount: Number(selectedCoupon?.result?.DISCOUNT) || 0,
      discount_reason: selectedCoupon?.name,
      coupon_id: selectedCoupon?.id,
      is_coupon_applied: !!selectedCoupon?.id,
      // subtotal: Number(priceSummary.subTotal) + Number(priceSummary.discount),
      subtotal: priceSummary.subTotalWithoutDiscount,
      // total: Number(priceSummary.total) + Number(priceSummary.discount),
      total: priceSummary.totalWithoutDiscount,
      payable_amount: priceSummary.total,
      tax: priceSummary.tax,
      tip: tipAmount || null,
      surcharge: priceSummary.serviceCharge,
      deliverycharge: 0,
      wallet_point_used: profile?.usedWalletPoints ? true : false,
      wallet_order_type: getWalletRedemption()?.order_type || null,
      wallet_applied_type: getWalletOrderType() || 0,
      wallet_amount: totalWalletAmount() || 0,
      customer_id: userData?.customer_id || null,
      wallet_points: getTotalWalletPoints() || 0,
      deliveryzone: null,
      delivery_driving_time: null,
      customer_name: checkoutDetails?.customer_name || null,
      customer_address: checkoutDetails?.customer_address || null,
      // customer_phone: `${phoneNumberPrefix}${checkoutDetails?.customer_phone}` || null,
      customer_phone:
        // `${numberPrefix}${checkoutDetails?.customer_phone}` || null,
        `${checkoutDetails?.customer_phone}` || null,

      customer_email:
        checkoutDetails?.customer_email?.replaceAll(/\s/g, "") || null,
      customer_zipcode: checkoutDetails?.customer_zipcode || null,
      deliver_time: deliverTime,
      delivery_type: "ASAP",
      order_type: "dining",
      payment_type: checkoutDetails?.paymentMethod || null,
      data: setProductData(),
      fcmToken: getLocalStorageItem("fcmToken") || "",
      is_tableside: true,
      guest_count: Number(tablePeopleCount),
      fuid: user?.uid || null,
      table_id: tableDetails?.table_id,
      table_name: tableDetails?.name.toString(),
      payment_gateway: checkoutDetails?.payment_gateway || null,
      payment_gateway_id: checkoutDetails?.payment_gateway_id || null,
      version: checkoutDetails?.version || null,
    };
  } else
    return {
      ...tablePrebookDetails,
      server_secret: APP_CONFIG.SERVER_SECRET,
      currency: APP_CONFIG.CURRENCY,
      // terminal_id: hotel.main_terminal,
      browserId: generateBrowserId(),
      isMobileApp: isMobileApp,

      userUniqueId: userId,
      notes: checkoutDetails.extraComments || "",
      restaurant_key: hotel.online_key,
      discount: getCartDiscount() || 0,
      // isDeliveryCouponApplied: selectedCoupon?.rules?.type === "FREE_DELIVERY",
      // deliveryCouponDiscount: getDeliveryCouponDiscount() || 0,
      // discount: Number(selectedCoupon?.result?.DISCOUNT) || 0,
      coupon_type: selectedCoupon && selectedCoupon?.rules?.type,
      discount_reason: selectedCoupon?.name,
      coupon_id: selectedCoupon?.id,
      is_coupon_applied: !!selectedCoupon?.id,
      customer_name: checkoutDetails.customer_name,
      // subtotal: Number(priceSummary.subTotal) + Number(priceSummary.discount),
      subtotal: priceSummary.subTotalWithoutDiscount,
      // total: Number(priceSummary.total) + Number(priceSummary.discount),
      total: priceSummary.totalWithoutDiscount,
      payable_amount: priceSummary.total,
      wallet_point_used: profile?.usedWalletPoints ? true : false,
      wallet_order_type: getWalletRedemption()?.order_type || null,
      wallet_applied_type: getWalletOrderType() || 0,
      wallet_amount: totalWalletAmount() || 0,
      customer_id: userData?.customer_id || null,
      wallet_points: getTotalWalletPoints() || 0,
      tax: priceSummary.tax,
      tip: tipAmount || null,
      surcharge: priceSummary.serviceCharge,
      deliverycharge: priceSummary.deliveryCharge,
      isVoucherApplied: isVoucherApplied,
      voucherData: voucherData?.voucherData,
      deliveryzone: location.currentDeliveryZone,
      delivery_driving_time: location.duration,
      customer_address: checkoutDetails.customer_address,
      // customer_phone: `${phoneNumberPrefix}${checkoutDetails?.customer_phone}`,
      // customer_phone: `${numberPrefix}${checkoutDetails?.customer_phone}`,
      customer_phone: `${checkoutDetails?.customer_phone}`,

      customer_email: checkoutDetails.customer_email.replaceAll(/\s/g, ""),
      customer_zipcode: checkoutDetails.customer_zipcode,
      deliver_time: dTime,
      delivery_type:
        checkoutDetails.orderType === ORDER_TYPES.TABLE_BOOKING_AND_ORDERING
          ? "SCHEDULED"
          : // : checkoutDetails.deliveryType,
            setOrderType(dTime, hotel, checkoutDetails?.orderType),
      order_type: getOrderType(isMobileApp, checkoutDetails.orderType),
      payment_type: checkoutDetails.paymentMethod,
      data: setProductData(),
      fcmToken: getLocalStorageItem("fcmToken") || "",
      is_tableside: false,
      coupon_discount_amount: selectedCoupon?.id
        ? parseInt(priceSummary?.discount, 10)
        : null,
      guest_count: null,
      fuid: user?.uid || null,
      payment_gateway: checkoutDetails?.payment_gateway || null,
      payment_gateway_id: checkoutDetails?.payment_gateway_id || null,
      version: checkoutDetails?.version || null,
    };
};
