import React from "react";
import IconButton from "@material-ui/core/IconButton";

const IconBtn = ({
  text,
  disable,
  handleClick,
  icon,
  endIcon,
  children,
  ...props
}) => {
  return (
    <IconButton
      variant="contained"
      color="primary"
      onClick={handleClick}
      disabled={disable}
      {...props}
    >
      {icon}
      {text}
      {children}
    </IconButton>
  );
};

export default IconBtn;
