import {
  APP_NAME,
  SILVERBACK_APP_NAME,
  SIPO_APP_NAME,
  STAGING_SIPO_APP_NAME,
  TAKEY_APP_NAME,
} from "./Constants";

export const setAppMetaData = () => {
  if (APP_NAME === TAKEY_APP_NAME) {
    window.document.title = "Takey Local Food delivery App Rangiora";
    window.document
      .querySelector('meta[name="description"]')
      .setAttribute("content", "Food delivery near me, Food delivery");
  } else if (APP_NAME === SIPO_APP_NAME) {
    window.document.title = "Online Ordering";
    window.document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Online ordering system powered by sipocloudpos.com"
      );
  } else if (APP_NAME === SILVERBACK_APP_NAME) {
    window.document.title = "Online Ordering";
    window.document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Online ordering system powered by silverback.com"
      );
  }else if (APP_NAME === STAGING_SIPO_APP_NAME) {
    window.document.title = "Online Ordering";
    window.document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Online ordering system powered by sipocloudpos.com"
      );
  }
};
