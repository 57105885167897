import { makeStyles } from "@material-ui/core/styles";
import {
  calculateFontSize,
  setFontColorOnBg,
} from "../../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  container: {
    width: "300px",
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 4px 8px rgb(0 0 0 / 40%)",
    borderRadius: "10px",
    zIndex: 1000,
    // borderTop : '2px solid red',
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(2),
  },
  OderListContainer: {
    marginTop: theme.spacing(1.6),
  },
  OderListItemContainer: {
    marginTop: theme.spacing(1.6),
    display: "flex",
    alignItems: "center",
  },
  text: {
    fontWeight: "bold",
    color: theme.palette.common.fontTitle,
  },
  countText: {
    fontWeight: "bold",
    marginLeft: theme.spacing(0.5),
    fontSize: calculateFontSize(12, theme.palette.common.fontVariation),
    color: theme.palette.common.fontTitle,
  },
  totalContainer: {
    display: "flex",
    borderTop: "2px solid",
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
  },
  btn: {
    width: "100%",
    marginTop: theme.spacing(1),
    color: setFontColorOnBg(theme?.palette?.button?.buttonColor || "#000000"),
    backgroundColor: theme?.palette?.button?.buttonColor || "black",
    border: theme?.palette?.button?.buttonBorder ? "2px solid black" : null,
    borderRadius: theme?.palette?.button?.borderRadius || "10px",
    "&:hover": {
      color: "#fff",
      cursor: "pointer",
    },
  },
}));

export default useStyles;
