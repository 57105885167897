import { makeStyles } from "@material-ui/core/styles";
import {
  calculateFontSize,
  setFontColorOnBg,
} from "../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  filterBarContainer: {
    height: "100%",
  },
  bgColor: {
    backgroundColor:
      theme.palette.common?.headerColor || theme.palette.common.white,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
    // padding: theme.spacing(0, 1.6),
    // [theme.breakpoints.up('sm')]: {
    //     padding: theme.spacing(0, 2),
    // },
    // [theme.breakpoints.up('md')]: {
    //     padding: theme.spacing(0, 12),
    // },
  },
  mobileBtn: {
    height: "33px",
    fontSize: calculateFontSize(10, theme.palette.common.fontVariation),
    backgroundColor: "transparent",
    // border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.common.fontTitle,
    // color: theme.palette.common.black,
    margin: theme.spacing(0.4, 0.4),
    display: "flex",
    borderRadius: "0",
    boxShadow: "none",
    marginRight: "-20px",
    marginTop: "8px",
    paddingRight: "4px",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
    "&:hover": {
      color: theme.palette.common.white,
      pointer: "cursor",
      borderRadius: "10px",
    },
  },
  btn: {
    height: "33px",
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    backgroundColor: theme?.palette?.button?.buttonColor || "transparent",
    // border: `1px solid ${theme.palette.primary.main}`,
    color: setFontColorOnBg(
      theme?.palette?.button?.buttonColor || theme.palette.button.main
    ),
    // color: theme.palette.common.black,
    margin: theme.spacing(0.4, 0.4),
    borderRadius: theme?.palette?.button?.borderRadius || "12px",
    border: theme?.palette?.button?.buttonBorder ? "1px solid black" : null,
    boxShadow: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
    "&:hover": {
      color: "#fff",
      pointer: "cursor",
      borderRadius: "10px",
    },
  },
  categoryDiv: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  leftDiv: {
    height: "100%",
    width: "10%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rightDiv: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rightMobileDiv: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  scrollbarMobileDiv: {
    overflowX: "auto",
    display: "flex",
    whiteSpace: "nowrap",
    maxWidth: "90%",
    boxShadow: "none",
    fontFamily: theme?.palette?.common?.fontFamily,
    marginLeft: "22px",
    "&::-webkit-scrollbar": {
      width: "0", // Hide the horizontal scrollbar
      height: "0", // Hide the vertical scrollbar
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "lightgray", // Customize scrollbar thumb color
    },
  },
  scrollableDiv: {
    overflowX: "auto",
    display: "flex",
    fontFamily: theme?.palette?.common?.fontFamily,
    whiteSpace: "nowrap",
    boxShadow: "none",
    maxWidth: "98%",
    // scrollPaddingBottom: "10px",
    "&::-webkit-scrollbar": {
      width: "0", // Hide the horizontal scrollbar
      height: "0", // Hide the vertical scrollbar
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "lightgray", // Customize scrollbar thumb color
    },
  },
  item: {
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    padding: "8px", // Add padding to create spacing
    width: "auto",
    height: "100%",
    paddingBottom: "0",
    fontFamily: theme?.palette?.common?.fontFamily,
    "&:hover": {
      fontWeight: 600,
      cursor: "pointer",
    },
  },
  boldText: {
    fontWeight: 600,
    textDecoration: "underline",
  },
}));

export default useStyles;
