// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, RecaptchaVerifier } from "firebase/auth";
import { fireBaseConfig } from "./config/app-config";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  updateDoc,
  getDoc,
} from "firebase/firestore";

// Initialize Firebase
export const app = initializeApp(fireBaseConfig);
export const firebaseAuth = getAuth(app);
export const firestore = getFirestore(app);
export const provider = new GoogleAuthProvider();

export const setUpRecaptcha = () => {
  try {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        // callback: (response) => {
        //   console.log("recaptcha-container", response);
        // },
      },
      firebaseAuth
    );
  } catch (error) {
    throw error.toString();
  }
};

export const setUpAppleSignIn = () => {
  try {
    if (window.AppleID) {
      window.AppleID.auth.init({
        clientId: "com.sipocloudpos.onlineorder",
        scope: "name email",
        redirectURI:
          "https://sipo-online-order-5ccef.firebaseapp.com/__/auth/handler",
      });
    } else {
      console.error("Apple Sign In SDK is not available.");
    }
  } catch (error) {
    console.error("Error initializing Apple Sign In:", error);
  }
};

// export const initializeOTPDailyCount = async () => {
//   const otpCountRef = doc(firestore, "otpCount", "dailyCount");

//   const currentDate = new Date().toISOString().split("T")[0];

//   try {
//     await setDoc(otpCountRef, {
//       date: currentDate,
//       count: 0,
//     });
//   } catch (error) {
//     console.error("Error initializing OTP daily count:", error);
//   }
// };

const initializeOTPDailyCount = async () => {
  const otpCountRef = doc(firestore, "otpCount", "dailyCount");

  try {
    const docSnapshot = await getDoc(otpCountRef);

    if (!docSnapshot.exists()) {
      // Initialize the document only if it doesn't exist
      const currentDate = new Date().toISOString().split("T")[0];
      await setDoc(otpCountRef, {
        date: currentDate,
        count: 0,
      });
    }
  } catch (error) {
    console.error("Error initializing OTP daily count:", error);
  }
};

initializeOTPDailyCount();

// export const sendOTP = (phoneNumber, callback) => {
//   if (!window.recaptchaVerifier) return null;
//   const appVerifier = window.recaptchaVerifier;
//   signInWithPhoneNumber(firebaseAuth, phoneNumber, appVerifier)
//     .then((confirmationResult) => {
//       // SMS sent. Prompt user to type the code from the message, then sign the
//       // user in with confirmationResult.confirm(code).
//       window.confirmationResult = confirmationResult;
//       callback();
//     })
//     .catch((error) => {
//       console.log(error);
//       // Error; SMS not sent
//       // ...
//     });
// };

// export const verifyOTP = (otp, callback) => {
//   const confirmationResult = window.confirmationResult;

//   console.log(otp, confirmationResult);

//   confirmationResult
//     .confirm(otp)
//     .then((result) => {
//       // User signed in successfully.
//       callback();
//       const user = result?.user;
//       console.log(user);
//       return user;
//       // ...
//     })
//     .catch((error) => {
//       // User couldn't sign in (bad verification code?)
//       // ...
//       console.log("User couldn't sign in (bad verification code?)", error);
//     });
// };
