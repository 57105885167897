import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appContianer: {
    height: "100%",
    // overflowY: "scroll",
    // overflowX: "hidden",
    position: "static",
  },
}));

export default useStyles;
