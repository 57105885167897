import { makeStyles } from "@material-ui/core/styles";
import { calculateFontSize } from "../../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  root: {
    width: "100%",
    marginTop: "16px",
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  item: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "12px",
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
    cursor: "pointer",
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      opacity: 0.8,
    },
    "&.selected": {
      backgroundColor: theme.palette.primary.main,
    },
    "&.disabled": {
      opacity: 0.4,
      backgroundColor: "#00000040",
      cursor: "not-allowed",
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  itemText: {
    fontSize: calculateFontSize(15, theme.palette.common.fontVariation),
    fontWeight: 900,
    color: theme.palette.primary.main,
    "&.selected": {
      color: theme.palette.common.white,
    },
  },
  itemIcon: {
    color: theme.palette.primary.main,
    "&.selected": {
      color: theme.palette.common.white,
    },
  },
  typeSectionContainer: {
    marginTop: theme.spacing(2),
  },
  dateTimeSelection: {
    width: "50%",
    // color: theme.palette.common.normalTitle,
    // color: theme?.palette?.card?.cardFontColor,

    padding: "0 1% !important",
    "& :first-of-type": {
      marginLeft: "0",
      color: theme?.palette?.card?.cardFontColor,
      // color: theme.palette.common.normalTitle,
    },
    "& fieldset": {
      border: "none !important",
    },
  },
  deliveryDesc: {
    // font-family: Poppins;
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    lineHeight: "21px",
    marginTop: theme.spacing(0.8),
  },
  slotWarning: {
    color: "red",
    fontFamily: "Poppins",
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    margin: "0 auto",
  },
  borderTable: {
    borderStyle: "hidden",
    // boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
    borderRadius: theme?.palette?.card?.cardBorderRadius || "12px",
    border: theme?.palette?.card?.cardBorder
      ? `1px solid ${theme?.palette?.card?.cardBorderColor}`
      : null,
    background: theme?.palette?.card?.cardColor || "#fff",
    "& td": {
      // borderColor: "#E8E8E8",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "unset",
    },
  },
  checkBoxTd: {
    padding: "0 14px",
    height: "56px",
    display: "flex",
    borderRight: "1px solid #E8E8E8",
    "& span": {
      fontWeight: "bold",
      fontSize: calculateFontSize(16, theme.palette.common.fontVariation),
      lineHeight: "18px",
      // fontFamily: "Poppins-Regular",
    },
    "& :last-of-type": {
      marginRight: "none",
    },
  },
  checkBoxContainer: {},
  selectTableCont: {
    padding: "0 14px",
    height: "56px",
    display: "flex",
  },
  btn: {
    height: "40px",
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    border: "1px solid",
    borderRadius: theme.shape.borderRadius,
    textTransform: "none",
    textDecoration: " none",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
    },
  },
  input: {
    width: "100%",
    padding: "0 6%",
    borderRadius: "10px",
    height: "48px",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    lineHeight: "21px",
  },
}));

export default useStyles;
