import React, { useEffect, useState } from "react";
import SpecialAlert from "../../SpecialAlert/SpecialAlert";
import useStyles from "./VoucherRedeemForm.style";
import { Button, Typography } from "@material-ui/core";
import useWalletPoints from "../../../CustomHooks/useWalletPoints";
import { useDispatch, useSelector } from "react-redux";
import {
  resetSelectedWallets,
  resetWallet,
  setRedumptionRatio,
  setSelectedWallets,
  setUsedWalletPoint,
  updateUsedWalletPoints,
} from "../../../store/Profile/ProfileActions";
import {
  clearCoupon,
  setDiscountRemoveModal,
  setWalletRedeemed,
  updateCart,
  walletModal,
} from "../../../store/Cart/CartActions";
import { MonetizationOn } from "@material-ui/icons";

const WalletRedeemForm = ({
  isCart = false,
  walletPoints,
  setWalletAccodian,
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  // const priceSummary = usePriceSummary();
  const { usedWalletPoints, selectedWallet, priceWalletPoint } = useSelector(
    (state) => state.profile
  );
  const {
    data,
    isDiscountRemoveModal,
    selectedCoupon,
    isWalletModal,
    priceSummary,
  } = useSelector((state) => state.cart);
  const { userData } = useSelector((state) => state.hotel);
  const {
    getWalletRedemption,
    getPriceWithAvailableWalletPoints,
    PointsToRedeemIfMax,
    filterCartProductsLoyality,
    calculateTotalPrice,
    getWalletPointsFromProductPrice,
    resetWalletProducts,
    checkIsWalletDiscountTypeIsOne,
    walletPointsOtherThanProduct,
    walletAmountOtherThanProd,
    splitDigits,
    RemoveItemCoupon,
  } = useWalletPoints();
  const dispatch = useDispatch();
  const [couponRemoveModal, setCouponRemoveModal] = useState(false);

  // useEffect(() => {
  //   if (usedWalletPoints) {
  //     const filteredProds = filterCartProductsLoyality(data);
  //     const total = calculateTotalPrice(filteredProds);
  //     const amountToReduce = getPriceWithAvailableWalletPoints(
  //       PointsToRedeemIfMax(usedWalletPoints)
  //     );

  //     const updatedProduct = filteredProds?.map((prod) => {
  //       let productPrice = prod.sale_price; // Default to sale_price

  //       // Check if a size is specified and sizes array is not empty
  //       if (prod.size && prod.sizes.length > 0) {
  //         const sizeDetail = prod.sizes.find((size) => size.id == prod.size);
  //         if (sizeDetail) {
  //           productPrice = sizeDetail.size_amount; // Use size_amount if available
  //         }
  //       }
  //       const prodPercentage = ((productPrice / total) * 100).toFixed(2);
  //       const contributionAmount = (
  //         (amountToReduce * prodPercentage) /
  //         100
  //       ).toFixed(2);

  //       const percentageOfDiscount = (
  //         (contributionAmount / productPrice) *
  //         100
  //       ).toFixed(2);

  //       dispatch(
  //         updateCart({
  //           ...prod,
  //           isWalletRedeemed: true,
  //           walletDiscount: percentageOfDiscount,
  //           walletAmount: contributionAmount,
  //           walletType: 1,
  //           walletUsedForProduct:
  //             getWalletPointsFromProductPrice(contributionAmount),
  //         })
  //       );
  //     });
  //   }
  // }, [dispatch, data, usedWalletPoints]);

  // useEffect(() => {
  //   dispatch(clearDiscountType());
  // }, []);

  const handleModalClick = () => {
    setCouponRemoveModal(false);
  };

  const handleRemoveCouponFunc = () => {
    RemoveItemCoupon(selectedCoupon);
    dispatch(clearCoupon());
    setCouponRemoveModal(false);
  };

  const handleModalClose = () => {
    const filteredProds = filterCartProductsLoyality(data);
    const total = calculateTotalPrice(filteredProds);

    const amountToReduce = getPriceWithAvailableWalletPoints(
      PointsToRedeemIfMax(usedWalletPoints)
    );

    filteredProds?.forEach((prod) => {
      let productPrice = prod.sale_price;

      if (prod?.size && prod?.sizes?.length > 0) {
        const sizeDetail = prod?.sizes.find((size) => size?.id == prod?.size);
        if (sizeDetail) {
          productPrice = sizeDetail.size_amount;
        }
      }

      const prodPercentage = ((productPrice * prod?.count) / total) * 100;
      // const eachProdPercentage = (productPrice / total) * 100;
      const contributionAmount = (amountToReduce * prodPercentage) / 100;
      // const eachContributionAmount =
      //   (amountToReduce * eachProdPercentage) / 100;
      const percentageOfDiscount =
        (contributionAmount / (productPrice * prod?.count)) * 100;
      // const eachPercentageDiscount =
      //   (eachContributionAmount / productPrice) * 100;

      dispatch(
        updateCart({
          ...prod,
          isWalletRedeemed: true,
          walletDiscount: percentageOfDiscount,
          // walletDiscount: parseInt(eachPercentageDiscount, 10),
          walletAmount: Number(contributionAmount).toFixed(2),
          walletType: 1,
          walletUsedForProduct:
            getWalletPointsFromProductPrice(contributionAmount),
        })
      );
      dispatch(setWalletRedeemed(true));
    });
    dispatch(walletModal(false));
  };

  const handleWalletApply = () => {
    if (!selectedCoupon?.id) {
      const selectedWallet = {
        selectedPoints: PointsToRedeemIfMax(walletPoints),
        selectedAmount: getPriceWithAvailableWalletPoints(
          PointsToRedeemIfMax(walletPoints)
        ),
      };
      dispatch(setSelectedWallets(selectedWallet));
      handleSubmit();
    } else {
      setCouponRemoveModal(true);
    }
  };

  const handleSubmit = () => {
    // e.preventDefault();
    dispatch(setRedumptionRatio(getWalletRedemption()));
    dispatch(
      setUsedWalletPoint(usedWalletPoints + PointsToRedeemIfMax(walletPoints))
    );

    // setWalletAccodian(false);
    dispatch(walletModal(true));
  };

  const handleRemoveWallet = () => {
    // dispatch(resetWallet());
    const updatedWallets = walletPointsOtherThanProduct();
    dispatch(updateUsedWalletPoints(updatedWallets));
    dispatch(resetSelectedWallets());
    resetWalletProducts(1);
  };

  return (
    <div className={isCart ? classes.root : classes.checkoutRoot}>
      {isCart && (
        <div className={classes.walletTitleDiv}>
          <Typography
            // color="primary"
            className={classes.headerTitle}
            variant="h2"
            noWrap
          >
            {"Wallet"}
          </Typography>
          <MonetizationOn className={classes.coinIcon} />
        </div>
      )}
      {
        <div className={classes.formDiv}>
          {checkIsWalletDiscountTypeIsOne() && selectedWallet ? (
            <Typography className={classes.redeemedText}>
              {`Congratulations... You redeemed ${Math.round(
                selectedWallet?.selectedPoints
              )} points on this order and saved ${selectedWallet?.selectedAmount?.toFixed(
                2
              )}$.`}
            </Typography>
          ) : PointsToRedeemIfMax(walletPoints) === 0 ? (
            <Typography className={classes.toRedeemText}>
              Your cart has no more products eligible for a loyalty discount.
            </Typography>
          ) : (
            <Typography className={classes.toRedeemText}>
              {`You can use ${Math.floor(
                PointsToRedeemIfMax(walletPoints)
              )} points on this order and save ${getPriceWithAvailableWalletPoints(
                PointsToRedeemIfMax(walletPoints)
              ).toFixed(2)}$. Would you like to apply?`}
            </Typography>
          )}

          <div className={classes.walletBtnDiv}>
            {checkIsWalletDiscountTypeIsOne() && usedWalletPoints ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleRemoveWallet}
                style={{ marginTop: "10px" }}
                className={classes.applyBtn}
              >
                Remove
              </Button>
            ) : (
              <Button
                variant="contained"
                disabled={PointsToRedeemIfMax(walletPoints) === 0}
                color="primary"
                onClick={handleWalletApply}
                style={{ marginTop: "10px" }}
                className={classes.applyBtn}
                // disabled={isButtonDisabled}
              >
                Apply
              </Button>
            )}
          </div>
        </div>
      }

      {/* {isWalletModal && ( */}
      <SpecialAlert
        title={`Congratulations... You redeemed ${Math.floor(
          PointsToRedeemIfMax(walletPoints)
        )} points on this order and save ${getPriceWithAvailableWalletPoints(
          PointsToRedeemIfMax(walletPoints)
        )?.toFixed(2)}$.`}
        open={isWalletModal}
        // isLoading={isVoucherLoading}
        handleClose={handleModalClose}
      />
      {/* )} */}
      <SpecialAlert
        open={couponRemoveModal}
        isCancel={true}
        handleCloseModal={handleModalClick}
        handleClose={handleRemoveCouponFunc}
        title={
          "Applying your loyality point will remove the currently selected coupon. Are you sure you want to continue?"
        }
      />
    </div>
  );
};

export default WalletRedeemForm;
