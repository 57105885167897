import { axiosInstance } from "../../../network/apis";
import { API } from "../../../utils/Constants";

export const getRestaurant = async ({ lat, lng }) => {
  const { data } = await axiosInstance.get(
    `${API.ENDPOINTS.TAKEY_GET_NEAREST_RESTAURANT}?latitude=${lat}&longitude=${lng}`,
    { handlerEnabled: false }
  );
  if (data.status === false) {
    throw new Error(data.message);
  }
  return { data: data.data };
};
