import * as types from "./RestaurantTypes";

const INITIAL_STATE = {
  location: null,
  isHotelFetchCompleted: false,
  hotel: null,
};

// Replace with you own reducer
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // case types.SET_RESTAURANT_KEY:
    //   return {
    //     ...state,
    //     hotelKey: action.payload,
    //   };
    case types.GET_TAKEY_RESTAURANT_REQUEST:
      return {
        ...state,
        location: action.payload,
        isHotelFetchCompleted: false,
        hotel: null,
      };
    case types.GET_TAKEY_RESTAURANT_RECEIVE:
      return {
        ...state,
        isHotelFetchCompleted: true,
        hotel: action.payload,
      };
    case types.CLEAR_TAKEY_RESTURANT:
      return {
        ...state,
        isHotelFetchCompleted: null,
        hotel: null,
      };
    default:
      return state;
  }
};

export default reducer;
