import { makeStyles } from "@material-ui/core/styles";
import { calculateFontSize } from "../../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  // root: {
  //     paddingBottom: theme.spacing(2.4)
  // },
  inputContainer: {
    // marginBottom: theme.spacing(0.8),
    backgroundColor: theme.palette.card.cardShadeColor,
    // color: theme.palette.primary.main,
    border: theme?.palette?.card?.cardBorder
      ? `1px solid ${theme?.palette?.card?.cardBorderColor}`
      : null,
    borderRadius: theme?.palette?.card?.cardBorderRadius || "12px",
    // border: theme?.palette?.card?.cardBorder ? `1px solid black` : null,
  },
  input: {
    fontSize: calculateFontSize(16, theme.palette.common.fontVariation),
    lineHeight: "21px",
    color: theme.palette.card?.cardFontColor,
    "&::placeholder": {
      color: theme.palette.card.cardShadeFontColor, // Change this to the desired color
    },
  },
  signupInput: {
    fontSize: calculateFontSize(16, theme.palette.common.fontVariation),
    lineHeight: "21px",
    color: "black",
    "&::placeholder": {
      color: theme.palette.card.cardShadeFontColor, // Change this to the desired color
    },
  },
  splInput: {
    fontSize: calculateFontSize(16, theme.palette.common.fontVariation),
    lineHeight: "21px",
    color:
      theme?.palette?.card?.cardFontColor || theme.palette.common.fontTitle,
    "&::placeholder": {
      color:
        theme?.palette?.card?.cardFontColor || theme.palette.common.fontTitle, // Change this to the desired color
    },
  },
  splBg: {
    backgroundColor: `${
      theme?.palette?.card?.cardShadeColor || theme.palette.common.lighterBg
    } !important`,
    "&::placeholder": {
      color:
        theme?.palette?.card?.cardFontColor || theme.palette.common.fontTitle, // Change this to the desired color
    },
  },
  notchedOutline: {
    border: "none",
  },
  helperText: {
    marginTop: theme.spacing(0.8),
    fontSize: calculateFontSize(12, theme.palette.common.fontVariation),
    lineHeight: "18px",
  },
  focused: {
    // border: `1px solid ${theme.palette.primary.main}`
  },
  success: {
    border: `1px solid ${theme.palette.common.green}`,
  },
  error: {
    border: `1px solid ${theme.palette.common.red}`,
  },
  successHelperText: {
    color: theme.palette.common.green,
  },
  errorHelperText: {
    color: theme.palette.common.red,
  },
}));

export default useStyles;
