import * as types from "./OrderTypes";

export const order = () => ({
  type: types.ORDER_REQUEST,
});
export const orderReceive = (payload) => ({
  type: types.ORDER_RECEIVE,
  payload,
});
export const orderFailed = (payload) => ({
  type: types.ORDER_FAILED,
  payload,
});
export const verifyPayment = (orderId, callBack) => ({
  type: types.VERIFY_PAYMENT_REQUEST,
  payload: { orderId, callBack },
});
export const verifyPaymentReceive = (payload) => ({
  type: types.VERIFY_PAYMENT_RECEIVE,
  payload,
});
export const setPaymentStatusCode = (payload) => ({
  type: types.PAYMENT_STATUS_CODE,
  payload,
});
export const getOrderStatus = (orderId) => ({
  type: types.ORDER_STATUS_REQUEST,
  orderId,
});
export const getOrderStatusReceive = (payload) => ({
  type: types.ORDER_STATUS_RECEIVE,
  payload,
});
export const clearOrder = () => ({
  type: types.CLEAR_ORDER,
});
export const clearCompleteOrder = () => ({
  type: types.CLEAR_COMPLETE_ORDER,
});
export const getOrderHistory = (cusId) => ({
  type: types.GET_ORDER_HISTORY,
  cusId,
});
export const orderHistory = (orders) => ({
  type: types.ORDER_HISTORY,
  payload: orders,
});
export const updateOrderRequestLoading = (payload) => ({
  type: types.UPDATE_ORDER_REQUEST_LOADING,
  payload,
});

export const updatePayStatus = (payload) => ({
  type: types.PAY_STATUS,
  payload,
});

export const updateFailedMsg = (payload) => ({
  type: types.FAILED_MSG,
  payload,
});

export const setOrderPagination = (payload) => ({
  type: types.SET_ORDER_PAGINATION,
  payload,
});

export const setOrderHistoryByPageNo = (cusId, pageNo) => ({
  type: types.GET_ORDER_HISTORY_BY_PAGE_NO,
  cusId: cusId,
  pageNo: pageNo,
});

export const setErrorModalInCheckoutOpen = () => ({
  type: types.SET_ERROR_MODAL_CHECKOUT_OPEN,
});

export const setUberFailedModal = (payload) => ({
  type: types.SET_UBER_FAILED_MODAL,
  payload,
});

export const setErrorModalInCheckoutClose = () => ({
  type: types.SET_ERROR_MODAL_CHECKOUT_CLOSE,
});

export const resetCheckoutErrorModal = () => ({
  type: types.RESET_CHECKOUT_ERR_MODAL,
});

export const cancelOrderStatusCheck = () => ({
  type: types.CANCEL_ORDER_STATUS_CHECK,
});
